import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from "@material-ui/core";
import React, { useState } from "react";
import WithLoading from "../../../../UI/HOC/WithLoading";

import offerType1Image from "../../../../../Assets/Images/offer_type_1.png";
import offerType2Image from "../../../../../Assets/Images/offer_type_2.png";
import ImageView from "../../../../UI/ImageView/ImageView";
import Input from "../../../../UI/Input/Input";
import TextArea from "../../../../UI/Textarea/Textarea";

interface OfferTypeProps {
	enableSubmit: boolean;
	isEdit: boolean;
	loading: boolean;
	offerType?: string | null;
	subTitle?: string;
	imageUrl?: string;
	textColour?: string | null;
	backgroundColour?: string | null;
	onChange: ({
		offerType,
		subTitle,
		fileImage,
		textColour,
		backgroundColour,
	}) => void;
	onSubmit: () => void;
}

const OfferType: React.FC<OfferTypeProps> = ({
	enableSubmit,
	isEdit,
	loading,
	offerType,
	imageUrl,
	subTitle,
	textColour,
	backgroundColour,
	onSubmit,
	onChange,
}) => {
	const [offertype, setOfferType] = useState<string>(offerType ?? "1");
	const [subtitle, setSubTitle] = useState<string | undefined>(subTitle);
	const [fileimage, setFileImage] = useState<string | undefined>();
	const [textcolour, setColour] = useState<string | undefined | null>(
		textColour
	);
	const [backgroundcolour, setBackgroundColour] = useState<
		string | undefined | null
	>(backgroundColour);

	const disableButton =
		!enableSubmit ||
		!textcolour ||
		textcolour?.length === 0 ||
		!backgroundcolour ||
		backgroundcolour?.length === 0 ||
		!(fileimage || (imageUrl && imageUrl.length > 0));
	return (
		<div className="d-flex-column w-100 justify-content-between">
			<div className="d-flex w-100 justify-content-between-start">
				<div className="d-flex-column w-100 justify-content-between-start">
					<FormControl>
						<p className="label">Select offer banner type</p>
						<RadioGroup
							aria-labelledby="offer-type-radio-buttons-group"
							name="type-radio-buttons-group"
							value={offertype}
							onChange={(event, value) => {
								setOfferType(value);
								onChange({
									offerType: value,
									subTitle: subtitle,
									fileImage: fileimage,
									textColour: textcolour,
									backgroundColour: backgroundcolour,
								});
							}}
						>
							<FormControlLabel
								value="1"
								control={<Radio color="primary" />}
								label="Type 1"
							/>
							<FormControlLabel
								value="2"
								control={<Radio color="primary" />}
								label="Type 2"
							/>
						</RadioGroup>
					</FormControl>
					<br></br>
					<img
						src={
							offertype === "1"
								? offerType1Image
								: offerType2Image
						}
						alt="No Data"
						style={{ height: "7rem" }}
					/>
					<br></br>
					<TextArea
						placeholder={"Sub Title"}
						label
						rows={5}
						columns={40}
						disabled={false}
						value={subtitle ?? ""}
						onChange={(event: string) => {
							setSubTitle(event);
							onChange({
								offerType: offertype,
								subTitle: event,
								fileImage: fileimage,
								textColour: textcolour,
								backgroundColour: backgroundcolour,
							});
						}}
					/>
				</div>

				<div className="d-flex-column w-100 justify-content-between-start">
					<div className="w-60">
						<ImageView
							uniqueName={"OfferImageUrl*"}
							imageUrl={imageUrl ?? ""}
							setImage={(value) => {
								setFileImage(value);
								onChange({
									offerType: offertype,
									subTitle: subtitle,
									fileImage: value,
									textColour: textcolour,
									backgroundColour: backgroundcolour,
								});
							}}
							label={"Image*"}
						/>
					</div>
					<div className="w-60">
						<Input
							placeholder={"Text Color*"}
							label
							type={"text"}
							disabled={false}
							value={textcolour ?? ""}
							onChange={(event: string) => {
								setColour(event);
								onChange({
									offerType: offertype,
									subTitle: subtitle,
									fileImage: fileimage,
									textColour: event,
									backgroundColour: backgroundcolour,
								});
							}}
						/>
						<p
							className="home-page-row-detail__hintText"
							style={{ marginTop: "0px" }}
						>
							(enter values in RGB format or text; eg: FF0000,
							red)
						</p>
					</div>
					<div className="w-60">
						<Input
							placeholder={"Background Color*"}
							label
							type={"text"}
							disabled={false}
							value={backgroundcolour ?? ""}
							onChange={(event: string) => {
								setBackgroundColour(event);
								onChange({
									offerType: offertype,
									subTitle: subtitle,
									fileImage: fileimage,
									textColour: textcolour,
									backgroundColour: event,
								});
							}}
						/>
						<p
							className="home-page-row-detail__hintText"
							style={{ marginTop: "0px" }}
						>
							(enter values in RGB format or text; eg: FF0000,
							red)
						</p>
					</div>
				</div>
			</div>

			<br></br>
			<br></br>
			<br></br>
			<div>
				<WithLoading isLoading={loading}>
					<button
						type="button"
						className="button__primary button__primary-active"
						disabled={disableButton}
						onClick={() => {
							onSubmit();
							/* onSubmit({
								offerType: offertype,
								fileImage: fileimage,
							}); */
						}}
					>
						{isEdit ? "Edit" : "Save"}
					</button>
				</WithLoading>
			</div>
			<br></br>
			<br></br>
		</div>
	);
};

export default OfferType;

import { CircularProgress } from '@material-ui/core'
import React from 'react'

const Spinner = ({size, thickness}) => {
  return (
    <div className='spinner-container'>
        <CircularProgress size={size} thickness={thickness} />
    </div>
  )
}

export default Spinner
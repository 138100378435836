import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DeleteFaq, GetFaqsByTitleId } from "../FaqQuery";
import Empty from "../../../UI/Empty/Empty";
import Error from "../../../UI/Error/Error";
import { CircularProgress } from "@material-ui/core";
import FaqQuestionEdit from "./FaqQuestionEdit";
import SnackbarContext from "../../../../Context/SnackbarContext";
import DeleteModal from "../../../UI/DeleteModal/DeleteModal";

const FaqDetail: React.FC = () => {
	const [editFaq, setEditFaq] = useState<any>(null);
	const [faqModal, setFaqModal] = useState<boolean>(false);
	const { id: titleId } = useParams<{ id: string }>();
	const [deleteFaq] = useMutation(DeleteFaq);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [deleteId, setDeleteId] = useState<number | null>(null);
	const [deleteModal, setDeleteModal] = useState<boolean>(false);

	const { data: faqs, error, loading, refetch } = useQuery(GetFaqsByTitleId, {
		variables: {
			titleId,
		},
	});

	const onDelete = (faqId: number) => {
		setDeleteLoading(true);
		deleteFaq({
			variables: {
				faqId,
			},
		})
			.then(({ data: { delete_faqs: faq } }: any) => {
				if (faq.affected_rows > 0) {
					setMessage("Faq  Deleted Successfully");
					setSnackbar(true);
					callRefetch();
					setDeleteLoading(false);
					return setDeleteModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setDeleteLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setDeleteLoading(false);
			});
	};
	const history = useHistory();

	if (error) {
		return <Error error={error} />;
	}

	const callRefetch = () => {
		refetch();
	};

	return (
		<div className="container-main">
			<FaqQuestionEdit
				isOpen={faqModal}
				setModal={setFaqModal}
				refetch={callRefetch}
				setEditData={setEditFaq}
				editData={editFaq}
			/>

			<DeleteModal
				loading={deleteLoading}
				setModal={setDeleteModal}
				modal={deleteModal}
				onClickDelete={() => {
					deleteId && onDelete(deleteId);
				}}
			/>
			<button
				type="button"
				className="button__primary button__primary-active"
				onClick={() => {
					history.goBack();
				}}
			>
				Back
			</button>

			<div className="season__box">
				<div className="season__main">
					<div className="season__header">
						<p className="title">
							FAQs{" "}
							{loading ? "" : `(${faqs.faq_titles_by_pk.title})`}
						</p>
						<button
							type="button"
							onClick={() => {
								setFaqModal(true);
							}}
						>
							+Add Faq
						</button>
					</div>

					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : faqs.faq_titles_by_pk.faqs.length > 0 ? (
						faqs.faq_titles_by_pk.faqs.map((element: any) => {
							return (
								<FaqQuestionItem
									element={element}
									key={element.id}
									onClickEdit={() => {
										setEditFaq(element);
										setFaqModal(true);
									}}
									onClickDelete={(id: number) => {
										setDeleteModal(true);
										setDeleteId(id);
									}}
								/>
							);
						})
					) : (
						<Empty />
					)}
				</div>
			</div>
		</div>
	);
};

interface FaqItemProps {
	element: any;
	onClickEdit: () => void;
	onClickDelete: (id: number) => void;
}

const FaqQuestionItem: React.FC<FaqItemProps> = (props: FaqItemProps) => {
	const { element, onClickEdit, onClickDelete } = props;

	return (
		<div className="faq__item">
			<p className="faq__sr">{element.id} .</p>
			<p className="faq__question">{element.question}</p>
			<p className="faq__answer">{element.answer}</p>
			<div className="faq__button">
				<button
					className="btn-list"
					onClick={() => {
						onClickDelete(element.id);
					}}
				>
					Delete
				</button>

				<button className="btn-list " onClick={onClickEdit}>
					Edit
				</button>
			</div>
		</div>
	);
};

export default FaqDetail;

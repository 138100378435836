import { useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import SnackbarContext from "../../../Context/SnackbarContext";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";
import { InsertTestimonial, UpdateTestimonial } from "./TestimonialQuery";
import close from "../../../Assets/Images/cross.svg";
import Input from "../../UI/Input/Input";
import "./Testimonials.scss";
import TextArea from "../../UI/Textarea/Textarea";

interface EditProps {
	isOpen: boolean;
	editData: any;
	setEditData: (element: any | null) => void;
	setModal: (value: boolean) => void;
	refetch: () => void;
}
const TestimonialEdit: React.FC<EditProps> = ({
	isOpen,
	editData,
	setEditData,
	setModal,
	refetch,
}: EditProps) => {
	const [author, setAuthor] = useState<string>("");
	const [designation, setDesignation] = useState<string>("");
	const [testimonial, setTestimonial] = useState<string>("");

	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateTestimonial] = useMutation(UpdateTestimonial);
	const [insertTestimonial] = useMutation(InsertTestimonial);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const clearState = () => {
		setAuthor("");
		setDesignation("");
		setTestimonial("");
		setEditData(null);
	};

	const onEdit = () => {
		setMutationLoading(true);
		updateTestimonial({
			variables: {
				testimonialId: editData.id,
				author,
				designation,
				testimonial,
			},
		})
			.then(({ data: { update_testimonials: testimonial } }: any) => {
				if (testimonial.affected_rows > 0) {
					setMessage("Testimonial Updated Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	const onSave = () => {
		setMutationLoading(true);
		insertTestimonial({
			variables: {
				author,
				designation,
				testimonial,
			},
		})
			.then(({ data: { insert_testimonials: testimonial } }: any) => {
				if (testimonial.affected_rows > 0) {
					setMessage("Testimonial Inserted Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				console.log("insert la error")
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	useEffect(() => {
		if (editData) {
			setAuthor(editData && editData.author);
			setDesignation(editData && editData.designation);
			setTestimonial(editData && editData.testimonial);
		}
	}, [editData]);

	return (
		<ReactModal
			className="testimonial__modal Modal__main"
			onRequestClose={() => {
				clearState();
				setModal(false);
			}}
			overlayClassName="Overlay"
			isOpen={isOpen}
		>
			<div className="close">
				<img
					src={close}
					alt="Close"
					className="close__img"
					onClick={() => {
						clearState();
						setModal(false);
					}}
				/>
			</div>
			<div className="add__modal__content">
				<p className="add__modal__header">
					{editData ? "Edit Testimonial" : "Add Testimonial"}
				</p>
				<div className="margin-auto w-75">
					<Input
						placeholder={"Author"}
						label
						type={"text"}
						disabled={false}
						value={author}
						onChange={(event: string) => {
							return setAuthor(event);
						}}
					/>
					<Input
						placeholder={"Designation"}
						label
						type={"text"}
						disabled={false}
						value={designation}
						onChange={(event: string) => {
							return setDesignation(event);
						}}
					/>

					<TextArea
						placeholder={"Testimonial"}
						label
						rows={10}
						columns={10}
						disabled={false}
						value={testimonial}
						onChange={(event: string) => {
							return setTestimonial(event);
						}}
					/>
				</div>

				{!mutationLoading ? (
					<div
						className="w-40 margin-auto"
						style={{ marginTop: "2em" }}
					>
						<PrimaryButton
							label="Save"
							disabled={!author || !designation || !testimonial}
							loading={mutationLoading}
							active
							onClick={() => {
								editData ? onEdit() : onSave();
							}}
						/>
					</div>
				) : (
					<div
						className="spinner-container"
						style={{ marginTop: "3em" }}
					>
						<CircularProgress size={40} thickness={4} />
					</div>
				)}
			</div>
		</ReactModal>
	);
};

export default TestimonialEdit;

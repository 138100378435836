import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SnackbarContext from "../../../../Context/SnackbarContext";
import {
	CreateAddress,
	GetUserByPk,
	UpdateAddress,
	UpdateUsers,
} from "../UserQuery";
import Error from "../../../UI/Error/Error";
import { CircularProgress } from "@material-ui/core";
import Empty from "../../../UI/Empty/Empty";
import ReactModal from "react-modal";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import close from "../../../../Assets/Images/cross.svg";
import Input from "../../../UI/Input/Input";

const UserDetail: React.FC = () => {
	const { id: userId } = useParams<{ id: string }>();

	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [editUser, setEditUser] = useState<any>(null);
	const [userModal, setUserModal] = useState<boolean>(false);

	const [editUserAddress, setEditUserAddress] = useState<any>(null);
	const [userAddressModal, setUserAddressModal] = useState<boolean>(false);

	const { data: users, error, loading, refetch } = useQuery(GetUserByPk, {
		variables: { userId },
	});
	const history = useHistory();

	if (error) {
		return <Error error={error} />;
	}
	let detailsObject: any = {};
	let addresses: any = {};
	if (!loading && users.users_by_pk) {
		const user = users.users_by_pk;
		addresses = [...users.users_by_pk.addresses];
		detailsObject = {
			"First Name": user.firstName,
			"Last Name": user.lastName,
			Email: user.email,
			Phone: user.phoneNumber,
		};
		detailsObject = Object.entries(detailsObject);
	} else if (!loading && !users.users_by_pk) {
		setMessage("User either does not exist");
		setSnackbar(true);
		return (
			<div className="container-main">
				<div className="user__box">
					<Empty />
				</div>
			</div>
		);
	}

	const callRefetch = () => {
		refetch();
	};

	return (
		<div className="container-main">
			<UserEdit
				isOpen={userModal}
				setModal={setUserModal}
				refetch={callRefetch}
				setEditData={setEditUser}
				editData={editUser}
			/>
			<UserAddressEdit
				isOpen={userAddressModal}
				setModal={setUserAddressModal}
				refetch={callRefetch}
				setEditData={setEditUserAddress}
				editData={editUserAddress}
			/>

			<button
				type="button"
				className="button__primary button__primary-active"
				onClick={() => {
					history.goBack();
				}}
			>
				Back
			</button>
			<div className="user__box">
				{loading ? (
					<div className="spinner-container">
						<CircularProgress size={40} thickness={4} />
					</div>
				) : (
					<div className="user__main">
						{loading ? (
							<div className="spinner-container">
								<CircularProgress size={40} thickness={4} />
							</div>
						) : (
							<>
								<div className="d-flex justify-content-between w-100">
									<p className="user__detail-id">
										<span className="user__detail-title">
											User ID
										</span>
										&nbsp;: &nbsp;&nbsp;{userId}
									</p>
									<p
										className="user__detail-title cursor-pointer"
										onClick={() => {
											setEditUser(users.users_by_pk);
											setUserModal(true);
										}}
									>
										edit
									</p>
								</div>
								<div className="user__container">
									{detailsObject.map(
										(element: any, index: number) => {
											return (
												<UserItem
													key={index}
													name={element[0]}
													value={element[1]}
												/>
											);
										}
									)}
								</div>
								<div className="d-flex justify-content-between w-100">
									<p className="user__detail-title">
										Addresses
									</p>

									<p
										className="user__detail-title cursor-pointer"
										onClick={() => {
											setUserAddressModal(true);
										}}
									>
										add
									</p>
								</div>
								<div className="user__container">
									{addresses.map(
										(element: any, index: number) => {
											return (
												<UserAddress
													onClickEdit={(element) => {
														setEditUserAddress(
															element
														);
														setUserAddressModal(
															true
														);
													}}
													element={element}
													key={index}
												/>
											);
										}
									)}
								</div>
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default UserDetail;

interface UserItemProps {
	name: string;
	value: string;
}

const UserItem: React.FC<UserItemProps> = (props: UserItemProps) => {
	const { name, value } = props;

	return (
		<p className="user__detail-value">
			<span className="user__detail-label">{name}:</span> {value}
		</p>
	);
};

interface UserAddressProps {
	element: any;
	onClickEdit: (value: any) => void;
}

const UserAddress: React.FC<UserAddressProps> = (props: UserAddressProps) => {
	const { element, onClickEdit } = props;

	return (
		<div>
			<div className="d-flex justify-content-between w-100">
				<p className="user__detail-value">
					<span className="user__detail-label">Name:</span>{" "}
					{element.name}
				</p>

				<p
					className="cursor-pointer"
					onClick={() => {
						onClickEdit(element);
					}}
				>
					edit
				</p>
			</div>

			<p className="user__detail-value">
				<span className="user__detail-label">Line 1:</span>
				{"  "}
				{element.lineOne}
			</p>
			<p className="user__detail-value">
				<span className="user__detail-label">Line 2: </span>
				{"  "}
				{element.lineTwo}
			</p>

			<p className="user__detail-value">
				<span className="user__detail-label">State:</span>
				{"  "}
				{element.state}
			</p>
			<p className="user__detail-value">
				<span className="user__detail-label">Town: </span>{" "}
				{element.town}
			</p>
			<p className="user__detail-value">
				<span className="user__detail-label">Zipcode:</span>
				{"  "}
				{element.zipcode}
			</p>
			<p className="user__detail-value">
				<span className="user__detail-label">Country:</span>
				{"  "}
				{element.country.name}
			</p>
		</div>
	);
};

interface UserEditProps {
	isOpen: boolean;
	editData: any;
	setEditData: (element: any | null) => void;
	setModal: (value: boolean) => void;
	refetch: () => void;
}
const UserEdit: React.FC<UserEditProps> = ({
	isOpen,
	editData,
	setEditData,
	setModal,
	refetch,
}: UserEditProps) => {
	const [firstName, setFirstName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [phone, setPhone] = useState<string>("");

	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateUser] = useMutation(UpdateUsers);

	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const clearState = () => {
		setFirstName("");
		setLastName("");
		setEmail("");
		setPhone("");
		setEditData(null);
	};

	const onEdit = () => {
		setMutationLoading(true);
		updateUser({
			variables: {
				userId: editData.id,
				firstName,
				lastName,
				phoneNumber: phone,
			},
		})
			.then(({ data: { update_users: users } }: any) => {
				if (users.affected_rows > 0) {
					setMessage("Users Updated Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	useEffect(() => {
		if (editData) {
			setFirstName(editData && editData.firstName);
			setLastName(editData && editData.lastName);
			setEmail(editData && editData.email);
			setPhone(editData && editData.phoneNumber);
		}
	}, [editData]);

	return (
		<ReactModal
			className="user__modal Modal__main"
			onRequestClose={() => {
				clearState();
				setModal(false);
			}}
			overlayClassName="Overlay"
			isOpen={isOpen}
		>
			<div className="close">
				<img
					src={close}
					alt="Close"
					className="close__img"
					onClick={() => {
						clearState();
						setModal(false);
					}}
				/>
			</div>
			<div className="add__modal__content">
				<p className="add__modal__header">Edit User</p>
				<div className="margin-auto w-75">
					<Input
						placeholder={"First name"}
						label
						type={"text"}
						disabled={false}
						value={firstName}
						onChange={(event: string) => {
							return setFirstName(event);
						}}
					/>
					<Input
						placeholder={"Last name"}
						label
						type={"text"}
						disabled={false}
						value={lastName}
						onChange={(event: string) => {
							return setLastName(event);
						}}
					/>
					<Input
						placeholder={"Email"}
						label
						type={"text"}
						disabled={true}
						value={email}
						onChange={(event: string) => {
							return setEmail(event);
						}}
					/>
					<Input
						placeholder={"Phone"}
						label
						type={"text"}
						disabled={false}
						value={phone}
						onChange={(event: string) => {
							return setPhone(event);
						}}
					/>
				</div>
				{!mutationLoading ? (
					<div
						className="w-40 margin-auto"
						style={{ marginTop: "2em" }}
					>
						<PrimaryButton
							label="Edit"
							disabled={!firstName || !lastName || !phone}
							loading={mutationLoading}
							active
							onClick={() => {
								onEdit();
							}}
						/>
					</div>
				) : (
					<div
						className="spinner-container"
						style={{ marginTop: "3em" }}
					>
						<CircularProgress size={40} thickness={4} />
					</div>
				)}
			</div>
		</ReactModal>
	);
};

const UserAddressEdit: React.FC<UserEditProps> = ({
	isOpen,
	editData,
	setEditData,
	setModal,
	refetch,
}: UserEditProps) => {
	const { id: userId } = useParams<{ id: string }>();

	const [name, setName] = useState<string>("");
	const [line1, setLine1] = useState<string>("");
	const [line2, setLine2] = useState<string>("");
	const [state, setState] = useState<string>("");
	const [town, setTown] = useState<string>("");
	const [zipCode, setZipcode] = useState<string>("");

	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateAddress] = useMutation(UpdateAddress);
	const [createSeason] = useMutation(CreateAddress);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const clearState = () => {
		setName("");
		setLine1("");
		setLine2("");
		setZipcode("");
		setTown("");
		setState("");
		setEditData(null);
	};

	const onEdit = () => {
		setMutationLoading(true);
		updateAddress({
			variables: {
				addressId: editData.id,
				name,
				lineOne: line1,
				lineTwo: line2,
				state,
				zipcode: zipCode,
				town,
				countryId: 1,
				userId,
			},
		})
			.then(({ data: { update_addresses: address } }: any) => {
				if (address.affected_rows > 0) {
					setMessage("Address Updated Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	const onSave = () => {
		setMutationLoading(true);
		createSeason({
			variables: {
				name: name,
				lineOne: line1,
				lineTwo: line2,
				state,
				zipcode: zipCode,
				town,
				countryId: 1,
				userId,
			},
		})
			.then(({ data: { insert_addresses: address } }: any) => {
				if (address.affected_rows > 0) {
					setMessage("Address Inserted Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	useEffect(() => {
		if (editData) {
			setName(editData && editData.name);
			setLine1(editData && editData.lineOne);
			setLine2(editData && editData.lineTwo);
			setZipcode(editData && editData.zipcode);
			setTown(editData && editData.town);
			setState(editData && editData.state);
		}
	}, [editData]);

	return (
		<ReactModal
			className="address__modal Modal__main"
			onRequestClose={() => {
				clearState();
				setModal(false);
			}}
			overlayClassName="Overlay"
			isOpen={isOpen}
		>
			<div className="close">
				<img
					src={close}
					alt="Close"
					className="close__img"
					onClick={() => {
						clearState();
						setModal(false);
					}}
				/>
			</div>
			<div className="add__modal__content">
				<p className="add__modal__header">
					{editData ? "Edit Address" : "Add Address"}
				</p>
				<div className="margin-auto w-90">
					<Input
						placeholder={"Address Line 1"}
						label
						type={"text"}
						disabled={false}
						value={line1}
						onChange={(event: string) => {
							return setLine1(event);
						}}
					/>
					<Input
						placeholder={"Address Line 2"}
						label
						type={"text"}
						disabled={false}
						value={line2}
						onChange={(event: string) => {
							return setLine2(event);
						}}
					/>
					<div className="d-flex justify-content-between w-100">
						<Input
							placeholder={"Name"}
							label
							type={"text"}
							disabled={false}
							value={name}
							onChange={(event: string) => {
								return setName(event);
							}}
						/>
						<Input
							placeholder={"State"}
							label
							type={"text"}
							disabled={false}
							value={state}
							onChange={(event: string) => {
								return setState(event);
							}}
						/>
					</div>
					<div className="d-flex justify-content-between w-100">
						<Input
							placeholder={"Town"}
							label
							type={"text"}
							disabled={false}
							value={town}
							onChange={(event: string) => {
								return setTown(event);
							}}
						/>
						<Input
							placeholder={"Zipcode"}
							label
							type={"text"}
							disabled={false}
							value={zipCode}
							onChange={(event: string) => {
								return setZipcode(event);
							}}
						/>
					</div>
				</div>

				{!mutationLoading ? (
					<div
						className="w-40 margin-auto"
						style={{ marginTop: "2em" }}
					>
						<PrimaryButton
							label={editData ? "Edit" : "Save"}
							disabled={!name}
							loading={mutationLoading}
							active
							onClick={() => {
								editData ? onEdit() : onSave();
							}}
						/>
					</div>
				) : (
					<div
						className="spinner-container"
						style={{ marginTop: "3em" }}
					>
						<CircularProgress size={40} thickness={4} />
					</div>
				)}
			</div>
		</ReactModal>
	);
};

import { useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import SnackbarContext from "../../../../Context/SnackbarContext";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import close from "../../../../Assets/Images/cross.svg";
import Input from "../../../UI/Input/Input";
import { InsertCoupon, UpdateCoupon } from "../CouponQuery";

interface CouponEditProps {
	isOpen: boolean;
	editData: any;
	setEditData: (element: any | null) => void;
	setModal: (value: boolean) => void;
	refetch: () => void;
}

const CouponEdit: React.FC<CouponEditProps> = (props: CouponEditProps) => {
	const { isOpen, editData, setEditData, setModal, refetch } = props;
	const [code, setCode] = useState<string>("");
	const [value, setValue] = useState<number>(0);

	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateCoupon] = useMutation(UpdateCoupon);
	const [insertCoupon] = useMutation(InsertCoupon);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const clearState = () => {
		setCode("");
		setValue(0);
		setEditData(null);
	};

	const onEdit = () => {
		setMutationLoading(true);
		updateCoupon({
			variables: {
				seasonId: editData.id,
				code,
				value,
			},
		})
			.then(({ data: { update_coupons: coupons } }: any) => {
				if (coupons.affected_rows > 0) {
					setMessage("Coupons Updated Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	const onSave = () => {
		setMutationLoading(true);
		insertCoupon({
			variables: {
				code,
				value,
			},
		})
			.then(({ data: { insert_coupons: coupons } }: any) => {
				if (coupons.affected_rows > 0) {
					setMessage("Coupon Inserted Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	useEffect(() => {
		if (editData) {
			setCode(editData && editData.code);
			setValue(editData && editData.value);
		}
	}, [editData]);

	return (
		<ReactModal
			className="coupon__modal Modal__main"
			onRequestClose={() => {
				clearState();
				setModal(false);
			}}
			overlayClassName="Overlay"
			isOpen={isOpen}
		>
			<div className="close">
				<img
					src={close}
					alt="Close"
					className="close__img"
					onClick={() => {
						clearState();
						setModal(false);
					}}
				/>
			</div>
			<div className="add__modal__content">
				<p className="add__modal__header">
					{editData ? "Edit Coupon" : "Add Coupon"}
				</p>
				<div className="margin-auto w-75">
					<Input
						placeholder={"Code"}
						label
						type={"text"}
						disabled={false}
						value={code}
						onChange={(event: string) => {
							return setCode(event);
						}}
					/>

					<Input
						placeholder={"Value"}
						label
						type={"number"}
						disabled={false}
						value={value.toString()}
						onChange={(event: string) => {
							return setValue(parseInt(event));
						}}
					/>
				</div>

				{!mutationLoading ? (
					<div
						className="w-40 margin-auto"
						style={{ marginTop: "2em" }}
					>
						<PrimaryButton
							label="Save"
							disabled={!code || !value}
							loading={mutationLoading}
							active
							onClick={() => {
								editData ? onEdit() : onSave();
							}}
						/>
					</div>
				) : (
					<div
						className="spinner-container"
						style={{ marginTop: "3em" }}
					>
						<CircularProgress size={40} thickness={4} />
					</div>
				)}
			</div>
		</ReactModal>
	);
};

export default CouponEdit;

import { useQuery } from "@apollo/client";
import React from "react";
import { GetHomePageRows } from "./HomePageRowsQuery";
import "./HomePageRows.scss";
import WithLoading from "../../../UI/HOC/WithLoading";
import Empty from "../../../UI/Empty/Empty";
import { Home_Page_Rows } from "../../../../generated/graphql";
import HomePageRowItem from "./Components/HomePageRowItem";
import HomePageTableHeader from "./Components/HomePageTableHeader";
import { useHistory } from "react-router-dom";

const HomePageRows: React.FC = () => {
	const {
		data: homePageData,
		error,
		loading,
		refetch,
	} = useQuery(GetHomePageRows);

	const history = useHistory();

	return (
		<div className="container-main">
			<div className="home-page-rows__container">
				<WithLoading isLoading={loading}>
					<div className="season__box">
						<div className="season__header">
							<p className="title">Home Page</p>
							<button
								type="button"
								onClick={() => {
									history.push(
										"/dashboard/home-page-rows/detail?mode=add"
									);
								}}
							>
								+Add New Home Row
							</button>
						</div>
						<HomePageTableHeader />
						{homePageData?.home_page_rows.length > 0 ? (
							homePageData?.home_page_rows.map(
								(homePageRow: Home_Page_Rows) => {
									return (
										<HomePageRowItem
											key={homePageRow.id}
											item={homePageRow}
											onClickEdit={() => {
												history.push(
													`/dashboard/home-page-rows/detail?mode=edit&id=${homePageRow.id}`
												);
											}}
											refetch={refetch}
										/>
									);
								}
							)
						) : (
							<Empty />
						)}
					</div>
					<br></br>
					<br></br>
					<br></br>
				</WithLoading>
			</div>
		</div>
	);
};

export default HomePageRows;

import { createContext } from 'react';

// interface snackbarContextProp {
// 	snackbar: boolean,
// 	setSnackbar: any,
// 	message: string
// 	setMessage: any,
// }


const SnackbarContext = createContext<any>(null);

export default SnackbarContext;

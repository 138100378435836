import React from "react";

const HomePageTableHeader = () => {
	return (
		<div className="home-page-rows__table">
			<p className="home-page-rows__title">Pos</p>
			<p className="home-page-rows__title">Title</p>
			<p className="home-page-rows__title">Type</p>
		</div>
	);
};

export default HomePageTableHeader;

import { createContext } from 'react';

// interface ProductContextProp {
// 	setProductId: (value: number) => void,
// 	productId: number
// }


const ProductContext = createContext<any>(null);

export default ProductContext;

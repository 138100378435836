import { gql } from "@apollo/client";

export const GetDeals = gql`
	query GetDeals {
		deal_of_the_day(order_by: {id: asc}) {
			id
			expiry
			enable
			discount
			product_type {
				id
				name
			}
			product {
				id
				name
			}
		}
	}
`;


export const GetProducts = gql`
	query GetProducts {
		product(where: {isDeleted: {_eq: false}}) {
			id
			name
    		productsSubCategories {
				subCategoryId
			}
		}
	}
`;



export const GetProductTypes = gql`
	query GetProducts {
		product_type(where: {isDeleted: {_eq: false}}) {
			id
			name
		}
	}
`;

export const UpdateDeal = gql`
	mutation UpdateDeal($expiry: timestamptz!, $productId: Int, $productTypeId: Int, $dealId: bigint!, $discount: Int!) {
		update_deal_of_the_day(where: {id: {_eq: $dealId}}, _set: {productId: $productId, productTypeId: $productTypeId, expiry: $expiry,  discount: $discount}) {
			affected_rows
		}
	}
`;

export const CreateCategory = gql`
	mutation CreateCategory($name: String!) {
		insert_categories(objects: {name: $name}) {
			affected_rows
		}
	}
`;

export const ToggleDeal = gql`
	mutation ToggleDeal($enable: Boolean!, $dealId: bigint!) {
		update_deal_of_the_day(where: {id: {_eq: $dealId}}, _set: {enable: $enable}) {
			affected_rows
		}
	}
`;


import React, { useState } from "react";
import WithLoading from "../../../../UI/HOC/WithLoading";
import Input from "../../../../UI/Input/Input";

interface GridTypeProps {
	enableSubmit: boolean;
	isEdit: boolean;
	loading: boolean;
	rows?: Number | undefined | null;
	columns?: Number | undefined | null;
	onChange: ({ rows, columns }) => void;
	onSubmit: () => void;
}

const GridType: React.FC<GridTypeProps> = ({
	rows,
	columns,
	enableSubmit,
	isEdit,
	loading,
	onSubmit,
	onChange,
	children,
}) => {
	const [row, setRows] = useState<Number | undefined | null>(rows);
	const [column, setColumns] = useState<Number | undefined | null>(columns);

	const disableButton = !enableSubmit || !row || !column;

	return (
		<div className="d-flex-column w-100 justify-content-between">
			<div className="d-flex w-100 justify-content-between-start">
				<div className="w-40">
					<Input
						placeholder={"Rows*"}
						label
						type={"number"}
						disabled={false}
						value={row?.toString() ?? ""}
						onChange={(event: string) => {
							setRows(parseInt(event));
							onChange({
								rows: parseInt(event),
								columns: column,
							});
						}}
					/>
				</div>
				<div className="w-40">
					<Input
						placeholder={"Columns*"}
						label
						type={"number"}
						disabled={false}
						value={column?.toString() ?? ""}
						onChange={(event: string) => {
							setColumns(parseInt(event));
							onChange({
								rows: row,
								columns: parseInt(event),
							});
						}}
					/>
				</div>
			</div>
			<br></br>

			{children}
			<br></br>
			<br></br>
			<div>
				<WithLoading isLoading={loading}>
					<button
						type="button"
						className="button__primary button__primary-active"
						disabled={disableButton}
						onClick={() => {
							onSubmit();
						}}
					>
						{isEdit ? "Edit" : "Save"}
					</button>
				</WithLoading>
			</div>
			<br></br>
			<br></br>
		</div>
	);
};

export default GridType;

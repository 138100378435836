import { useMutation, useQuery } from "@apollo/client";
import { Chip, CircularProgress } from "@material-ui/core";
import React, { useContext, useState } from "react";
import SnackbarContext from "../../../Context/SnackbarContext";
import Empty from "../../UI/Empty/Empty";
import Error from "../../UI/Error/Error";

import "./SubCategory.scss";
import CategoryEdit from "./SubCategoryEdit/SubCategoryEdit";
import { GetSubCategories, ToggleSubCategory } from "./SubCategoryQuery";

const SubCategory: React.FC = () => {
	const {
		data: subCategories,
		error,
		loading,
		refetch,
	} = useQuery(GetSubCategories);
	const [editSeason, setEditSeason] = useState<any>(null);
	const [seasonModal, setSeasonModal] = useState<boolean>(false);

	if (error) {
		return <Error error={error} />;
	}

	const callRefetch = () => {
		refetch();
	};

	return (
		<div className="container-main">
			<CategoryEdit
				isOpen={seasonModal}
				setModal={setSeasonModal}
				refetch={callRefetch}
				setEditData={setEditSeason}
				editData={editSeason}
			/>

			<div className="season__box">
				<div className="season__main">
					<div className="season__header">
						<p className="title">Sub-Categories</p>
						<button
							type="button"
							onClick={() => {
								setSeasonModal(true);
							}}
						>
							+Add Sub-Category
						</button>
					</div>
					<CategoryTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : subCategories.sub_categories.length > 0 ? (
						subCategories.sub_categories.map((element: any) => {
							//console.log(element);
							return (
								<SubCategoryItem
									element={element}
									refetch={callRefetch}
									key={element.id}
									onClickEdit={() => {
										setEditSeason(element);
										setSeasonModal(true);
									}}
								/>
							);
						})
					) : (
						<Empty />
					)}
				</div>
			</div>
		</div>
	);
};

export default SubCategory;

const CategoryTitle: React.FC = () => {
	return (
		<div className="subCategory__table">
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Name</p>
			<p className="season__table-title">Category</p>
		</div>
	);
};

interface SeasonItemProps {
	element: any;
	onClickEdit: () => void;
	refetch: () => void;
}

const SubCategoryItem: React.FC<SeasonItemProps> = (props: SeasonItemProps) => {
	const { element, onClickEdit, refetch } = props;
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const [toggleSubCategory] = useMutation(ToggleSubCategory);
	const [loading, setLoading] = useState(false);

	const categoryList = element.categoriesSubCategories.map((e) => e.category);

	const onToggle = () => {
		setLoading(true);
		toggleSubCategory({
			variables: {
				subCategoryId: element.id,
				isDeleted: !element.isDeleted,
			},
		})
			.then(({ data }) => {
				setSnackbar(true);
				setMessage("Sub Category updated Successfully");
				setLoading(false);
				refetch();
			})
			.catch((error) => {
				setSnackbar(true);
				setLoading(false);
				setMessage(error.message);
			});
	};

	console.log(element);

	return (
		<div className="subCategory__table">
			<p className="season__table-item">{element.id}</p>
			<p className="season__table-item">{element.name}</p>
			<div className="season__table-item">
				{categoryList && categoryList.length > 0 && (
					<div className="subCategory__table-chip-container">
						{categoryList.map((chipData) => {
							return (
								<div style={{ margin: "2px 2px" }}>
									<Chip
										label={chipData.name}
										key={chipData.id}
										size="small"
									></Chip>
								</div>
							);
						})}
					</div>
				)}
			</div>

			<div className="season__actions">
				<button className="btn-list" onClick={onClickEdit}>
					Edit
				</button>

				{loading ? (
					<div
						style={{ width: "4rem" }}
						className="spinner-container"
					>
						<CircularProgress size={20} thickness={3} />
					</div>
				) : (
					<button
						className="btn-list"
						onClick={() => {
							onToggle();
						}}
					>
						{element.isDeleted ? "Enable" : "Disable"}
					</button>
				)}
			</div>
		</div>
	);
};

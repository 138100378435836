import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import "./BlogEditor.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

interface BlogEditorProps {
	convertedContent: any;
	setConvertedContent: (value: any) => void;
}

const Blog: React.FC<BlogEditorProps> = (props) => {
	const { convertedContent, setConvertedContent } = props;

	const [editorState, setEditorState] = useState<EditorState>(() =>
		EditorState.createEmpty()
	);

	const editor = React.useRef<any>();

	useEffect(() => {
		setConvertedContent(
			draftToHtml(convertToRaw(editorState.getCurrentContent()))
		);
	}, [editorState, setConvertedContent]);

	useEffect(() => {
		if (convertedContent) {
			const blocksFromHtml = htmlToDraft(convertedContent);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(
				contentBlocks,
				entityMap
			);
			setEditorState(EditorState.createWithContent(contentState));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Editor
			ref={editor}
			editorState={editorState}
			onEditorStateChange={setEditorState}
			wrapperClassName="wrapper-class"
			editorClassName="editor-class"
			toolbarClassName="toolbar-class"
		/>
	);
};

export default Blog;

import { useApolloClient, useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { imageUpload } from "../../../Utils/imageUpload";
import ImageView from "../../UI/ImageView/ImageView";
import Input from "../../UI/Input/Input";
import Search from "../../UI/Search/Search";
import Checkbox from "../../UI/Checkbox/Checkbox";

import { GetProducts, GetProductTypes } from "../Deal/DealQuery";
import { GetSubCategories } from "../SubCategory/SubCategoryQuery";
import "./Banner.scss";
import { BannerTypeProps, BannerProps } from "./BannerEdit";
import { UpdateBannerTypeOne } from "./BannerQuery";

export const TypeOneBanner: React.FC<BannerTypeProps> = ({
	bannerDetails,
	refetch,
}) => {
	return (
		<>
			{bannerDetails.banners.map((banner) => (
				<TypeOne banner={banner} refetch={refetch} key={banner.id} />
			))}
		</>
	);
};

const TypeOne: React.FC<BannerProps> = ({ banner, refetch }) => {
	const [title, setTitle] = useState<string>(banner.title);
	const [heading, setHeading] = useState<string>(banner.heading);
	const [image, setImage] = useState<string>(banner.image);

	const [file, setFile] = useState<any>(null);
	console.log(banner);
	const [fieldType, setFieldType] = useState<number>(
		banner.bannerProducts[0].product
			? 1
			: banner.bannerProducts[0].product_type
			? 2
			: 3
	);
	const client = useApolloClient();
	const [options, setOptions] = useState<any[]>([]);
	const [field, setField] = useState<any>(null);
	const [mutationLoading, setLoading] = useState(false);
	const [isDeleted, setIsDeleted] = useState(banner.isDeleted);
	const [updateBanner] = useMutation(UpdateBannerTypeOne);

	useEffect(() => {
		setTitle(banner.title);
		setHeading(banner.heading);
		setImage(banner.image);
		setFieldType(
			banner.bannerProducts[0].product
				? 1
				: banner.bannerProducts[0].product_type
				? 2
				: 3
		);
		setIsDeleted(banner.isDeleted);
		setField(
			banner.bannerProducts[0].product
				? banner.bannerProducts[0].product
				: banner.bannerProducts[0].product_type
				? banner.bannerProducts[0].product_type
				: banner.bannerProducts[0].sub_category
		);
	}, [banner]);

	useEffect(() => {
		if (fieldType === 1) {
			client
				.query({ query: GetProducts })
				.then(({ data: { product } }) => {
					setOptions(product);

					setField(banner.bannerProducts[0].product);
					setFieldType(1);
				});
		} else if (fieldType === 2) {
			client
				.query({ query: GetProductTypes })
				.then(({ data: { product_type } }) => {
					setOptions(product_type);
					setField(banner.bannerProducts[0].product_type);
					setFieldType(2);
				});
		} else {
			client
				.query({ query: GetSubCategories })
				.then(({ data: { sub_categories } }) => {
					setOptions(sub_categories);
					setField(banner.bannerProducts[0].sub_category);
					setFieldType(3);
				});
		}
	}, [fieldType, client, banner.bannerProducts]);

	console.log(fieldType);

	const onToggle = () => {
		setLoading(true);
		updateBanner({
			variables: {
				bannerId: banner.id,
				isDeleted: !isDeleted,
				title,
				heading,
				image,
				productId: fieldType === 1 ? field.id : null,
				productTypeId: fieldType === 2 ? field.id : null,
				subCategoryId: fieldType === 3 ? field.id : null,
			},
		})
			.then(({ data }) => {
				setLoading(false);
				refetch(null);
			})
			.catch((error) => {
				refetch(error);
			});
	};

	const onSave = async () => {
		setLoading(true);

		let imageUrl = image;
		if (file) {
			imageUrl = await imageUpload(file, `/images/Banners/${title}`);
		}
		updateBanner({
			variables: {
				bannerId: banner.id,
				title,
				heading,
				image: imageUrl,
				isDeleted,
				productId: fieldType === 1 ? field.id : null,
				productTypeId: fieldType === 2 ? field.id : null,
				subCategoryId: fieldType === 3 ? field.id : null,
			},
		})
			.then(({ data }) => {
				refetch(null);
				setLoading(false);
				refetch(null);
			})
			.catch((error) => {
				refetch(error);

				setLoading(false);
				refetch(error);
			});
	};

	return (
		<div className="banner__one">
			<div className="w-100">
				<div style={{ height: "auto" }}>
					<ImageView
						uniqueName={banner.id}
						imageUrl={banner.image}
						setImage={setFile}
						label={"Banner Image"}
					/>
				</div>

				<div className="banner__one-footer">
					{!mutationLoading ? (
						<>
							<button
								onClick={onSave}
								disabled={!title || !heading || !field}
								className="banner__button"
							>
								Save
							</button>
						</>
					) : (
						<div className="w-100">
							<div className="spinner-container">
								<CircularProgress size={40} thickness={4} />
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="">
				<Input
					placeholder={"Title"}
					label
					type={"text"}
					disabled={false}
					value={title}
					onChange={(event: string) => {
						return setTitle(event);
					}}
				/>
				<Input
					placeholder={"Heading"}
					label
					type={"text"}
					disabled={false}
					value={heading}
					onChange={(event: string) => {
						return setHeading(event);
					}}
				/>
				<div className="banner__one-checkbox">
					<div style={{ width: "10rem" }}>
						<Checkbox
							checked={fieldType === 1}
							setChecked={() => {
								setFieldType(1);
							}}
							label={"Add Product"}
						/>
					</div>
					<div style={{ width: "11rem" }}>
						<Checkbox
							checked={fieldType === 2}
							setChecked={() => {
								setFieldType(2);
							}}
							label={"Add Product Type"}
						/>
					</div>
					<div style={{ width: "11rem" }}>
						<Checkbox
							checked={fieldType === 3}
							setChecked={() => {
								setFieldType(3);
							}}
							label={"Add Sub Category"}
						/>
					</div>
				</div>

				<Search
					label={
						fieldType === 1
							? "Product"
							: fieldType === 2
							? "Product Type"
							: "Sub Category"
					}
					options={options}
					value={field ? field?.name : ""}
					onChangeSelect={(event: { id: number; name: string }) => {
						setField(event);
					}}
				/>
			</div>
		</div>
	);
};

import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/storage';



const firebaseConfig = {
	apiKey: "AIzaSyDhvK8SWMn9mzwgW9ZhgrSq9hXxvCdZiG4",
	authDomain: "indoamerican-dev.firebaseapp.com",
	projectId: "indoamerican-dev",
	storageBucket: "indoamerican-dev.appspot.com",
	messagingSenderId: "262645124787",
	appId: "1:262645124787:web:20e67a682fb5dfb9072ac6",
	measurementId: "G-58Q1GBTM19"
};

export default firebase.initializeApp(firebaseConfig);
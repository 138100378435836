import { gql } from "@apollo/client";

export const GetTestimonials = gql`
	query GetTestimonials {
		testimonials(order_by: { id: asc }) {
			author
			id
			testimonial
			designation
			updatedAt
		}
	}
`;

export const DeleteTestimonial = gql`
	mutation DeleteTestimonial($testimonialId: Int!) {
		delete_testimonials(where: { id: { _eq: $testimonialId } }) {
			affected_rows
		}
	}
`;

export const UpdateTestimonial = gql`
	mutation UpdateTestimonial(
		$author: String!
		$designation: String!
		$testimonial: String!
		$testimonialId: Int!
	) {
		update_testimonials(
			where: { id: { _eq: $testimonialId } }
			_set: {
				author: $author
				designation: $designation
				testimonial: $testimonial
			}
		) {
			affected_rows
		}
	}
`;

export const InsertTestimonial = gql`
	mutation InsertTestimonial(
		$author: String!
		$designation: String!
		$testimonial: String!
	) {
		insert_testimonials(
			objects: {
				author: $author
				designation: $designation
				testimonial: $testimonial
			}
		) {
			affected_rows
		}
	}
`;

import "./Product.scss";
import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import SearchBox from "../../UI/SearchBox/SearchBox";
import Error from "../../UI/Error/Error";
import { Chip, CircularProgress } from "@material-ui/core";
import Empty from "../../UI/Empty/Empty";
import Pagination from "../../UI/Pagination/Pagination";
import SnackbarContext from "../../../Context/SnackbarContext";
import { GetProducts, GetProductsCount, ToggleProduct } from "./ProductQuery";
import { useHistory, useLocation } from "react-router-dom";
import ProductContext from "../../../Context/ProductContext";
import WithLoading from "../../UI/HOC/WithLoading";

export const useQueryParams = () => {
	return new URLSearchParams(useLocation().search);
};

const Product = () => {
	const query = useQueryParams();

	const [searchString, setSearchString] = useState<string>("");
	const [limit] = useState(8);
	const [offset, setOffset] = useState(
		query.get("offset") ? parseInt(query?.get("offset") ?? "0") : 0
	);
	const history = useHistory();
	const { setProductId } = useContext(ProductContext);

	const {
		data: products,
		error,
		loading,
		refetch,
	} = useQuery(GetProducts, {
		variables: {
			searchString: `%${searchString}%`,
			limit,
			offset,
		},
	});

	const {
		data: productCount,
		error: errorCount,
		loading: countLoading,
		refetch: countRefetch,
	} = useQuery(GetProductsCount, {
		variables: {
			searchString: `%${searchString}%`,
		},
	});

	const callRefetch = () => {
		refetch();
		countRefetch();
	};

	if (error || errorCount) {
		return <Error error={error ? error : errorCount} />;
	}

	return (
		<div className="container-main">
			<div className="w-40">
				<SearchBox
					placeholder="Search product with name"
					onChangeSearch={(value) => {
						setSearchString(value);
					}}
				/>
			</div>
			<div className="coupon__box">
				<div className="coupon__main">
					<div className="season__header">
						<p className="title">Product</p>
						<button
							type="button"
							onClick={() => {
								history.push(
									"/dashboard/products/product-detail?mode=add&id=0"
								);
							}}
						>
							+Add Product
						</button>
					</div>
					<ProductTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : products.product.length > 0 ? (
						products.product.map((element: any) => {
							return (
								<ProductItem
									element={element}
									key={element.id}
									refetch={callRefetch}
									onClickEdit={() => {
										setProductId(element.id);
										history.push(
											`/dashboard/products/product-detail?mode=edit&id=${element.id}`
										);
									}}
								/>
							);
						})
					) : (
						<Empty />
					)}
				</div>

				{!loading && !countLoading && (
					<Pagination
						limit={limit}
						offset={offset}
						count={productCount.product_aggregate.aggregate.count}
						changeOffset={(value) => setOffset(value)}
					/>
				)}
			</div>
		</div>
	);
};

const ProductTitle: React.FC = () => {
	return (
		<div className="product__table">
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Name</p>
			<p className="season__table-title">Sub-Category</p>
			{/* {<p className="season__table-title">Category</p>} */}
		</div>
	);
};

interface ItemProps {
	element: any;
	refetch: () => void;
	onClickEdit: () => void;
}

const ProductItem: React.FC<ItemProps> = (props: ItemProps) => {
	const { element, onClickEdit, refetch } = props;
	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [toggleProduct] = useMutation(ToggleProduct);
	const [loading, setLoading] = useState(false);

	const subCategoriesList = element.productsSubCategories.map(
		(e) => e.subCategory
	);

	const onToggle = () => {
		setLoading(true);
		toggleProduct({
			variables: {
				productId: element.id,
				isDeleted: !element.isDeleted,
			},
		})
			.then(({ data }) => {
				setSnackbar(true);
				setMessage("Product updated Successfully");
				setLoading(false);
				refetch();
			})
			.catch((error) => {
				setSnackbar(true);
				setMessage(error.message);
			});
	};

	return (
		<div className="product__table">
			<p className="product__table-item">{element.id}</p>
			<p className="product__table-item">{element.name}</p>
			<div className="product__table-item">
				{subCategoriesList && subCategoriesList.length > 0 && (
					<div className="product__table-item__chip-container">
						{subCategoriesList.map((chipData) => {
							return (
								<div style={{ margin: "2px 2px" }}>
									<Chip
										label={chipData.name}
										key={chipData.id}
										size="small"
									></Chip>
								</div>
							);
						})}
					</div>
				)}
			</div>

			{/* <p className="season__table-item">
				{element.productsSubCategories?.subCategory?.categoriesSubCategories?.category
					? element.productsSubCategories?.subCategory?.categoriesSubCategories?.category.name
					: ""}
			</p> */}

			<div className="product__table-item-actions">
				<button className="btn-list" onClick={onClickEdit}>
					View
				</button>
				<WithLoading isLoading={loading} size={20} thickness={3}>
					<button
						className="btn-list"
						onClick={() => {
							onToggle();
						}}
					>
						{element.isDeleted ? "Enable" : "Disable"}
					</button>
				</WithLoading>

				{/* {loading ? (
					<div style={{ width: "4rem" }}>
						<CircularProgress size={20} thickness={3} />
					</div>
				) : (
					<button
						className="btn-list"
						onClick={() => {
							onToggle();
						}}
					>
						{element.isDeleted ? "Enable" : "Disable"}
					</button>
				)} */}
			</div>
		</div>
	);
};

export default Product;

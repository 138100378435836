import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Error from "../../UI/Error/Error";
import { GetBannerDetails } from "./BannerQuery";
import "./Banner.scss";

import SnackbarContext from "../../../Context/SnackbarContext";
import { TypeOneBanner } from "./BannerTypeOne";
import { TypeTwoBanner } from "./BannerTypeTwo";
import { TypeThreeBanner } from "./BannerTypeThree";
import { TypeFourBanner } from "./BannerTypeFour";

const BannerEdit: React.FC = () => {
	const { id: typeId } = useParams<{ id: string }>();

	const { data: bannerDetails, loading, refetch } = useQuery(
		GetBannerDetails,
		{
			variables: {
				typeId: parseInt(typeId),
			},
		}
	);

	const history = useHistory();

	const callRefetch = (error) => {
		if (error) {
			setSnackbar(true);
			setMessage(error.message);
		} else {
			refetch();
			setSnackbar(true);
			setMessage("Banner updated Successfully");
		}
	};
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	return (
		<div className="container-main">
			<div className="banner__container">
				{loading ? (
					<div className="spinner-container">
						<CircularProgress size={40} thickness={4} />
					</div>
				) : (
					<>
						<div className="w-100 d-flex justify-content-between">
							<h1 className="title">
								{bannerDetails.banner_type[0].name}
							</h1>
							<button
								type="button"
								className="button__primary button__primary-active"
								onClick={() => {
									history.goBack();
								}}
							>
								Back
							</button>
						</div>

						<CustomSwitch
							typeId={parseInt(typeId)}
							refetch={callRefetch}
							bannerDetails={bannerDetails.banner_type[0]}
						/>
					</>
				)}
			</div>
		</div>
	);
};

interface CustomSwitchBanner {
	typeId: number;
	bannerDetails: any;
	refetch: (error: null | any) => void;
}

const CustomSwitch: React.FC<CustomSwitchBanner> = ({
	typeId,
	bannerDetails,
	refetch,
}) => {
	switch (typeId) {
		case 1:
			return (
				<TypeOneBanner
					bannerDetails={bannerDetails}
					refetch={refetch}
				/>
			);
		case 2:
			return (
				<TypeTwoBanner
					bannerDetails={bannerDetails}
					refetch={refetch}
				/>
			);
		case 3:
			return (
				<TypeThreeBanner
					bannerDetails={bannerDetails}
					refetch={refetch}
				/>
			);
		case 4:
			return (
				<TypeFourBanner
					bannerDetails={bannerDetails}
					refetch={refetch}
				/>
			);
		default:
			return (
				<Error
					error={{
						message: "Provided Banner Type is not valid ",
					}}
				/>
			);
	}
};

export default BannerEdit;

export interface BannerTypeProps {
	bannerDetails: any;
	refetch: (error: null | any) => void;
}

export interface BannerProps {
	banner: any;
	refetch: (error: null | any) => void;
}

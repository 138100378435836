import React, { useState } from "react";
import WithLoading from "../../../../UI/HOC/WithLoading";
import ImageView from "../../../../UI/ImageView/ImageView";
import Input from "../../../../UI/Input/Input";
import TextArea from "../../../../UI/Textarea/Textarea";

interface HeaderTypeProps {
	enableSubmit: boolean;
	isEdit: boolean;
	loading: boolean;
	subTitle?: string;
	imageUrl?: string;
	onChange: ({ subTitle, fileImage }) => void;
	onSubmit: () => void;
}

const HeaderType = ({
	enableSubmit,
	isEdit,
	loading,
	subTitle,
	imageUrl,
	onSubmit,
	onChange,
}: HeaderTypeProps) => {
	const [subtitle, setSubTitle] = useState<string | undefined>(subTitle);
	const [fileimage, setFileImage] = useState<string | undefined>();

	const disableButton =
		!enableSubmit ||
		!subtitle ||
		subtitle.length === 0 ||
		!(fileimage || (imageUrl && imageUrl.length > 0));

	return (
		<div className="d-flex-column w-100 justify-content-between">
			<div className="d-flex w-100 justify-content-between-start">
				<div className="w-40">
					<TextArea
						placeholder={"Sub Title*"}
						label
						rows={5}
						columns={40}
						disabled={false}
						value={subtitle ?? ""}
						onChange={(event: string) => {
							setSubTitle(event);
							onChange({
								subTitle: event,
								fileImage: fileimage,
							});
						}}
					/>
				</div>
				<div
					className="w-40"
					style={{ marginTop: "0px", paddingTop: "0px" }}
				>
					<ImageView
						uniqueName={"HeaderImageUrl*"}
						imageUrl={imageUrl ?? ""}
						setImage={(value) => {
							setFileImage(value);
							onChange({
								subTitle: subtitle,
								fileImage: value,
							});
						}}
						label={"Image*"}
					/>
				</div>
			</div>
			<br></br>
			<br></br>
			<div className="w-25">
				<WithLoading isLoading={loading}>
					<button
						type="button"
						className="button__primary button__primary-active"
						disabled={disableButton}
						onClick={() => {
							onSubmit();
						}}
					>
						{isEdit ? "Edit" : "Save"}
					</button>
				</WithLoading>
			</div>
			<br></br>
			<br></br>
		</div>
	);
};

export default HeaderType;

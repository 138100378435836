import { useMutation, useQuery } from "@apollo/client";
import { Chip, CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import SnackbarContext from "../../../../Context/SnackbarContext";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import {
	CreateCategory,
	DeleteCategoriesCategoryTypes,
	GetCategoryTypes,
	InsertCategoriesCategoryTypes,
	UpdateCategoriesById,
} from "../CategoryQuery";
import close from "../../../../Assets/Images/cross.svg";
import Input from "../../../UI/Input/Input";
import "../Category.scss";
import Search from "../../../UI/Search/Search";
import {
	Categories_Category_Types,
	Category_Types,
} from "../../../../generated/graphql";

interface CategoryEditProps {
	isOpen: boolean;
	editData: any;
	setEditData: (element: any | null) => void;
	setModal: (value: boolean) => void;
	refetch: () => void;
}
const CategoryEdit: React.FC<CategoryEditProps> = ({
	isOpen,
	editData,
	setEditData,
	setModal,
	refetch,
}: CategoryEditProps) => {
	const [name, setName] = useState<string>("");
	const [categoryTypeList, setCategoryTypeList] = useState<any>([]);
	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateCategory] = useMutation(UpdateCategoriesById);
	const [createCategory] = useMutation(CreateCategory);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const { data: categoryTypeData, loading } = useQuery(GetCategoryTypes);
	const [deleteCategoryCategoryTypes] = useMutation(
		DeleteCategoriesCategoryTypes
	);

	const [insertCategoriesCategoryTypes] = useMutation(
		InsertCategoriesCategoryTypes
	);

	useEffect(() => {
		if (editData) {
			setName(editData && editData.name);
			setCategoryTypeList(
				editData &&
					editData.categoriesCategoryTypes.map((e) => e.categoryType)
			);
		}
	}, [editData]);

	const clearState = () => {
		setName("");
		setCategoryTypeList([]);
		setEditData(null);
	};

	const handleCategoryTypeDelete = (category) => {
		setCategoryTypeList((values) => {
			return values.filter((val) => {
				return val.id != category.id;
			});
		});
	};

	const onEdit = async () => {
		setMutationLoading(true);

		try {
			// Delete Categories_Category_Types
			const {
				data: {
					delete_categories_category_types: { affected_rows },
				},
			} = await deleteCategoryCategoryTypes({
				variables: {
					_or: generateOrExp(
						filterCategoryTypesToDelete({
							newCategoryTypes:
								categoryTypeList as Array<Category_Types>,
							currentCategoryTypes: getCurrentCategoryTypes(),
						})
					),
				},
			});
		} catch (error: any) {
			setMessage(error.message);
			setSnackbar(true);
			setMutationLoading(false);
			return;
		}

		const categoriesCategoryTypesObject = categoryTypeList.map((e) => {
			return { categoryTypeId: e.id, categoryId: editData.id };
		});

		updateCategory({
			variables: {
				categoryId: editData.id,
				name,
				categoriesCategoryTypesObject,
			},
		})
			.then(({ data: { update_categories: category } }: any) => {
				if (category.affected_rows > 0) {
					setMessage("Category Updated Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	const onSave = async () => {
		setMutationLoading(true);

		try {
			const {
				data: {
					insert_categories_one: { id },
				},
			} = await createCategory({
				variables: {
					name: name,
				},
			});

			console.log(id);
			const categoriesCategoryTypesObject = categoryTypeList.map((e) => {
				return { categoryTypeId: e.id, categoryId: id };
			});

			const {
				data: {
					insert_categories_category_types: { affected_rows },
				},
			} = await insertCategoriesCategoryTypes({
				variables: {
					categoriesCategoryTypesObject:
						categoriesCategoryTypesObject,
				},
			});

			setMessage("Category Inserted Successfully");
			setSnackbar(true);
			refetch();
			clearState();
			setMutationLoading(false);
			return setModal(false);
		} catch (error) {
			console.log(error);
			setMessage("Some Unknown Error Occurred");
			setSnackbar(true);
			setMutationLoading(false);
		}
		/* createSeason({
			variables: {
				name: name,
			},
		})
			.then(({ data: { insert_categories: category } }: any) => {
				if (category.affected_rows > 0) {
					setMessage("Category Inserted Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			}); */
	};

	const getCurrentCategoryTypes =
		(): Array<Category_Types> => /*: Promise<[Categories]>*/ {
			// TODO: Fetch current categorires connected to the subcategories
			// For now, returning the previously fetched data

			if (!editData) return [];
			return editData.categoriesCategoryTypes.map(
				(e: Categories_Category_Types) => {
					return e.categoryType as Category_Types;
				}
			);
		};

	const filterCategoryTypesToDelete = ({
		currentCategoryTypes,
		newCategoryTypes,
	}: {
		currentCategoryTypes: Array<Category_Types>;
		newCategoryTypes: Array<Category_Types>;
	}): Array<Category_Types> => {
		return currentCategoryTypes.filter((c) => {
			const found = newCategoryTypes.find((n) => c.id === n.id);
			return !found;
		});
	};

	const generateOrExp = (
		categoriesTypesToDelete: Array<Category_Types>
	): Array<any> => {
		if (!editData) return [];
		const categoryId = editData.id;
		return categoriesTypesToDelete.map((categoryType: Category_Types) => {
			return {
				_and: [
					{ categoryTypeId: { _eq: categoryType.id } },
					{ categoryId: { _eq: categoryId } },
				],
			};
		});
	};

	return (
		<ReactModal
			className="subCategory__modal  Modal__main"
			onRequestClose={() => {
				clearState();
				setModal(false);
			}}
			overlayClassName="Overlay"
			isOpen={isOpen}
		>
			<div className="close">
				<img
					src={close}
					alt="Close"
					className="close__img"
					onClick={() => {
						clearState();
						setModal(false);
					}}
				/>
			</div>
			{loading ? (
				<div className="spinner-container" style={{ marginTop: "3em" }}>
					<CircularProgress size={40} thickness={4} />
				</div>
			) : (
				<div className="add__modal__content">
					<p className="add__modal__header">
						{editData ? "Edit Category" : "Add Category"}
					</p>
					<div className="margin-auto w-75">
						<Input
							placeholder={"Name"}
							label
							type={"text"}
							disabled={false}
							value={name}
							onChange={(event: string) => {
								return setName(event);
							}}
						/>
						<Search
							label="Category Type"
							options={categoryTypeData.category_types}
							value={""}
							//value={category && category.name}
							onChangeSelect={(event: {
								id: number;
								name: string;
							}) => {
								if (!event || !event.id) return;
								setCategoryTypeList((values) => {
									const duplicateValue = values?.filter(
										(val) => val.id === event.id
									);
									return !duplicateValue ||
										duplicateValue.length === 0
										? values
											? [...values, event]
											: [event]
										: values;
								});
							}}
						/>
						{categoryTypeList && categoryTypeList.length > 0 && (
							<div className="subCategory__modal__chip-container">
								{categoryTypeList.map((chipData) => {
									return (
										<div style={{ margin: "2px 5px" }}>
											<Chip
												label={chipData.name}
												key={chipData.id}
												onDelete={() =>
													handleCategoryTypeDelete(
														chipData
													)
												}
											></Chip>
										</div>
									);
								})}
							</div>
						)}
					</div>

					{!mutationLoading ? (
						<div
							className="w-40 margin-auto"
							style={{ marginTop: "2em" }}
						>
							<PrimaryButton
								label="Save"
								disabled={!name}
								loading={mutationLoading}
								active
								onClick={() => {
									editData ? onEdit() : onSave();
								}}
							/>
						</div>
					) : (
						<div
							className="spinner-container"
							style={{ marginTop: "3em" }}
						>
							<CircularProgress size={40} thickness={4} />
						</div>
					)}
				</div>
			)}
		</ReactModal>
	);
};

export default CategoryEdit;

import { useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import close from "../../../../Assets/Images/cross.svg";
import SnackbarContext from "../../../../Context/SnackbarContext";
import Input from "../../../UI/Input/Input";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";

import "../../Category/Category.css";
import {
	CreateCategoryType,
	UpdateCategoryTypeById,
} from "../CategoryTypeQuery";

interface CategoryTypeEditProps {
	isOpen: boolean;
	editData: any;
	setEditData: (element: any | null) => void;
	setModal: (value: boolean) => void;
	refetch: () => void;
}
const CategoryTypeEdit: React.FC<CategoryTypeEditProps> = ({
	isOpen,
	editData,
	setEditData,
	setModal,
	refetch,
}: CategoryTypeEditProps) => {
	const [name, setName] = useState<string>("");
	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateCategory] = useMutation(UpdateCategoryTypeById);
	const [createSeason] = useMutation(CreateCategoryType);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const clearState = () => {
		setName("");
		setEditData(null);
	};

	const onEdit = () => {
		setMutationLoading(true);
		updateCategory({
			variables: {
				categoryTypeId: editData.id,
				name,
			},
		})
			.then(({ data: { update_category_types: category } }: any) => {
				if (category.affected_rows > 0) {
					setMessage("Category Type Updated Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	const onSave = () => {
		setMutationLoading(true);
		createSeason({
			variables: {
				name: name,
			},
		})
			.then(({ data: { insert_category_types: category } }: any) => {
				if (category.affected_rows > 0) {
					setMessage("New Category Type Created Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	useEffect(() => {
		if (editData) {
			setName(editData && editData.name);
		}
	}, [editData]);

	return (
		<ReactModal
			className="season__modal Modal__main"
			onRequestClose={() => {
				clearState();
				setModal(false);
			}}
			overlayClassName="Overlay"
			isOpen={isOpen}
		>
			<div className="close">
				<img
					src={close}
					alt="Close"
					className="close__img"
					onClick={() => {
						clearState();
						setModal(false);
					}}
				/>
			</div>
			<div className="add__modal__content">
				<p className="add__modal__header">
					{editData ? "Edit Category Type" : "Add Category Type"}
				</p>
				<div className="margin-auto w-75">
					<Input
						placeholder={"Name"}
						label
						type={"text"}
						disabled={false}
						value={name}
						onChange={(event: string) => {
							return setName(event);
						}}
					/>
				</div>

				{!mutationLoading ? (
					<div
						className="w-40 margin-auto"
						style={{ marginTop: "2em" }}
					>
						<PrimaryButton
							label="Save"
							disabled={!name}
							loading={mutationLoading}
							active
							onClick={() => {
								editData ? onEdit() : onSave();
							}}
						/>
					</div>
				) : (
					<div
						className="spinner-container"
						style={{ marginTop: "3em" }}
					>
						<CircularProgress size={40} thickness={4} />
					</div>
				)}
			</div>
		</ReactModal>
	);
};

export default CategoryTypeEdit;

import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SnackbarContext from "../../../Context/SnackbarContext";
import { imageUpload } from "../../../Utils/imageUpload";
import Input from "../../UI/Input/Input";
import TextArea from "../../UI/Textarea/Textarea";
import "./Product.scss";
import {
	UpdateInstruction,
	InsertInstruction,
	GetInstructions,
	DeleteInstruction,
} from "./ProductQuery";
import right from "../../../Assets/Images/rightArrow.svg";
import DeleteModal from "../../UI/DeleteModal/DeleteModal";

const Instruction = () => {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const [titleId] = useState<number | null>(
		parseInt(query.get("titleId") as string)
	);
	const [typeId] = useState<number | null>(
		parseInt(query.get("typeId") as string)
	);
	const [title] = useState<string | null>(query.get("title"));

	const { data: instructions, loading, refetch } = useQuery(GetInstructions, {
		variables: {
			instructionTitleId: titleId,
		},
	});

	const history = useHistory();

	return (
		<div className="container-main">
			<div className="banner__container">
				<div className="season__header">
					<p className="title">{!loading && title}</p>

					<button
						type="button"
						className="button__primary button__primary-active"
						onClick={() => {
							history.goBack();
						}}
					>
						Back
					</button>
				</div>

				{loading ? (
					<div
						className="spinner-container"
						style={{ marginTop: "3em" }}
					>
						<CircularProgress size={40} thickness={4} />
					</div>
				) : instructions.instructions.length > 0 ? (
					instructions.instructions.map((element: any) => (
						<InstructionDetail
							key={element.id}
							titleId={titleId}
							instructionDetails={element}
							refetch={refetch}
							typeId={typeId}
							title={title}
						/>
					))
				) : (
					<p />
				)}
				{!loading && (
					<InstructionDetail
						titleId={titleId}
						instructionDetails={null}
						refetch={refetch}
						typeId={typeId}
						title={title}
					/>
				)}
			</div>
		</div>
	);
};

export default Instruction;

interface DetailsProps {
	titleId: number | null;
	instructionDetails: any | null;
	refetch: () => void;
	typeId: number | null;
	title: string | null;
}

const InstructionDetail: React.FC<DetailsProps> = (props: DetailsProps) => {
	const {
		titleId,
		instructionDetails,
		refetch,
		typeId,
		title: instructionTitle,
	} = props;
	const [title, setTitle] = useState<string>("");
	const [index, setIndex] = useState<number>(0);
	const [description, setDescription] = useState<string>("");
	const [mediaUrl, setMediaUrl] = useState<string>("");
	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateInstruction] = useMutation(UpdateInstruction);
	const [insertInstruction] = useMutation(InsertInstruction);

	const [deleteInstruction] = useMutation(DeleteInstruction);
	const [file, setFile] = useState<any>(null);
	const [expand, setExpand] = useState<boolean>(false);

	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [deleteModal, setDeleteModal] = useState<boolean>(false);

	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	console.log(titleId);
	const onEdit = async () => {
		let mediaUrlFirebase = mediaUrl;
		setMutationLoading(true);
		if (file) {
			mediaUrlFirebase = await imageUpload(
				file,
				`/files/Instructions/${instructionTitle}/${title}`
			);
		}
		updateInstruction({
			variables: {
				instructionTitleId: titleId,
				title,
				index,
				description,
				mediaUrl: mediaUrlFirebase,
				instructionId: instructionDetails.id,
			},
		})
			.then(({ data: { update_instructions: title } }: any) => {
				if (title.affected_rows > 0) {
					setMessage("Instruction Title Updated Successfully");
					setSnackbar(true);
					refetch();
					setMutationLoading(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	const onSave = async () => {
		setMutationLoading(true);
		let mediaUrlFirebase = mediaUrl;
		setMutationLoading(true);
		if (file) {
			mediaUrlFirebase = await imageUpload(
				file,
				`/files/Instructions/${instructionTitle}/${title}`
			);
		}
		insertInstruction({
			variables: {
				instructionTitleId: titleId,
				title,
				index,
				description,
				mediaUrl: mediaUrlFirebase,
			},
		})
			.then(({ data: { insert_instructions: title } }: any) => {
				if (title.affected_rows > 0) {
					setMessage("Instruction Title Inserted Successfully");
					setSnackbar(true);
					setMutationLoading(false);
					refetch();

					clearState();
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};
	const clearState = () => {
		setTitle("");
		setDescription("");
		setIndex(1);
	};

	useEffect(() => {
		if (instructionDetails) {
			setTitle(instructionDetails && instructionDetails.title);
			setIndex(instructionDetails && instructionDetails.index);
			setDescription(
				instructionDetails && instructionDetails.description
			);
			setMediaUrl(instructionDetails && instructionDetails.mediaUrl);
		}
	}, [instructionDetails]);

	const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		const uploadedFile: any =
			event.target.files &&
			event.target.files.length > 0 &&
			event.target.files[0];
		setFile(uploadedFile);
		console.log(uploadedFile);
	};

	const onExpand = () => {
		setExpand((expand) => !expand);
	};

	const onDeleteInstruction = async () => {
		try {
			setDeleteLoading(true);
			const {
				data: { delete_instructions: instruction },
			} = await deleteInstruction({
				variables: {
					instructionId: instructionDetails.id,
				},
			});

			if (instruction.affected_rows > 0) {
				setMessage("Instruction Title Deleted Successfully");
				setSnackbar(true);
				refetch();
				setDeleteLoading(false);
			} else {
				setMessage("Some Unknown Error Occurred");
				setDeleteLoading(false);
				return setSnackbar(true);
			}
		} catch (error) {
			setMessage(error.message);
			setSnackbar(true);
			setDeleteLoading(false);
		}
	};

	return (
		<div className="instruction__detail">
			<DeleteModal
				loading={deleteLoading}
				setModal={setDeleteModal}
				modal={deleteModal}
				onClickDelete={() => {
					onDeleteInstruction();
					// deleteId && onDelete(deleteId);
				}}
			/>
			<div
				className="w-100 d-flex justify-content-between"
				onClick={onExpand}
			>
				<p className="">
					{" "}
					{index ? ` Instruction ${index}` : "Add Instruction"}
				</p>

				<img
					src={right}
					alt="Dropdown"
					className="instruction__icon"
					style={{
						transform: expand ? "rotate(-90deg)" : "rotate(90deg)",
					}}
				/>
			</div>
			{expand && (
				<>
					<div className="w-100 d-flex justify-content-between">
						<div className="w-40">
							<Input
								placeholder={"Title"}
								label
								type={"text"}
								disabled={false}
								value={title}
								onChange={(event: string) => {
									return setTitle(event);
								}}
							/>
						</div>

						<div className="w-40">
							<Input
								placeholder={"Index"}
								label
								type={"number"}
								disabled={false}
								value={index.toString()}
								onChange={(event: string) => {
									return setIndex(parseInt(event));
								}}
							/>
						</div>
					</div>
					<div className="w-100 d-flex justify-content-between">
						<TextArea
							placeholder={"Description"}
							label
							rows={6}
							columns={40}
							disabled={false}
							value={description}
							onChange={(event: string) => {
								return setDescription(event);
							}}
						/>

						{typeId === 3 && (
							<>
								<label htmlFor="File">
									<input
										type="file"
										name="file"
										id="File"
										onChange={onChangeFile}
									/>
								</label>
								{mediaUrl && (
									<a
										href={mediaUrl}
										target="_blank"
										rel="noreferrer"
									>
										File
									</a>
								)}
							</>
						)}
					</div>

					<div
						className={`w-40 d-flex ${
							instructionDetails
								? "justify-content-between"
								: "justify-content-center"
						}`}
						style={{ margin: "2em auto 0" }}
					>
						{!mutationLoading ? (
							<button
								className="banner__button"
								disabled={!description || !index}
								onClick={() => {
									instructionDetails ? onEdit() : onSave();
								}}
							>
								{instructionDetails ? "Edit" : "Save"}
							</button>
						) : (
							<div
								className="spinner-container"
								style={{ marginTop: "3em" }}
							>
								<CircularProgress size={40} thickness={4} />
							</div>
						)}

						{instructionDetails && (
							<button
								className="banner__button"
								disabled={!description || !index}
								onClick={() => {
									setDeleteModal(true);
								}}
							>
								Delete
							</button>
						)}
					</div>
				</>
			)}
		</div>
	);
};

import React from "react";

const CatergoryTypeTitle = () => {
	return (
		<div className="season__table">
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Name</p>
		</div>
	);
};

export default CatergoryTypeTitle;

import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Nav from "./Nav/Nav";
import "./Dashboard.scss";
import Season from "./Season/Season";
// import StoreLocation from "./StoreLocation/StoreLocation";
import Faq from "./Faq/Faq";
import FaqDetail from "./Faq/FaqDetail/FaqDetail";
import Coupon from "./Coupon/Coupon";
import User from "./User/User";
import UserDetail from "./User/UserDetail/UserDetail";
import Order from "./Order/Order";
import OrderDetail from "./Order/OrderDetail/OrderDetail";
import Blog from "./Blog/Blog";
import BlogView from "./Blog/BlogView/BlogView";
import Category from "./Category/Category";
import SubCategory from "./SubCategory/SubCategory";
import Deal from "./Deal/Deal";
import Banner from "./Banners/Banner";
import BannerEdit from "./Banners/BannerEdit";
import Product from "./Product/Product";
import ProductDetail from "./Product/ProductDetail";
import ProductType from "./Product/ProductType";
import ProductTypeDetail from "./Product/ProductTypeDetail";
import Recommended from "./Product/Recommended";
import InstructionTitle from "./Product/InstructionTitle";
import Instruction from "./Product/Instruction";
import ProductTypeMain from "./Product/ProductTypeMain";
import Testimonials from "./Testimonials/Testimonials";
import CategoryType from "./CategoryType/CategoryType";
import HomePageRows from "./HomePageRows/HomePageRowsMain/HomePageRows";
import HomePageRowDetail from "./HomePageRows/HomePageRowDetail/HomePageRowDetail";

const Dashboard: React.FC = () => {
	return (
		<div className="dashboard">
			<div className="dashboard__nav ">
				<Nav />
			</div>
			<div className="dashboard__main box-shadow-sm ">
				<Switch>
					<Route exact={true} path="/dashboard/seasons">
						<Season />
					</Route>

					{/* <Route exact={true} path="/dashboard/stores">
						<StoreLocation />
					</Route> */}

					<Route exact={true} path="/dashboard/faqs">
						<Faq />
					</Route>

					<Route exact={true} path="/dashboard/faqs/:id">
						<FaqDetail />
					</Route>

					<Route exact={true} path="/dashboard/coupons">
						<Coupon />
					</Route>

					<Route exact={true} path="/dashboard/users">
						<User />
					</Route>

					<Route exact={true} path="/dashboard/users/:id">
						<UserDetail />
					</Route>

					<Route exact={true} path="/dashboard/orders">
						<Order />
					</Route>

					<Route exact={true} path="/dashboard/orders/:id">
						<OrderDetail />
					</Route>
					<Route exact={true} path="/dashboard/blogs">
						<Blog />
					</Route>

					<Route exact={true} path="/dashboard/blogs/:id">
						<BlogView />
					</Route>

					<Route exact={true} path="/dashboard/category-types">
						<CategoryType />
					</Route>

					<Route exact={true} path="/dashboard/categories">
						<Category />
					</Route>

					<Route exact={true} path="/dashboard/subcategories">
						<SubCategory />
					</Route>

					<Route exact={true} path="/dashboard/deal-of-the-day">
						<Deal />
					</Route>
					<Route exact={true} path="/dashboard/home-page-rows">
						<HomePageRows />
					</Route>
					<Route exact={true} path="/dashboard/home-page-rows/detail">
						<HomePageRowDetail />
					</Route>
					<Route exact={true} path="/dashboard/banners">
						<Banner />
					</Route>

					<Route exact={true} path="/dashboard/banners/:id">
						<BannerEdit />
					</Route>

					<Route exact={true} path="/dashboard/products">
						<Product />
					</Route>

					<Route
						exact={true}
						path="/dashboard/products/product-detail"
					>
						<ProductDetail />
					</Route>

					<Route exact={true} path="/dashboard/product-types/">
						<ProductType />
					</Route>

					<Route
						exact={false}
						path="/dashboard/products/product-type-detail"
					>
						<ProductTypeDetail />
					</Route>
					<Route exact={false} path="/dashboard/recommendations">
						<Recommended />
					</Route>

					<Route exact={false} path="/dashboard/instruction-titles">
						<InstructionTitle />
					</Route>

					<Route exact={false} path="/dashboard/instructions">
						<Instruction />
					</Route>
					<Route exact={true} path="/dashboard/product-types-main">
						<ProductTypeMain />
					</Route>

					<Route exact={true} path="/dashboard/testimonials">
						<Testimonials />
					</Route>
					<Redirect to="/dashboard/category-types" />
				</Switch>
			</div>
		</div>
	);
};

export default Dashboard;

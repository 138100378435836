import React from "react";
import "./Textarea.scss";

interface TextAreaProps {
	placeholder: string;
	onChange: (value: string) => void;
	value: string;
	label: boolean;
	disabled: boolean;
	rows: number;
	columns: number;
}

const TextArea: React.FC<TextAreaProps> = ({
	placeholder,
	onChange,
	value,
	label,
	disabled,
	columns,
	rows,
}) => {
	return (
		<div>
			{label && <p className="label">{placeholder}</p>}
			<textarea
				className="textarea"
				placeholder={placeholder}
				disabled={disabled}
				value={value}
				onChange={(event) => {
					onChange(event.target.value);
				}}
				cols={columns}
				rows={rows}
			/>
		</div>
	);
};

export default TextArea;

import { useQuery } from "@apollo/client";
import React, { useContext, useRef, useState } from "react";
import SearchBox from "../../UI/SearchBox/SearchBox";
import "./Order.scss";
import {DownloadOrderDetails, GetOrderCount, GetOrders, GetOrderStatus } from "./OrderQuery";
import Error from "../../UI/Error/Error";
import { CircularProgress } from "@material-ui/core";
import Empty from "../../UI/Empty/Empty";
import Pagination from "../../UI/Pagination/Pagination";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../Product/Product";
import { parseIsoToDate } from "../../../Utils/parseIsoToDate";
import Search from "../../UI/Search/Search";
import SnackbarContext from "../../../Context/SnackbarContext";
import ReactModal from "react-modal";
import close from "../../../Assets/Images/cross.svg";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";
import DateTimePicker from "react-datetime-picker";
import { addDays, format } from "date-fns";
import { CSVLink } from 'react-csv'
import { useApolloClient } from "@apollo/client";
import Link from "react-csv/components/Link";

const Order: React.FC = () => {
	const [codeSearch, setCodeSearch] = useState<string>("");
	const [downloadOrderModalOpen, setDownloadOrderModalOpen] = useState(false);
	const [limit] = useState(8);
	const query = useQueryParams();
	const [offset, setOffset] = useState(
		query.get("offset") ? parseInt(query?.get("offset") ?? "0") : 0
	);

	const [selectedOrderStatus, setSelectedOrderStatus] = useState<{
		id: number;
		name: string;
	}>({
		id: 6,
		name: 'Paid'
	});

	const { data: orders, error, loading } = useQuery(GetOrders, {
		variables: {
			statusId: selectedOrderStatus.id,
			searchString: `%${codeSearch}%`,
			limit,
			offset,
		},
	});

	const { data: orderStatuses, error: orderStatusError, loading: orderStatusLoading } = useQuery(GetOrderStatus);

	const {
		data: orderCount,
		error: errorCount,
		loading: countLoading,
	} = useQuery(GetOrderCount, {
		variables: {
			searchString: `%${codeSearch}%`,
		},
	});

	if (error || errorCount) {
		return <Error error={error ? error : errorCount} />;
	}

	return (
		<div className="container-main">
			<OrderTrackingEdit
				isOpen={downloadOrderModalOpen}
				setModal={setDownloadOrderModalOpen}
			/>
			<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
				<SearchBox
					placeholder="Search Orders with Email, Phone or Razorpay order id"
					onChangeSearch={(value) => {
						setCodeSearch(value);
					}}
				/>
				<p style={{ marginLeft: '24px', marginTop: '12px', width: '340px' }} className="cursor-pointer button__primary button__primary-active"
					onClick={() => {
						setDownloadOrderModalOpen(true);
					}}
				>
					Download Order Details
				</p>
			</div>
			<div className="coupon__box">
				<div className="coupon__main">
					<div style={{display: 'flex', width: '100%', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'}}>
						<p className="title">Orders</p>
							<Search
								label="Sort by Order Status"
								options={orderStatuses && orderStatuses.order_status}
								value={selectedOrderStatus?.name}
								onChangeSelect={(event: {
									id: number;
									name: string;
								}) => {
									return setSelectedOrderStatus(event);
								}}
							/>
					</div>
					<OrderTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : orders.orders.length > 0 ? (
						orders.orders.map((element: any) => {
							return (
								<OrderItem element={element} key={element.id} />
							);
						})
					) : (
						<Empty />
					)}
				</div>

				{!loading && !countLoading && (
					<Pagination
						limit={limit}
						offset={offset}
						count={orderCount.orders_aggregate.aggregate.count}
						changeOffset={(value) => setOffset(value)}
					/>
				)}
			</div>
		</div>
	);
};

export default Order;

const OrderTitle: React.FC = () => {
	return (
		<div className="user__table" style={{marginTop: '54px'}}>
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Amount</p>
			<p className="season__table-title">Email</p>
			<p className="season__table-title">Razorpay Order</p>
			<p className="season__table-title">Order date</p>
		</div>
	);
};

interface ItemProps {
	element: any;
}

const OrderItem: React.FC<ItemProps> = (props: ItemProps) => {
	const { element } = props;
	const history = useHistory();

	return (
		<div className="user__table">
			<p className="season__table-item">{element.id}</p>
			<p className="season__table-item">{element.totalAmount}</p>
			<p className="season__table-item">{element.user.email}</p>
			<p className="season__table-item">{element.razorPayOrderId}</p>
			<p className="season__table-item">{parseIsoToDate(element.createdAt)}</p>
			<div className="season__actions">
				<button
					className="btn-list"
					onClick={() => {
						history.push(`/dashboard/orders/${element.id}`);
					}}
				>
					view
				</button>
			</div>
		</div>
	);
};

interface DownloadOrderDetails {
	isOpen: boolean;
	setModal: (value: boolean) => void;
}

let headers = [{
	label: 'Order ID',
	key: 'orderId'
}, {
	label: 'Email',
	key: 'email',
}, {
	label: 'Name',
	key: 'name'
}, {
	label: 'Phone',
	key: 'phoneNumber'
}, {
	label: 'Address',
	key: 'address'
}, {
	label: 'Tracking Number',
	key: 'trackingNumber'
}, {
	label: 'Total Amount',
	key: 'totalAmount'
}, {
	label: 'Order Status',
	key: 'orderStatus'
}, {
	label: 'Razorpay Order ID',
	key: 'razorPayOrderId'
}, {
	label: 'Razorpay Payment ID',
	key: 'razorPayPaymentId'
}]

const OrderTrackingEdit: React.FC<DownloadOrderDetails> = ({
	isOpen,
	setModal,
}: DownloadOrderDetails) => {

	const [queryLoading, setQueryLoading] = useState(false);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [headersData, setHeadersData] = useState(headers);
	const [fromDate, setFromDate] = useState((new Date()).toISOString());
	const [toDate, setToDate] = useState(addDays(new Date(), 1).toISOString());
	const [ordersData, setOrdersData] = useState<any[]>([]);
	const csvLink = useRef<any>();
	const client = useApolloClient();

	const onDownload = async () => {
		try {
		setQueryLoading(true);
		const {data: {orders}} = await client.query({
			query: DownloadOrderDetails,
			variables: {
				fromTime: fromDate,
				toTime: toDate
			}
		});

		if (orders.length > 0) {
			const modifiedHeaders = [...headersData]
			const modifiedData: {[key: string]: any}[] = []
			orders.map((order) => {
				const modifiedOrderData = {
					orderId: order.id,
					email: order.user.email,
					name: `${order.user.firstName} ${order.user.lastName}`,
					phoneNumber: order.user.phoneNumber,
					trackingNumber: order.trackingNumber,
					totalAmount: order.totalAmount,
					orderStatus: order.order_status.name,
					razorPayOrderId: order.razorPayOrderId,
					address: `${order.address.name} ${order.address.lineOne} ${order.address.lineTwo} ${order.address.zipcode} ${order.address.town} ${order.address.state} ${order.address.country.name}`
				}

				order.order_product_types.map(({product_type, count}, i) => {
					const index = i + 1
					modifiedOrderData[`product${index}ID`] = product_type.id;
					modifiedOrderData[`product${index}Name`] = product_type.name;
					modifiedOrderData[`product${index}Price`] = product_type.discountedPrice;
					modifiedOrderData[`product${index}Count`] = count;

					const filteredHeader = modifiedHeaders.filter((header) => header.label === `Product ${index} ID`)
					if (filteredHeader.length === 0) {
						modifiedHeaders.push({
							label: `Product ${index} ID`,
							key: `product${index}ID`
						}, {
							label: `Product ${index} name`,
							key: `product${index}Name`
						}, {
							label: `Product ${index} price`,
							key: `product${index}Price`
						},  {
							label: `Product ${index} quantity`,
							key: `product${index}Count`
						});
					}
				})
				modifiedData.push(modifiedOrderData)
			});
			setHeadersData(modifiedHeaders);
			setOrdersData(modifiedData);
			console.log(csvLink);
			csvLink.current?.link?.click();
			console.log(csvLink);
			setMessage("Data downloaded successfully");
		    setSnackbar(true);
			console.log(csvLink);
			setFromDate((new Date()).toISOString())
			setToDate(addDays(new Date(), 1).toISOString());
			setQueryLoading(false);
			setModal(false);
		} else {
			setMessage("No data found for the date range");
		    setSnackbar(true);
			setFromDate((new Date()).toISOString())
			setToDate(addDays(new Date(), 1).toISOString());
			setQueryLoading(false);
			setModal(false);
		}
		} catch(e: any) {
			setMessage(e.message);
		    setSnackbar(true);
			setFromDate((new Date()).toISOString())
			setToDate(addDays(new Date(), 1).toISOString());
			setQueryLoading(false);
			setModal(false);
		}
		// updateOrderTracking({
		// 	variables: {
		// 		orderId: orderId,
		// 		trackingNumber
		// 	},
		// })
		// 	.then(({ data: { update_orders: orders } }: any) => {
		// 		if (orders.affected_rows > 0) {
		// 			setMessage("Tracking number updated Successfully");
		// 			setSnackbar(true);
		// 			refetch();
		// 			setTrackingNumber("");
		// 			setMutationLoading(false);
		// 			return setModal(false);
		// 		} else {
		// 			setMessage("Some Unknown Error Occurred");
		// 			setMutationLoading(false);
		// 			return setSnackbar(true);
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		setMessage(error.message);
		// 		setSnackbar(true);
		// 		setMutationLoading(false);
		// 	});
	};

	return <ReactModal
		className="season__modal Modal__main"
		onRequestClose={() => {
			setModal(false);
		}}
		overlayClassName="Overlay"
		isOpen={isOpen}
	>

		<div className="close">
			<img
				src={close}
				alt="Close"
				className="close__img"
				onClick={() => {
					setFromDate((new Date()).toISOString())
					setToDate(addDays(new Date(), 1).toISOString());
					setQueryLoading(false);
					setModal(false);
				}}
			/>
		</div>

		<div className="add__modal__content">
			<p className="add__modal__header">Download Order Details as CSV</p>
			<div className="margin-auto w-75" style={{marginTop: "4em", display: 'flex'}}>
			<div style={{marginRight: '16px'}}>
				<p>From</p>
				<DateTimePicker
					value={new Date(fromDate)}
					onChange={(date) => {
						setFromDate(date.toISOString())
					}}
				/>
			</div>
			<div style={{marginLeft: '16px'}}>
				<p>To</p>
				<DateTimePicker
					value={new Date(toDate)}
					onChange={(date) => {
						setToDate(date.toISOString())
					}}
				/>
			</div>
			</div>

			{!queryLoading ? (
				<div
					className="w-40 margin-auto"
					style={{ marginTop: "2em" }}
				>
					<PrimaryButton
						label="Download CSV"
						disabled={!fromDate || !toDate}
						loading={queryLoading}
						active
						onClick={() => {
							onDownload();
						}}
					/>
				</div>
			) : (
				<div
					className="spinner-container"
					style={{ marginTop: "3em" }}
				>
					<CircularProgress size={40} thickness={4} />
				</div>
			)}
			<CSVLink
						headers={headersData}
					    filename={`${format(new Date(fromDate), 'dd/mm/yyyy')}-${format(new Date(toDate), 'dd/mm/yyyy')}.csv`}
					    //@ts-ignore
						ref={csvLink}
						target="_blank"
						data={ordersData}
					/>
		</div>

	</ReactModal>
}

import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Category_Types } from "../../../generated/graphql";
import WithLoading from "../../UI/HOC/WithLoading";
import Empty from "../../UI/Empty/Empty";
import CategoryEdit from "../Category/CategoryEdit/CategoryEdit";
import { GetCategoryTypes } from "./CategoryTypeQuery";
import CategoryTypeEdit from "./Components/CategoryTypeEdit";
import CategoryTypeItem from "./Components/CategoryTypeItem";
import CatergoryTypeTitle from "./Components/CatergoryTypeTitle";

const CategoryType = () => {
	const { data: data, error, loading, refetch } = useQuery(GetCategoryTypes);
	const [seasonModal, setSeasonModal] = useState<boolean>(false);
	const [editSeason, setEditSeason] = useState<any>(null);

	const callRefetch = () => {
		refetch();
	};

	const categoryTypes: Category_Types[] = data?.category_types;

	console.log(categoryTypes?.length);

	return (
		<div className="container-main">
			<CategoryTypeEdit
				isOpen={seasonModal}
				setModal={setSeasonModal}
				refetch={callRefetch}
				setEditData={setEditSeason}
				editData={editSeason}
			/>

			<div className="season__box">
				<div className="season__main">
					<div className="season__header">
						<p className="title">Category Types</p>
						<button
							type="button"
							onClick={() => {
								setSeasonModal(true);
							}}
						>
							+ Add Category Type
						</button>
					</div>
					<CatergoryTypeTitle />
					<WithLoading isLoading={loading}>
						{categoryTypes && categoryTypes?.length > 0 ? (
							categoryTypes.map((element: any) => {
								return (
									<CategoryTypeItem
										categoryType={element}
										refetch={callRefetch}
										key={element.id}
										onClickEdit={() => {
											setEditSeason(element);
											setSeasonModal(true);
										}}
									/>
								);
							})
						) : (
							<Empty />
						)}
					</WithLoading>
				</div>
			</div>
		</div>
	);
};

export default CategoryType;

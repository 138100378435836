import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router";
import Login from "./Components/Login/Login";
import SnackbarContext from "./Context/SnackbarContext";
import ProductContext from "./Context/ProductContext";

import Dashboard from "./Components/Dashboard/Dashboard";
import Snackbar from "@material-ui/core/Snackbar";
import firebase from "firebase";

const App: React.FC = () => {
	const [user, setUser] = useState<any>("true");
	const [productId, setProductId] = useState<number | null>(null);

	const [snackbar, setSnackbar] = useState(false);
	const [message, setMessage] = useState("");

	useEffect(() => {
		const sub = firebase.auth().onAuthStateChanged((user) => {
			setUser(user);
		});
		return () => {
			sub();
		};
	}, []);

	return (
		<SnackbarContext.Provider
			value={{
				snackbar,
				setSnackbar,
				message,
				setMessage,
			}}
		>
			<div className="App">
				<Switch>
					<Route
						path="/dashboard"
						render={() => {
							return user ? (
								<ProductContext.Provider
									value={{
										productId,
										setProductId
									}}
								>
									<Dashboard />
								</ProductContext.Provider>
							) : (
								<Redirect to="/login" />
							);
						}}
					/>
					<Route
						path="/login"
						render={() => {
							return user ? (
								<Redirect to="/dashboard" />
							) : (
								<Login />
							);
						}}
					/>
					<Redirect to="/login" />
				</Switch>

				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={snackbar}
					autoHideDuration={5000}
					message={message}
					onClose={() => {
						setSnackbar(false);
					}}
				/>
			</div>
		</SnackbarContext.Provider>
	);
};

export default App;

import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SnackbarContext from "../../../Context/SnackbarContext";
import { imageUpload } from "../../../Utils/imageUpload";
import {
	GetProductTypeDetail,
	InsertProductType,
	UpdateProductType,
} from "./ProductQuery";
import { GetProducts } from "../Deal/DealQuery";
import { GetSeasons } from "../Season/SeasonQuery";
import Input from "../../UI/Input/Input";

import TextArea from "../../UI/Textarea/Textarea";
import Search from "../../UI/Search/Search";
import ImageView from "../../UI/ImageView/ImageView";
import {
	CircularProgress,
	InputLabel,
	MenuItem,
	Select,
	Input as InputM,
	makeStyles,
	Chip,
	useTheme,
	FormControl,
} from "@material-ui/core";
import Error from "../../UI/Error/Error";
import "./Product.scss";
import Checkbox from "../../UI/Checkbox/Checkbox";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

const ProductTypeDetail = () => {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const [mode] = useState<string | null>(query.get("mode"));
	const [productTypeId] = useState<number | null>(
		parseInt(query.get("productTypeId") as string)
	);

	const [name, setName] = useState<string>("");
	const [remark, setRemark] = useState<string>("");
	const [duration, setDuration] = useState<string>("");
	const [SKU, setSKU] = useState<string>("");
	const [originalPrice, setOriginalPrice] = useState<number>(0);
	const [discountedPrice, setDiscountedPrice] = useState<number>(0);

	const [plant, setPlant] = useState<string>("");
	const [exposure, setExposure] = useState<string>("");
	const [height, setHeight] = useState<string>("");
	const [spacing, setSpacing] = useState<string>("");
	const [spread, setSpread] = useState<string>("");
	const [type, setType] = useState<string>("");
	const [commonName, setCommonName] = useState<string>("");
	const [family, setFamily] = useState<string>("");
	const [origin, setOrigin] = useState<string>("");
	const [habit, setHabit] = useState<string>("");
	const [growingTips, setGrowingTips] = useState<string>("");

	const [imageUrl, setImageUrl] = useState<string>("");
	const [recommendedImageUrl, setRecommendedImageUrl] = useState<string>("");
	const [seasonArray, setSeasonArray] = useState<any[]>([]);
	const [product, setProduct] = useState<any>(null);
	const [fileRecommendedImage, setFileRecommendedImage] = useState<any>(null);
	const [fileImage, setFileImage] = useState<any>(null);
	const [isFeatured, setIsFeatured] = useState<boolean>(false);

	const [quantity, setQuantity] = useState<string>("");

	const [notFoundError, setNotFountError] = useState<boolean>(false);
	const [queryLoading, setQueryLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [insertProductType] = useMutation(InsertProductType);
	const [updateProduct] = useMutation(UpdateProductType);

	const { data: products, loading: productLoading } = useQuery(GetProducts);
	const { data: seasons, loading: seasonLoading } = useQuery(GetSeasons);
	const history = useHistory();
	const classes = useStyles();
	const theme = useTheme();
	const client = useApolloClient();

	//[{season: {data: {name: ""}}}]
	useEffect(() => {
		if (mode === "edit" && productTypeId) {
			setQueryLoading(true);
			client
				.query({
					query: GetProductTypeDetail,
					variables: { productTypeId },
				})
				.then(({ data: { product_type } }) => {
					if (product_type.length > 0) {
						const productTypeDetail = product_type[0];
						setImageUrl(productTypeDetail.imageUrl);
						setName(productTypeDetail.name);
						setRemark(productTypeDetail.remark);
						setOriginalPrice(productTypeDetail.originalPrice);
						setDiscountedPrice(productTypeDetail.discountedPrice);
						setIsFeatured(productTypeDetail.isFeatured);
						setDuration(productTypeDetail.duration);
						setQuantity(productTypeDetail.quantity);
						setExposure(productTypeDetail.exposure);
						setSpacing(productTypeDetail.spacing);
						setSpread(productTypeDetail.spread);
						setHeight(productTypeDetail.height);
						setType(productTypeDetail.type);

						setCommonName(productTypeDetail.commonName);
						setFamily(productTypeDetail.family);
						setOrigin(productTypeDetail.origin);
						setHabit(productTypeDetail.habit);
						setGrowingTips(productTypeDetail.growingTips);

						setPlant(productTypeDetail.plant);
						setSeasonArray(
							productTypeDetail.product_seasons.map(
								(element) => element.season.name
							)
						);
						setProduct(productTypeDetail.product);
						setSKU(productTypeDetail.SKU);
						setRecommendedImageUrl(
							productTypeDetail.recommendedCoverImage
						);
						setQueryLoading(false);
					} else {
						setNotFountError(true);
						setQueryLoading(false);
					}
				});
		}
	}, [mode, productTypeId, client]);

	const onSave = async () => {
		setLoading(true);

		let imageUrl = await imageUpload(
			fileImage,
			`/images/ProductTypes/${name}`
		);
		let recommendedImageUrl = await imageUpload(
			fileRecommendedImage,
			`/images/ProductTypes/${name}_cover`
		);

		const seasonIds = seasonArray.map((season) => {
			return seasons.seasons.filter(
				(element) => element.name === season
			)[0].id;
		});

		const productSeasons = seasonIds.map((seasonId) => {
			return {
				seasonId,
			};
		});

		insertProductType({
			variables: {
				name,
				remark,
				duration,
				plant,
				isFeatured,
				spread: spread ? spread.trim() : null,
				exposure: exposure ? exposure.trim() : null,
				spacing: spacing ? spacing.trim() : null,
				height: height ? height.trim() : null,
				type: type ? type.trim() : null,
				SKU,
				discountedPrice,
				quantity,
				originalPrice,
				productSeasons,
				productId: product.id,
				imageUrl,
				recommendedCoverImage: recommendedImageUrl,
				commonName,
				family,
				origin,
				habit,
				growingTips,
			},
		})
			.then(({ data: { insert_product_type: product } }) => {
				setMessage("Product Inserted Inserted Successfully");
				setSnackbar(true);
				setLoading(false);
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setLoading(false);
			});
	};

	const onEdit = async () => {
		//  { "season": { "data": { "name": "spring" } }, "productTypeId": 8}

		const seasonIds = seasonArray.map((season) => {
			return seasons.seasons.filter(
				(element) => element.name === season
			)[0].id;
		});

		const productSeasons = seasonIds.map((seasonId) => {
			return {
				seasonId,
				productTypeId,
			};
		});

		console.log(productSeasons);
		setLoading(true);
		let imageUrlFirebase = imageUrl;
		let recommendedUrlFirebase = recommendedImageUrl;

		if (fileImage) {
			imageUrlFirebase = await imageUpload(
				fileImage,
				`/images/ProductTypes/${name}`
			);
		}
		if (fileRecommendedImage) {
			recommendedUrlFirebase = await imageUpload(
				fileRecommendedImage,
				`/images/ProductTypes/${name}_cover`
			);
		}

		updateProduct({
			variables: {
				productTypeId,
				name,
				remark,
				duration,
				plant,
				isFeatured,
				SKU,
				discountedPrice,
				spread: spread ?? null,
				exposure: exposure ?? null,
				spacing: spacing ?? null,
				height: height ?? null,
				type: type ?? null,

				originalPrice,
				quantity,
				productSeasons,
				productId: product.id,
				recommendedCoverImage: recommendedUrlFirebase,
				imageUrl: imageUrlFirebase,

				commonName,
				family,
				origin,
				habit,
				growingTips,
			},
		})
			.then(({ data }) => {
				setMessage("Product Type Updated  Successfully");
				setSnackbar(true);
				client.reFetchObservableQueries();
				setLoading(false);
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setLoading(false);
			});
	};

	const handleChange = (event) => {
		setSeasonArray(event.target.value);
		console.log(seasonArray);
	};

	if (notFoundError) {
		return (
			<Error
				error={{
					message: "Given Product Type does not exist in database",
				}}
			/>
		);
	} else
		return (
			// <div className="container-main">
			<div className="product-type__main">
				<div className="season__header">
					<p className="title">Product Type Detail</p>

					<div className="w-40 d-flex justify-content-between">
						<div className="" style={{ width: "15rem" }}>
							<Checkbox
								checked={isFeatured}
								setChecked={() => {
									setIsFeatured(!isFeatured);
								}}
								label={"Is Featured Product Type ?"}
							/>
						</div>
						<button
							type="button"
							className="button__primary button__primary-active"
							onClick={() => {
								history.goBack();
							}}
						>
							Back
						</button>
					</div>
				</div>

				{seasonLoading || productLoading || queryLoading ? (
					<div className="spinner-container">
						<CircularProgress size={40} thickness={4} />
					</div>
				) : (
					<>
						<div className="w-100 d-flex justify-content-between align-items-center">
							<div className="w-40">
								<Input
									placeholder={"SKU*"}
									label
									type={"text"}
									disabled={false}
									value={SKU}
									onChange={(event: string) => {
										return setSKU(event);
									}}
								/>
							</div>

							<div className="w-40">
								<Input
									placeholder={"Quantity*"}
									label
									type={"text"}
									disabled={false}
									value={quantity}
									onChange={(event: string) => {
										return setQuantity(event);
									}}
								/>
							</div>
						</div>
						<div className="w-100 d-flex justify-content-between align-items-center">
							<div className="w-40">
								<Input
									placeholder={"Name*"}
									label
									type={"text"}
									disabled={false}
									value={name}
									onChange={(event: string) => {
										return setName(event);
									}}
								/>
							</div>
						</div>
						<hr />
						<div className="w-100 d-flex justify-content-between">
							<div className="w-40">
								<ImageView
									uniqueName={"ImageUrl"}
									imageUrl={imageUrl}
									setImage={setFileImage}
									label={"Product Type Image*"}
								/>
							</div>

							<div className="w-40">
								<ImageView
									uniqueName={"CoverImageUrl"}
									imageUrl={recommendedImageUrl}
									setImage={setFileRecommendedImage}
									label={"Product Type Recommended Image*"}
								/>
							</div>
						</div>
						<hr />
						<div className="w-100 d-flex justify-content-between">
							<div className="w-40">
								<Input
									placeholder={"Original Price"}
									label
									type={"number"}
									disabled={false}
									value={
										originalPrice
											? originalPrice.toString()
											: ""
									}
									onChange={(event: string) => {
										return setOriginalPrice(
											parseInt(event)
										);
									}}
								/>
							</div>

							<div className="w-40">
								<Input
									placeholder={"Discounted Price*"}
									label
									type={"number"}
									disabled={false}
									value={discountedPrice.toString()}
									onChange={(event: string) => {
										return setDiscountedPrice(
											parseInt(event)
										);
									}}
								/>
							</div>
						</div>
						<hr />
						<div className="w-100 d-flex justify-content-between">
							<FormControl
								className={classes.formControl}
								style={{ width: "15rem" }}
							>
								{/* <Search
									label={"Seasons"}
									options={seasons.seasons}
									value={season ? season.name : ""}
									onChangeSelect={(event: any) => {
										console.log(event);
										setSeason(event);
									}}
								/> */}
								<InputLabel
									id="demo-mutiple-chip-label"
									style={{ width: "max-content" }}
								>
									{product && product?.subCategoryId === 2
										? "Blooming Seasons*"
										: "Seasons*"}
								</InputLabel>
								<Select
									labelId="demo-mutiple-chip-label"
									id="demo-mutiple-chip"
									multiple
									value={seasonArray}
									onChange={handleChange}
									input={<InputM id="select-multiple-chip" />}
									renderValue={(selected) => (
										<div className={classes.chips}>
											{seasonArray.map((value) => (
												<Chip
													key={value}
													label={value}
													className={classes.chip}
												/>
											))}
										</div>
									)}
									MenuProps={MenuProps}
								>
									{seasons.seasons
										.map((element) => element.name)
										.map((season) => (
											<MenuItem
												key={season}
												value={season}
												style={getStyles(
													season,
													seasonArray,
													theme
												)}
											>
												{season}
											</MenuItem>
										))}
								</Select>
							</FormControl>

							<div
								className="w-40"
								style={{ marginBottom: "2em" }}
							>
								<Search
									label={"Products*"}
									options={products.product}
									value={product ? product.name : ""}
									onChangeSelect={(event: any) => {
										setProduct(event);
									}}
								/>
							</div>
						</div>
						<hr />
						<div className="w-100 d-flex justify-content-between">
							<div className="w-40">
								<Input
									placeholder={"Plant"}
									label
									type={"text"}
									disabled={false}
									value={plant}
									onChange={(event: string) => {
										return setPlant(event);
									}}
								/>
							</div>
							<div className="w-40">
								<Input
									placeholder={"Type"}
									label
									type={"text"}
									disabled={false}
									value={type}
									onChange={(event: string) => {
										return setType(event);
									}}
								/>
							</div>
						</div>
						{
							//product && product?.subCategoryId === 2 && (<>
						}{" "}
						<div className="w-100 d-flex justify-content-between">
							<div className="w-40">
								<Input
									placeholder={"Spread"}
									label
									type={"test"}
									disabled={false}
									value={spread}
									onChange={(event: string) => {
										return setSpread(event);
									}}
								/>
							</div>
							<div className="w-40">
								<Input
									placeholder={"Exposure"}
									label
									type={"text"}
									disabled={false}
									value={exposure}
									onChange={(event: string) => {
										return setExposure(event);
									}}
								/>
							</div>
						</div>
						<div className="w-100 d-flex justify-content-between">
							<div className="w-40">
								<Input
									placeholder={"Size"}
									label
									type={"text"}
									disabled={false}
									value={height}
									onChange={(event: string) => {
										return setHeight(event);
									}}
								/>
							</div>
							<div className="w-40">
								<Input
									placeholder={"Spacing"}
									label
									type={"text"}
									disabled={false}
									value={spacing}
									onChange={(event: string) => {
										return setSpacing(event);
									}}
								/>
							</div>
						</div>
						<div className="w-100 d-flex justify-content-between">
							<div className="w-40">
								<Input
									placeholder={"Common Name"}
									label
									type={"text"}
									disabled={false}
									value={commonName}
									onChange={(event: string) => {
										return setCommonName(event);
									}}
								/>
							</div>
							<div className="w-40">
								<Input
									placeholder={"Family"}
									label
									type={"text"}
									disabled={false}
									value={family}
									onChange={(event: string) => {
										return setFamily(event);
									}}
								/>
							</div>
						</div>
						<div className="w-100 d-flex justify-content-between">
							<div className="w-40">
								<Input
									placeholder={"Habit"}
									label
									type={"text"}
									disabled={false}
									value={habit}
									onChange={(event: string) => {
										return setHabit(event);
									}}
								/>
							</div>
							<div className="w-40">
								<Input
									placeholder={"Origin"}
									label
									type={"text"}
									disabled={false}
									value={origin}
									onChange={(event: string) => {
										return setOrigin(event);
									}}
								/>
							</div>
						</div>
						{
							//</>)}
						}
						<div className="w-100 d-flex justify-content-between">
							<div className="w-40">
								<TextArea
									placeholder={"Remark"}
									label
									rows={8}
									columns={40}
									disabled={false}
									value={remark}
									onChange={(event: string) => {
										return setRemark(event);
									}}
								/>
							</div>

							<div className="w-40">
								<TextArea
									placeholder={"Duration"}
									label
									rows={8}
									columns={40}
									disabled={false}
									value={duration}
									onChange={(event: string) => {
										return setDuration(event);
									}}
								/>
							</div>
						</div>
						<div className="w-100 d-flex justify-content-between">
							<div className="w-40">
								<TextArea
									placeholder={"Growing Tips"}
									label
									rows={8}
									columns={40}
									disabled={false}
									value={growingTips}
									onChange={(event: string) => {
										return setGrowingTips(event);
									}}
								/>
							</div>
						</div>
						<hr />
					</>
				)}
				<div
					className="w-100 d-flex justify-content-center"
					style={{ marginTop: "2em" }}
				>
					{!loading ? (
						<>
							<button
								onClick={productTypeId ? onEdit : onSave}
								disabled={
									!name.trim() ||
									!discountedPrice ||
									!quantity?.trim() ||
									!SKU.trim() ||
									!product ||
									!(seasonArray.length > 0) ||
									!(fileImage || imageUrl) ||
									!(
										fileRecommendedImage ||
										recommendedImageUrl
									)
								}
								className="banner__button"
							>
								{productTypeId ? "Edit" : "Save"}
							</button>
						</>
					) : (
						<div className="w-100">
							<div className="spinner-container">
								<CircularProgress size={40} thickness={4} />
							</div>
						</div>
					)}
				</div>
			</div>
			//</div>
		);
};

export default ProductTypeDetail;

import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import SnackbarContext from "../../../Context/SnackbarContext";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";
import {
	DeleteInstructionTitle,
	GetInstructionTypes,
	InsertInstructionTitles,
	UpdateInstructionTitles,
} from "./ProductQuery";
import close from "../../../Assets/Images/cross.svg";
import Input from "../../UI/Input/Input";
import "./Product.scss";
import Search from "../../UI/Search/Search";
import { useLocation } from "react-router-dom";
import DeleteModal from "../../UI/DeleteModal/DeleteModal";

interface InstructionTitleEditProps {
	isOpen: boolean;
	editData: any;
	setEditData: (element: any | null) => void;
	setModal: (value: boolean) => void;
	refetch: () => void;
}
const InstructionTitleEdit: React.FC<InstructionTitleEditProps> = ({
	isOpen,
	editData,
	setEditData,
	setModal,
	refetch,
}: InstructionTitleEditProps) => {
	const [name, setName] = useState<string>("");
	const [index, setIndex] = useState<number>(1);
	const [type, setType] = useState<any>(null);

	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateInstructionTitle] = useMutation(UpdateInstructionTitles);
	const [insertInstructionTitle] = useMutation(InsertInstructionTitles);
	const [deleteInstructionTitle] = useMutation(DeleteInstructionTitle);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const [productId] = useState<number | null>(
		parseInt(query.get("productId") as string)
	);
	const { data: instructionTypes, loading } = useQuery(GetInstructionTypes);
	const clearState = () => {
		setName("");
		setIndex(1);
		setType(null);
		setEditData(null);
	};

	useEffect(() => {
		if (editData) {
			setName(editData && editData.name);
			setIndex(editData && editData.index);
			setType(editData && editData.instructions_type);
		}
	}, [editData]);

	const onEdit = () => {
		setMutationLoading(true);
		updateInstructionTitle({
			variables: {
				instructionTitleId: editData.id,
				name,
				index,
				typeId: type.id,
				productId,
			},
		})
			.then(({ data: { update_instruction_titles: title } }: any) => {
				if (title.affected_rows > 0) {
					setMessage("Instruction Title Updated Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	const onSave = () => {
		setMutationLoading(true);
		insertInstructionTitle({
			variables: {
				name,
				index,
				typeId: type.id,
				productId,
			},
		})
			.then(({ data: { insert_instruction_titles: title } }: any) => {
				if (title.affected_rows > 0) {
					setMessage("Instruction Title Inserted Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	const onDelete = () => {
		setDeleteLoading(true);
		deleteInstructionTitle({
			variables: {
				instructionId: editData.id,
			},
		})
			.then(({ data: { delete_instruction_titles: title } }: any) => {
				if (title.affected_rows > 0) {
					setMessage("Instruction Title Deleted Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setDeleteLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setDeleteLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setDeleteLoading(false);
			});
	};

	return (
		<ReactModal
			className="instruction__modal Modal__main"
			onRequestClose={() => {
				clearState();
				setModal(false);
			}}
			overlayClassName="Overlay"
			isOpen={isOpen}
		>
			<DeleteModal
				loading={deleteLoading}
				setModal={setDeleteModal}
				modal={deleteModal}
				onClickDelete={() => {
					onDelete();
					// deleteId && onDelete(deleteId);
				}}
			/>
			<div className="close">
				<img
					src={close}
					alt="Close"
					className="close__img"
					onClick={() => {
						clearState();
						setModal(false);
					}}
				/>
			</div>

			{loading ? (
				<div className="spinner-container" style={{ marginTop: "3em" }}>
					<CircularProgress size={40} thickness={4} />
				</div>
			) : (
				<div className="add__modal__content">
					<p className="add__modal__header">
						{editData
							? "Edit Instruction Title"
							: "Add Instruction Title"}
					</p>
					<div className="margin-auto w-75">
						<Input
							placeholder={"Name"}
							label
							type={"text"}
							disabled={false}
							value={name}
							onChange={(event: string) => {
								return setName(event);
							}}
						/>
						<Input
							placeholder={"Index"}
							label
							type={"number"}
							disabled={false}
							value={index.toString()}
							onChange={(event: string) => {
								return setIndex(parseInt(event));
							}}
						/>
						<Search
							label={"Instruction Type"}
							options={instructionTypes.instructions_types}
							value={type ? type.name : ""}
							onChangeSelect={(event: any) => {
								setType(event);
							}}
						/>
					</div>

					{!mutationLoading ? (
						<>
							<div
								className={`w-40 margin-auto d-flex  ${
									editData
										? "justify-content-between"
										: "justify-content-center"
								}`}
								style={{ marginTop: "2em" }}
							>
								<PrimaryButton
									label="Save"
									disabled={!name || !index || !type}
									loading={mutationLoading}
									active
									onClick={() => {
										editData ? onEdit() : onSave();
									}}
								/>

								<PrimaryButton
									label="Delete"
									disabled={false}
									loading={mutationLoading}
									active
									onClick={() => {
										setDeleteModal(true);
									}}
								/>
							</div>
						</>
					) : (
						<div
							className="spinner-container"
							style={{ marginTop: "3em" }}
						>
							<CircularProgress size={40} thickness={4} />
						</div>
					)}
				</div>
			)}
		</ReactModal>
	);
};

export default InstructionTitleEdit;

import React from "react";
import "./Pagination.scss";
import right from "../../../Assets/Images/rightArrow.svg";
import left from "../../../Assets/Images/leftArrow.svg";
import { useHistory } from "react-router-dom";

interface PaginationProps {
	limit: number;
	offset: number;
	count: number;
	changeOffset: (value: any) => any;
}

export const Pagination: React.FC<PaginationProps> = (
	props: PaginationProps
) => {
	const { limit, offset, changeOffset, count } = props;

	const leftIconStyle = {
		opacity: offset ? 1 : 0.3,
		cursor: offset ? "pointer" : "not-allowed",
	};

	const rightIconStyle = {
		opacity: count > offset + limit ? 1 : 0.3,
		cursor: count > offset + limit ? "pointer" : "not-allowed",
	};

	const history = useHistory();

	return (
		<div className="pagination">
			<p className="pagination__title">
				{offset + 1} &nbsp; -{" "}
				{offset + limit < count ? offset + limit : count}
				&nbsp; of &nbsp;
				{count} &nbsp;
			</p>
			<img
				src={left}
				style={leftIconStyle}
				alt="Last Page"
				className="pagination__icon"
				onClick={() => {
					changeOffset((oldOffset: number) => {
						const newOffset =
							offset > 0 ? oldOffset - limit : oldOffset;
						history.push(`?offset=${newOffset}`);
						return newOffset;
					});
				}}
			/>
			<img
				src={right}
				style={rightIconStyle}
				alt="Next Page"
				onClick={() => {
					changeOffset((oldOffset: number) => {
						const newOffset =
							count > oldOffset + limit
								? oldOffset + limit
								: oldOffset;

						history.push(`?offset=${newOffset}`);

						return newOffset;
					});
				}}
				className="pagination__icon"
			/>
		</div>
	);
};

export default Pagination;
function useRouter() {
	throw new Error("Function not implemented.");
}

import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SnackbarContext from "../../../Context/SnackbarContext";
import Empty from "../../UI/Empty/Empty";
import Error from "../../UI/Error/Error";
import InstructionTitleEdit from "./InstructionTitleEdit";

import "./Product.scss";
import {
	GetInstructionTitlesByProductId,
	ToggleInstructionTitle,
} from "./ProductQuery";

const InstructionTitle: React.FC = () => {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const [productId] = useState<number | null>(
		parseInt(query.get("productId") as string)
	);
	const [productName] = useState<string | null>(query.get("productName"));

	const { data: instructionTitles, error, loading, refetch } = useQuery(
		GetInstructionTitlesByProductId,
		{
			variables: {
				productId: productId ? productId : 0,
			},
		}
	);

	const [editTitle, setEditTitle] = useState<any>(null);
	const [titleModal, setTitleModal] = useState<boolean>(false);
	const history = useHistory();

	if (error) {
		return <Error error={error} />;
	}

	const callRefetch = () => {
		refetch();
	};

	return (
		<div className="container-main">
			<InstructionTitleEdit
				isOpen={titleModal}
				setModal={setTitleModal}
				refetch={callRefetch}
				setEditData={setEditTitle}
				editData={editTitle}
			/>
			<button
				type="button"
				className="button__primary button__primary-active"
				onClick={() => {
					history.goBack();
				}}
			>
				Back
			</button>
			<div className="season__box">
				<div className="season__main">
					<div className="season__header">
						<p className="title">
							Instruction Titles &nbsp;({productName})
						</p>

						<button
							type="button"
							onClick={() => {
								setTitleModal(true);
							}}
						>
							+Add Instruction Title
						</button>
					</div>
					<ProductTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : instructionTitles.instruction_titles.length > 0 ? (
						instructionTitles.instruction_titles.map(
							(element: any) => {
								return (
									<ProductItem
										element={element}
										refetch={callRefetch}
										key={element.id}
										onClickEdit={() => {
											setEditTitle(element);
											setTitleModal(true);
										}}
									/>
								);
							}
						)
					) : (
						<Empty />
					)}
				</div>
			</div>
		</div>
	);
};

export default InstructionTitle;

const ProductTitle: React.FC = () => {
	return (
		<div className="instruction__table">
			<p className="season__table-title">Index</p>
			<p className="season__table-title">Name</p>
			<p className="season__table-title">Type</p>
		</div>
	);
};

interface ProductItemProps {
	element: any;
	onClickEdit: () => void;
	refetch: () => void;
}

const ProductItem: React.FC<ProductItemProps> = (props: ProductItemProps) => {
	const { element, onClickEdit, refetch } = props;
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const [toggleSeason] = useMutation(ToggleInstructionTitle);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const onToggle = () => {
		setLoading(true);
		toggleSeason({
			variables: {
				instructionTitleId: element.id,
				isDeleted: !element.isDeleted,
			},
		})
			.then(({ data }) => {
				setSnackbar(true);
				setMessage("Status updated Successfully");
				setLoading(false);
				refetch();
			})
			.catch((error) => {
				setSnackbar(true);
				setMessage(error.message);
			});
	};

	return (
		<div className="instruction__table">
			<p className="season__table-item">{element.index}</p>
			<p className="season__table-item">{element.name}</p>
			<p className="season__table-item">
				{element.instructions_type.name}
			</p>

			<div className="season__actions">
				<button className="btn-list" onClick={onClickEdit}>
					Edit
				</button>

				<button
					className="btn-list"
					onClick={() => {
						history.push(
							`/dashboard/instructions?titleId=${element.id}&typeId=${element.instructions_type.id}&title=${element.name}`
						);
					}}
				>
					View
				</button>

				{loading ? (
					<div
						style={{ width: "4rem" }}
						className="spinner-container"
					>
						<CircularProgress size={20} thickness={3} />
					</div>
				) : (
					<button
						className="btn-list"
						onClick={() => {
							onToggle();
						}}
					>
						{element.isDeleted ? "Enable" : "Disable"}
					</button>
				)}
			</div>
		</div>
	);
};

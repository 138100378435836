import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import SnackbarContext from "../../../../../Context/SnackbarContext";
import { Home_Page_Rows } from "../../../../../generated/graphql";
import WithLoading from "../../../../UI/HOC/WithLoading";
import { UpdateHomePageRowsVisiblity } from "../HomePageRowsQuery";

const HomePageRowItem = ({
	item,
	onClickEdit,
	refetch,
}: {
	item: Home_Page_Rows;
	onClickEdit: () => void;
	refetch: () => void;
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [toggleVisiblityMutation] = useMutation(UpdateHomePageRowsVisiblity);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const toggleVisiblity = async () => {
		setLoading(true);
		try {
			const {
				data: {
					update_home_page_rows_by_pk: { id },
				},
			} = await toggleVisiblityMutation({
				variables: {
					homePageRowId: item.id,
					isDeleted: !item.isDeleted,
				},
			});
			refetch();
			setMessage("Sub Category updated Successfully");
		} catch (error: any) {
			setMessage(error.message);
		}
		setSnackbar(true);
		setLoading(false);
	};

	return (
		<div className="home-page-rows__table">
			<p className="home-page-rows__table-item">{item.priority}</p>
			<p className="home-page-rows__table-item">{item.title}</p>
			<p className="home-page-rows__table-item">{item.homePageRowType}</p>
			<div className="home-page-rows__table-item-actions">
				<button className="btn-list" onClick={onClickEdit}>
					EDIT
				</button>
				{item.homePageRowType.toLocaleLowerCase() !== "header" ? (
					<WithLoading isLoading={loading} size={20} thickness={3}>
						<button className="btn-list" onClick={toggleVisiblity}>
							{item.isDeleted ? "ENABLE" : "DISABLE"}
						</button>
					</WithLoading>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default HomePageRowItem;

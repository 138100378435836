import { gql } from "@apollo/client";

export const GetBannerTypes = gql`
	query GetBanner_types {
		banner_type(order_by: {id: asc}) {
			id
			name
			type
		}
	}

`;

export const GetBannerDetails = gql`
	query GetBanner_types($typeId: Int!) {
		banner_type(order_by: {id: asc}, where: {type: {_eq: $typeId}}) {
			id
			name
			banners(order_by: {id:asc }) {
				id
				cost
				description
				heading
				title
				image
				isDeleted
				bannerProducts {
					product {
						name
						id
					}
					sub_category {
						id
						name
					}
					product_type {
						id
						name
					}
					
				}
			}
		}
	}
`;


export const UpdateBannerTypeOne = gql`
	mutation UpdateBannerTypeOne($bannerId: Int!, $image: String!, $isDeleted: Boolean!, $title: String!, $heading: String, $cost: Int,  $productId: bigint,$productTypeId: Int, $subCategoryId: Int, $description: String ) {
		update_banners(where: {id: {_eq: $bannerId}}, _set: {image: $image, isDeleted: $isDeleted, title: $title, cost: $cost, heading: $heading, description: $description}) {
			affected_rows
		}
		update_banner_product(where: {bannerId: {_eq: $bannerId}}, _set:{ productId: $productId, productTypeId:  $productTypeId, subCategoryId: $subCategoryId}) {
			affected_rows
		}
	}
`;
import { gql } from "@apollo/client";

export const GetSubCategories = gql`
	query GetSubCategories {
		sub_categories(order_by: {id: asc}) {
			id
			name
			coverImageUrl
			portraitImageUrl
			landscapeImageUrl
			isDeleted
			categoriesSubCategories{
				category{
				  id
				  name
				}
			}
		}
	}
`;


export const UpdateSubCategoriesById = gql`
mutation UpdateSubCategoriesById($name: String!, $subCategoryId: Int!, $coverImageUrl: String!, $portraitImageUrl: String!, $landscapeImageUrl: String!, $categoriesSubCategoriesObject: [categories_sub_categories_insert_input!]!) {
	update_sub_categories(where: {id: {_eq: $subCategoryId}}, _set: {name: $name, coverImageUrl: $coverImageUrl, portraitImageUrl: $portraitImageUrl, landscapeImageUrl: $landscapeImageUrl}) {
		affected_rows
	  }
	insert_categories_sub_categories(objects: $categoriesSubCategoriesObject, on_conflict: {constraint: categories_sub_categories_categoryId_subCategoryId_key, update_columns: []}) {
	  affected_rows
	}
  }
`;

export const CreateSubCategory = gql`
mutation CreateSubCategory($name: String!, $coverImageUrl: String!, $portraitImageUrl: String!, $landscapeImageUrl: String!) {
	insert_sub_categories_one(object: {name: $name, coverImageUrl: $coverImageUrl, portraitImageUrl: $portraitImageUrl, landscapeImageUrl: $landscapeImageUrl}) {
	  id
	}
  }
`;

export const ToggleSubCategory = gql`
	mutation ToggleSubCategory($isDeleted: Boolean!, $subCategoryId: Int!) {
		update_sub_categories(where: { id: { _eq: $subCategoryId } }, _set: { isDeleted: $isDeleted }) {
			affected_rows
		}
	}
`;

export const GetCategoriesBySubCategoryId = gql`
query GetCategoriesBySubCategoryId($subCategoryId: Int!) {
	categories(where: {_and: [{categories_sub_categories: {subCategoryId: {_eq: $subCategoryId}}}, {isDeleted: {_eq: false}}]}) {
	  id
	  name
	}
  }
`;

export const DeleteCategoriesSubCategories = gql`
mutation DeleteCategoriesSubCategories($_or: [categories_sub_categories_bool_exp]!) {
	delete_categories_sub_categories(where: {_or: $_or}) {
	  affected_rows
	}
  }`;


export const InsertCategoriesSubCategories = gql`
  mutation InsertCategoriesSubCategories($objects: [categories_sub_categories_insert_input!]!) {
	insert_categories_sub_categories(objects: $objects, on_conflict: {constraint: categories_sub_categories_categoryId_subCategoryId_key, update_columns: []}) {
	  affected_rows
	}
  }`;
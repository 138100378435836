import { gql } from "@apollo/client";

export const GetCategoryTypes = gql`
query getCategoryTypes {
    category_types(order_by:{id:asc}) {
      id
      name
      isDeleted
    }
  }
`;

export const EnableDisableCategoryType = gql`
mutation EnableDisableCategoryType($isDeleted: Boolean!, $categoryTypeId: bigint!) {
    update_category_types(where: {id: {_eq: $categoryTypeId}}, _set: {isDeleted: $isDeleted}) {
        affected_rows
    }
}
`;

export const UpdateCategoryTypeById = gql`
mutation UpdateCategoryTypeById($name: String!, $categoryTypeId: bigint!) {
    update_category_types(where: {id: {_eq: $categoryTypeId}}, _set: {name: $name}) {
      affected_rows
    }
  }
`;

export const CreateCategoryType = gql`
mutation CreateCategoryType($name: String!) {
    insert_category_types(objects: {name: $name}) {
        affected_rows
    }
}
`;

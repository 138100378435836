import { useMutation, useQuery } from "@apollo/client";
import { Chip, CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import SnackbarContext from "../../../../Context/SnackbarContext";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import {
	CreateSubCategory,
	DeleteCategoriesSubCategories,
	InsertCategoriesSubCategories,
	UpdateSubCategoriesById,
} from "../SubCategoryQuery";
import close from "../../../../Assets/Images/cross.svg";
import Input from "../../../UI/Input/Input";
import "../SubCategory.scss";
import { GetCategories } from "../../Category/CategoryQuery";
import Search from "../../../UI/Search/Search";
import ImageView from "../../../UI/ImageView/ImageView";
import { imageUpload } from "../../../../Utils/imageUpload";
import {
	Categories,
	Categories_Sub_Categories,
} from "../../../../generated/graphql";

interface CategoryEditProps {
	isOpen: boolean;
	editData: any;
	setEditData: (element: any | null) => void;
	setModal: (value: boolean) => void;
	refetch: () => void;
}
const SubCategoryEdit: React.FC<CategoryEditProps> = ({
	isOpen,
	editData,
	setEditData,
	setModal,
	refetch,
}: CategoryEditProps) => {
	const [name, setName] = useState<string>("");
	const [categoryList, setCategoryList] = useState<any>([]);

	const [coverImage, setCoverImage] = useState<any>("");
	const [coverFile, setCoverFile] = useState<any>(null);

	const [portraitImage, setPortraitImage] = useState<any>("");
	const [portraitFile, setPortraitFile] = useState<any>(null);

	const [landscapeImage, setLandscapeImage] = useState<any>("");
	const [landscapeFile, setLandscapeFile] = useState<any>(null);

	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateSubCategory] = useMutation(UpdateSubCategoriesById);
	const [createSubCategory] = useMutation(CreateSubCategory);
	const [deleteCategorySubCategory] = useMutation(
		DeleteCategoriesSubCategories
	);

	const [insertCategoriesSubCategories] = useMutation(
		InsertCategoriesSubCategories
	);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const { data: categories, loading } = useQuery(GetCategories);

	useEffect(() => {
		if (editData) {
			setName(editData && editData.name);
			setCategoryList(
				editData &&
					editData.categoriesSubCategories.map((e) => e.category)
			);
			setCoverImage(editData && editData.coverImageUrl);
			setPortraitImage(editData && editData.portraitImageUrl);
			setLandscapeImage(editData && editData.landscapeImageUrl);
		}
	}, [editData]);

	const clearState = () => {
		setName("");
		setCategoryList([]);
		setEditData(null);
	};

	const handleCategoryDelete = (category) => {
		setCategoryList((values) => {
			return values.filter((val) => {
				return val.id != category.id;
			});
		});
	};

	const onEdit = async () => {
		setMutationLoading(true);

		let coverImageUrl = coverImage;
		if (coverFile) {
			coverImageUrl = await imageUpload(
				coverFile,
				`/images/Sub_Categories_Cover_Images/${name}`
			);
		}
		let portraitImageUrl = portraitImage;
		if (portraitFile) {
			portraitImageUrl = await imageUpload(
				portraitFile,
				`/images/Sub_Categories_Portrait_Images/${name}`
			);
		}

		let landscapeImageUrl = landscapeImage;
		if (landscapeFile) {
			landscapeImageUrl = await imageUpload(
				landscapeFile,
				`/images/Sub_Categories_Landscape_Images/${name}`
			);
		}

		try {
			// Delete Category_SubCategory
			const {
				data: {
					delete_categories_sub_categories: { affected_rows },
				},
			} = await deleteCategorySubCategory({
				variables: {
					_or: generateOrExp(
						filterCategoriesToDelete({
							newCategories: categoryList as Array<Categories>,
							currentCategories: getCurrentCategories(),
						})
					),
				},
			});
		} catch (error: any) {
			setMessage(error.message);
			setSnackbar(true);
			setMutationLoading(false);
			return;
		}

		const categoriesSubCategoriesObject = categoryList.map((e) => {
			return { categoryId: e.id, subCategoryId: editData.id };
		});

		updateSubCategory({
			variables: {
				subCategoryId: editData.id,
				name,
				coverImageUrl,
				portraitImageUrl,
				landscapeImageUrl,
				categoriesSubCategoriesObject,
			},
		})
			.then(({ data: { update_sub_categories: subCategory } }: any) => {
				if (subCategory.affected_rows > 0) {
					setMessage("Sub-Category Updated Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	const onSave = async () => {
		setMutationLoading(true);
		let coverImageUrl = await imageUpload(
			coverFile,
			`/images/Sub_Categories_Cover_Images/${name}`
		);
		let portraitImageUrl = await imageUpload(
			portraitFile,
			`/images/Sub_Categories_Portrait_Images/${name}`
		);
		let landscapeImageUrl = await imageUpload(
			landscapeFile,
			`/images/Sub_Categories_Landscape_Images/${name}`
		);
		try {
			const {
				data: {
					insert_sub_categories_one: { id },
				},
			} = await createSubCategory({
				variables: {
					name: name,
					coverImageUrl,
					portraitImageUrl,
					landscapeImageUrl,
				},
			});
			console.log(id);

			const categoriesSubCategoriesObject = categoryList.map((e) => {
				return { categoryId: e.id, subCategoryId: id };
			});

			const {
				data: {
					insert_categories_sub_categories: { affected_rows },
				},
			} = await insertCategoriesSubCategories({
				variables: { objects: categoriesSubCategoriesObject },
			});

			setMessage("Sub-Category Inserted Successfully");
			setSnackbar(true);
			refetch();
			clearState();
			setMutationLoading(false);
			return setModal(false);
		} catch (error) {
			console.log(error);
			setMessage("Some Unknown Error Occurred");
			setMutationLoading(false);
			return setSnackbar(true);
		}
	};

	const getCurrentCategories =
		(): Array<Categories> => /*: Promise<[Categories]>*/ {
			// TODO: Fetch current categorires connected to the subcategories
			// For now, returning the previously fetched data

			if (!editData) return [];
			return editData.categoriesSubCategories.map(
				(e: Categories_Sub_Categories) => {
					return e.category as Categories;
				}
			);
		};

	const filterCategoriesToDelete = ({
		currentCategories,
		newCategories,
	}: {
		currentCategories: Array<Categories>;
		newCategories: Array<Categories>;
	}): Array<Categories> => {
		return currentCategories.filter((c) => {
			const found = newCategories.find((n) => c.id === n.id);
			return !found;
		});
	};

	const generateOrExp = (
		categoriesToDelete: Array<Categories>
	): Array<any> => {
		if (!editData) return [];
		const subCategoryId = editData.id;
		return categoriesToDelete.map((category: Categories) => {
			return {
				_and: [
					{ categoryId: { _eq: category.id } },
					{ subCategoryId: { _eq: subCategoryId } },
				],
			};
		});
	};

	const disableButton =
		!name ||
		!categoryList ||
		!(coverFile || (coverImage && coverImage.length > 0)) ||
		!(landscapeFile || (landscapeImage && landscapeImage.length > 0)) ||
		!(portraitFile || (portraitImage && portraitImage.length > 0));

	return (
		<ReactModal
			className="subCategory__modal  Modal__main"
			onRequestClose={() => {
				clearState();
				setModal(false);
			}}
			overlayClassName="Overlay"
			isOpen={isOpen}
		>
			<div className="close">
				<img
					src={close}
					alt="Close"
					className="close__img"
					onClick={() => {
						clearState();
						setModal(false);
					}}
				/>
			</div>

			{loading ? (
				<div className="spinner-container" style={{ marginTop: "3em" }}>
					<CircularProgress size={40} thickness={4} />
				</div>
			) : (
				<div className="add__modal__content">
					<p className="add__modal__header">
						{editData ? "Edit Sub-Category" : "Add Sub-Category"}
					</p>
					<div className="d-flex justify-content-between-start">
						<div className="d-flex-column w-40">
							<Input
								placeholder={"Name"}
								label
								type={"text"}
								disabled={false}
								value={name}
								onChange={(event: string) => {
									return setName(event);
								}}
							/>
							<Search
								label="Category"
								options={categories.categories}
								value={""}
								//value={category && category.name}
								onChangeSelect={(event: {
									id: number;
									name: string;
								}) => {
									if (!event || !event.id) return;
									setCategoryList((values) => {
										const duplicateValue = values?.filter(
											(val) => val.id === event.id
										);
										return !duplicateValue ||
											duplicateValue.length === 0
											? values
												? [...values, event]
												: [event]
											: values;
									});
								}}
							/>
							{categoryList && categoryList.length > 0 && (
								<div className="subCategory__modal__chip-container">
									{categoryList.map((chipData) => {
										return (
											<div style={{ margin: "2px 5px" }}>
												<Chip
													label={chipData.name}
													key={chipData.id}
													onDelete={() =>
														handleCategoryDelete(
															chipData
														)
													}
												></Chip>
											</div>
										);
									})}
								</div>
							)}
							<ImageView
								uniqueName={"coverImageUrl*"}
								imageUrl={editData && editData.coverImageUrl}
								setImage={setCoverFile}
								label={"Cover Image"}
							/>
						</div>
						<div className="d-flex-column w-40">
							<ImageView
								uniqueName={"portraitImageUrl*"}
								imageUrl={editData && editData.portraitImageUrl}
								setImage={setPortraitFile}
								label={"Portrait Image"}
							/>
							<ImageView
								uniqueName={"landscapeImageUrl*"}
								imageUrl={
									editData && editData.landscapeImageUrl
								}
								setImage={setLandscapeFile}
								label={"Landscape Image"}
							/>
						</div>
					</div>

					{!mutationLoading ? (
						<div
							className="w-40 margin-auto"
							style={{ marginTop: "2em" }}
						>
							<PrimaryButton
								label="Save"
								disabled={disableButton}
								loading={mutationLoading}
								active
								onClick={() => {
									editData ? onEdit() : onSave();
								}}
							/>
						</div>
					) : (
						<div
							className="spinner-container"
							style={{ marginTop: "3em" }}
						>
							<CircularProgress size={40} thickness={4} />
						</div>
					)}
				</div>
			)}
		</ReactModal>
	);
};

export default SubCategoryEdit;

import React from "react";
import "./SearchBox.scss";
import search from "../../../Assets/Images/search.svg";

interface SearchBoxProps {
    placeholder: string;
    type?: string;
    onChangeSearch: (value: string) => void;
}

const SearchBox: React.FC<SearchBoxProps> = (props: SearchBoxProps) => {
    let timeout: NodeJS.Timeout;

    const onChangeSearch = (value: string) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            props.onChangeSearch(value);
        }, 300);
    };

    return (
        <div className="searchBox">
            <div className="searchBox__icon">
                <img src={search} style={{ width: "1.5rem" }} alt="search" />
            </div>

            <input
                className="searchBox__search"
                type={props.type ? props.type : "search"}
                name={props.placeholder}
                aria-label={props.placeholder}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeSearch(event.target.value);
                }}
                placeholder={props.placeholder}
            />
        </div>
    );
};

export default SearchBox;

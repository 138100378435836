import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useState } from "react";
import SnackbarContext from "../../../Context/SnackbarContext";
import Empty from "../../UI/Empty/Empty";
import Error from "../../UI/Error/Error";

import "./Testimonials.scss";
import TestimonialEdit from "./TestimonialEdit";
import { GetTestimonials, DeleteTestimonial } from "./TestimonialQuery";

const Season: React.FC = () => {
	const { data: testimonials, error, loading, refetch } = useQuery(
		GetTestimonials
	);
	const [editSeason, setEditSeason] = useState<any>(null);
	const [seasonModal, setSeasonModal] = useState<boolean>(false);

	if (error) {
		return <Error error={error} />;
	}

	const callRefetch = () => {
		refetch();
	};

	return (
		<div className="container-main">
			<TestimonialEdit
				isOpen={seasonModal}
				setModal={setSeasonModal}
				refetch={callRefetch}
				setEditData={setEditSeason}
				editData={editSeason}
			/>

			<div className="season__box">
				<div className="season__main">
					<div className="season__header">
						<p className="title">Testimonial</p>
						<button
							type="button"
							onClick={() => {
								setEditSeason(null);
								setSeasonModal(true);
							}}
						>
							+Add Testimonial
						</button>
					</div>
					<SeasonTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : testimonials.testimonials.length > 0 ? (
						testimonials.testimonials.map((element: any) => {
							return (
								<SeasonItem
									element={element}
									refetch={callRefetch}
									key={element.id}
									onClickEdit={() => {
										setEditSeason(element);
										setSeasonModal(true);
									}}
								/>
							);
						})
					) : (
						<Empty />
					)}
				</div>
			</div>
		</div>
	);
};

export default Season;

const SeasonTitle: React.FC = () => {
	return (
		<div className="coupon__table">
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Author</p>
			<p className="season__table-title">Designation</p>
		</div>
	);
};

interface SeasonItemProps {
	element: any;
	onClickEdit: () => void;
	refetch: () => void;
}

const SeasonItem: React.FC<SeasonItemProps> = (props: SeasonItemProps) => {
	const { element, onClickEdit, refetch } = props;
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const [deleteTestimonial] = useMutation(DeleteTestimonial);
	const [loading, setLoading] = useState(false);

	const onToggle = () => {
		setLoading(true);
		deleteTestimonial({
			variables: {
				testimonialId: element.id,
			},
		})
			.then(({ data }) => {
				setSnackbar(true);
				setMessage("Testimonial Deleted updated Successfully");
				setLoading(false);
				refetch();
			})
			.catch((error) => {
				setSnackbar(true);
				setMessage(error.message);
			});
	};

	return (
		<div className="coupon__table">
			<p className="season__table-item">{element.id}</p>
			<p className="season__table-item">{element.author}</p>
			<p className="season__table-item">{element.designation}</p>

			<div className="season__actions">
				<button className="btn-list" onClick={onClickEdit}>
					Edit
				</button>

				{loading ? (
					<div
						style={{ width: "4rem" }}
						className="spinner-container"
					>
						<CircularProgress size={20} thickness={3} />
					</div>
				) : (
					<button
						className="btn-list"
						onClick={() => {
							onToggle();
						}}
					>
						Delete
					</button>
				)}
			</div>
		</div>
	);
};

import React from "react";
import "./Input.scss";

interface InputProps {
    placeholder: string;
    onChange: (value: string) => void;
    value: string;
    type: string;
    label: boolean;
    disabled: boolean;
}

const Input: React.FC<InputProps> = ({
    placeholder,
    onChange,
    value,
    type,
    label,
    disabled,
}) => {
    return (
        <div>
            {label && <p className="label">{placeholder}</p>}
            <input
                disabled={disabled}
                className="Input"
                type={type}
                aria-label={placeholder}
                placeholder={placeholder}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                }}
                value={value}
            />
        </div>
    );
};

export default Input;

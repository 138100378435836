import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SnackbarContext from "../../../../Context/SnackbarContext";
import { GetOrderByOrderId, GetOrderStatus, UpdateOrder, UpdateOrderTrackingNumber } from "../OrderQuery";
import Error from "../../../UI/Error/Error";
import { CircularProgress } from "@material-ui/core";
import Empty from "../../../UI/Empty/Empty";
import ReactModal from "react-modal";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import close from "../../../../Assets/Images/cross.svg";
import Search from "../../../UI/Search/Search";
import { parseIsoToDate } from "../../../../Utils/parseIsoToDate";
import Input from "../../../UI/Input/Input";

const UserDetail: React.FC = () => {
	const { id: orderId } = useParams<{ id: string }>();

	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [orderStatus, setOrderStatus] = useState<boolean>(false);
	const [orderStatusEdit, setEditOrderStatus] = useState<any>(null);
	const [trackingNumberEdit, setTrackingNumberEdit] = useState<boolean>(false);
	const history = useHistory();

	const { data: orders, error, loading, refetch } = useQuery(
		GetOrderByOrderId,
		{
			variables: { orderId },
		}
	);

	if (error) {
		return <Error error={error} />;
	}
	let detailsObject: any = {};
	let addresses: any = {};
	if (!loading && orders.orders_by_pk) {
		const user = orders.orders_by_pk.user;

		addresses = orders.orders_by_pk.address;
		detailsObject = {
			"First Name": user.firstName,
			"Last Name": user.lastName,
			Email: user.email,
			Phone: user.phoneNumber,
			"Razorpay Order Id": orders.orders_by_pk.razorPayOrderId,
			Amount: orders.orders_by_pk.totalAmount,
			// "Shipping Fee": orders.orders_by_pk.shippingFee,
			"Order Status": orders.orders_by_pk.order_status.name,
			"Order Date": parseIsoToDate(orders.orders_by_pk.createdAt),
			"Tracking Number": orders.orders_by_pk.trackingNumber ?? "Not updated"
		};
		detailsObject = Object.entries(detailsObject);
	} else if (!loading && !orders.users_by_pk) {
		setMessage("Order either does not exist or is deleted by admin");
		setSnackbar(true);
		return (
			<div className="container-main">
				<div className="user__box">
					<Empty />
				</div>
			</div>
		);
	}

	const callRefetch = () => {
		refetch();
	};

	return (
		<div className="container-main">
			{/* <div className="banner__container"> */}
			<OrderStatusEdit
				isOpen={orderStatus}
				setModal={setOrderStatus}
				refetch={callRefetch}
				setEditData={setEditOrderStatus}
				editData={orderStatusEdit}
			/>

			<OrderTrackingEdit
				isOpen={trackingNumberEdit}
				setModal={setTrackingNumberEdit}
				refetch={callRefetch}
				orderId={orders?.orders_by_pk.id}
				prevTrackingNumber={orders?.orders_by_pk.trackingNumber}
			/>

			<button
				type="button"
				className="button__primary button__primary-active"
				onClick={() => {
					history.goBack();
				}}
			>
				Back
			</button>

			<div className="user__box">
				{loading ? (
					<div className="spinner-container">
						<CircularProgress size={40} thickness={4} />
					</div>
				) : (
					<div className="user__main">
						{loading ? (
							<div className="spinner-container">
								<CircularProgress size={40} thickness={4} />
							</div>
						) : (
							<>
								<div className="d-flex justify-content-between w-100">
									<p className="user__detail-id">
										<span className="user__detail-title">
											Order ID
										</span>
										&nbsp;: &nbsp;&nbsp;{orderId}
									</p>
								</div>
								<div className="user__container">
									{detailsObject.map(
										(element: any, index: number) => {
											return (
												<OrderItem
													key={index}
													name={element[0]}
													value={element[1]}
												/>
											);
										}
									)}
								</div>
								<p style={{ width: '240px', marginTop: '16px', marginBottom: '24px' }}
									className="cursor-pointer button__primary button__primary-active"
									onClick={() => {
										setEditOrderStatus({
											id: orderId,
											order_status: orders.orders_by_pk.order_status
										});
										setOrderStatus(true);
									}}
								>
									Update Order Status
								</p>

								<p style={{ width: '270px', marginTop: '16px', marginBottom: '24px' }}
									className="cursor-pointer button__primary button__primary-active"
									onClick={() => {
										setTrackingNumberEdit(true);
									}}
								>
									Update Tracking Number
								</p>

								<div className="d-flex justify-content-between w-100">
									<p className="user__detail-title">
										Address
									</p>
								</div>
								<div className="user__container">
									<UserAddress element={addresses} />
								</div>
								<p className="user__detail-title">
									Order Products
								</p>
								<div className="order__products">
									{orders.orders_by_pk.order_product_types.map(
										(element: any) => (
											<OrderProduct
												element={element}
											/>
										)
									)}
								</div>
							</>
						)}
					</div>
				)}
			</div>
		</div>
		// </div>

	);
};

export default UserDetail;

interface UserItemProps {
	name: string;
	value: string;
}

const OrderItem: React.FC<UserItemProps> = (props: UserItemProps) => {
	const { name, value } = props;

	return (
		<p className="user__detail-value">
			<span className="user__detail-label">{name}:</span> {value}
		</p>
	);
};

interface UserAddressProps {
	element: any;
}

const UserAddress: React.FC<UserAddressProps> = (props: UserAddressProps) => {
	const { element } = props;

	return (
		<div>
			<div className="d-flex justify-content-between w-100">
				<p className="user__detail-value">
					<span className="user__detail-label">Name:</span>{" "}
					{element.name}
				</p>
			</div>

			<p className="user__detail-value">
				<span className="user__detail-label">Line 1:</span>
				{"  "}
				{element.lineOne}
			</p>
			<p className="user__detail-value">
				<span className="user__detail-label">Line 2: </span>
				{"  "}
				{element.lineTwo}
			</p>

			<p className="user__detail-value">
				<span className="user__detail-label">State:</span>
				{"  "}
				{element.state}
			</p>
			<p className="user__detail-value">
				<span className="user__detail-label">Town: </span>{" "}
				{element.town}
			</p>
			<p className="user__detail-value">
				<span className="user__detail-label">Zipcode:</span>
				{"  "}
				{element.zipcode}
			</p>
			<p className="user__detail-value">
				<span className="user__detail-label">Country:</span>
				{"  "}
				{element.country.name}
			</p>
		</div>
	);
};

interface OrderProductProps {
	element: any;
}

const OrderProduct: React.FC<OrderProductProps> = (props: OrderProductProps) => {
	const { element } = props;

	return (
		<div className="order__product">
			<p className="user__detail-value">
				<span className="user__detail-label">Name:</span>
				{"  "}
				{element.product_type.name}
			</p>

			<p className="user__detail-value">
				<span className="user__detail-label">Cost:</span>
				{"  "}
				{element.product_type.discountedPrice}
			</p>

			<p className="user__detail-value">
				<span className="user__detail-label">Quantity:</span>
				{"  "}
				{element.count}
			</p>
		</div>
	);
};

interface OrderEditProps {
	isOpen: boolean;
	editData: any;
	setEditData: (element: any | null) => void;
	setModal: (value: boolean) => void;
	refetch: () => void;
}
const OrderStatusEdit: React.FC<OrderEditProps> = ({
	isOpen,
	editData,
	setEditData,
	setModal,
	refetch,
}: OrderEditProps) => {
	const [orderStatus, setOrderStatus] = useState<{
		id: number;
		name: string;
	} | null>(editData?.order_status);
	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateOrderStatus] = useMutation(UpdateOrder);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const clearState = () => {
		setOrderStatus(null);
		setEditData(null);
	};

	const onEdit = () => {
		setMutationLoading(true);
		updateOrderStatus({
			variables: {
				orderId: editData.id,
				orderStatusId: orderStatus?.id,
			},
		})
			.then(({ data: { update_orders: orders } }: any) => {
				if (orders.affected_rows > 0) {
					setMessage("Order status Updated Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	const { data: orderStatuses, error, loading } = useQuery(GetOrderStatus);

	useEffect(() => {
		if (editData) {
			setOrderStatus(editData && editData.order_status);
		}
	}, [editData]);

	if (error) {
		return <Error error={error} />;
	}

	return (
		<ReactModal
			className="season__modal Modal__main"
			onRequestClose={() => {
				clearState();
				setModal(false);
			}}
			overlayClassName="Overlay"
			isOpen={isOpen}
		>
			<div className="close">
				<img
					src={close}
					alt="Close"
					className="close__img"
					onClick={() => {
						clearState();
						setModal(false);
					}}
				/>
			</div>

			{loading ? (
				<div className="spinner-container" style={{ marginTop: "3em" }}>
					<CircularProgress size={40} thickness={4} />
				</div>
			) : (
				<div className="add__modal__content">
					<p className="add__modal__header">Edit Order Status</p>
					<div className="margin-auto w-75">
						<Search
							label="Order Status"
							options={orderStatuses.order_status}
							value={orderStatus ? orderStatus?.name : ""}
							onChangeSelect={(event: {
								id: number;
								name: string;
							}) => {
								return setOrderStatus(event);
							}}
						/>
					</div>

					{!mutationLoading ? (
						<div
							className="w-40 margin-auto"
							style={{ marginTop: "2em" }}
						>
							<PrimaryButton
								label="Save"
								disabled={!orderStatus}
								loading={mutationLoading}
								active
								onClick={() => {
									onEdit();
								}}
							/>
						</div>
					) : (
						<div
							className="spinner-container"
							style={{ marginTop: "3em" }}
						>
							<CircularProgress size={40} thickness={4} />
						</div>
					)}
				</div>
			)}
		</ReactModal>
	);
};

interface OrderTrackingProps {
	isOpen: boolean;
	orderId: number;
	refetch: () => void;
	setModal: (value: boolean) => void;
	prevTrackingNumber: string | null | undefined;
}

const OrderTrackingEdit: React.FC<OrderTrackingProps> = ({
	isOpen,
	refetch,
	setModal,
	orderId,
	prevTrackingNumber
}: OrderTrackingProps) => {

	const [mutationLoading, setMutationLoading] = useState(false);
	const [trackingNumber, setTrackingNumber] = useState<string>(prevTrackingNumber ?? "");
	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [updateOrderTracking] = useMutation(UpdateOrderTrackingNumber);

	const onEdit = () => {
		setMutationLoading(true);
		updateOrderTracking({
			variables: {
				orderId: orderId,
				trackingNumber
			},
		})
			.then(({ data: { update_orders: orders } }: any) => {
				if (orders.affected_rows > 0) {
					setMessage("Tracking number updated Successfully");
					setSnackbar(true);
					refetch();
					setTrackingNumber("");
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	return <ReactModal
		className="season__modal Modal__main"
		onRequestClose={() => {
			setModal(false);
		}}
		overlayClassName="Overlay"
		isOpen={isOpen}
	>

		<div className="close">
			<img
				src={close}
				alt="Close"
				className="close__img"
				onClick={() => {

					setModal(false);
				}}
			/>
		</div>

		<div className="add__modal__content">
			<p className="add__modal__header">Update Tracking Number</p>
			<div className="margin-auto w-75" style={{marginTop: "2em"}}>
				<Input
					placeholder={"Tracking number*"}
					label={false}
					type={"text"}
					disabled={false}
					value={trackingNumber}
					onChange={(event: string) => {
						return setTrackingNumber(event);
					}}
				/>
			</div>

			{!mutationLoading ? (
				<div
					className="w-40 margin-auto"
					style={{ marginTop: "2em" }}
				>
					<PrimaryButton
						label="Save"
						disabled={!trackingNumber}
						loading={mutationLoading}
						active
						onClick={() => {
							onEdit();
						}}
					/>
				</div>
			) : (
				<div
					className="spinner-container"
					style={{ marginTop: "3em" }}
				>
					<CircularProgress size={40} thickness={4} />
				</div>
			)}
		</div>

	</ReactModal>
}

import { gql } from "@apollo/client";

export const GetHomePageRowsById = gql`
query GetHomePageRowsById($homePageRowId: bigint!) {
    home_page_rows_by_pk(id: $homePageRowId) {
        id
        columns
        imageUrl
        homePageRowType
        isLandscape
        offerType
        priority
        rows
        subtitle
        textColour
        backgroundColour
        title
        viewAllSubCategoryId
        viewAllSubCategory {
          id
          name
        }
        homePageRowsSubCategories {
          subCategory {
            id
            name
          }
        }
    }
}`;

export const GetSubCategories = gql`
query GetSubCategories{
    sub_categories(order_by: {id: asc}, where: {isDeleted: {_eq: false}}){
        id
        name
    }
}`;

export const InsertHomePageRowsOne = gql`
mutation InsertHomePageRowsOne($homePageRowType: String!, $title: String!, $priority: Int!, $viewAllSubCategoryId: Int!, $columns: Int, $imageUrl: String, $isLandscape: Boolean, $offerType: String, $rows: Int, $subtitle: String, $textColour: String, $backgroundColour: String) {
    insert_home_page_rows_one(object: {homePageRowType: $homePageRowType, title: $title, priority: $priority, viewAllSubCategoryId: $viewAllSubCategoryId, columns: $columns, imageUrl: $imageUrl, isLandscape: $isLandscape, offerType: $offerType, rows: $rows, subtitle: $subtitle, textColour: $textColour, backgroundColour: $backgroundColour}) {
      id
    }
  }
  `;

export const UpdateHomePageRowsById = gql`
mutation UpdateHomePageRowsById($homePageRowId: bigint!, $homePageRowsSubCategoriesObject: [home_page_rows_sub_categories_insert_input!]!, $homePageRowType: String!, $title: String!, $priority: Int!, $viewAllSubCategoryId: Int!, $columns: Int, $imageUrl: String, $isLandscape: Boolean, $offerType: String, $rows: Int, $subtitle: String, $textColour: String, $backgroundColour: String) {
  update_home_page_rows_by_pk(pk_columns: {id: $homePageRowId}, _set: {homePageRowType: $homePageRowType, title: $title, priority: $priority, viewAllSubCategoryId: $viewAllSubCategoryId, columns: $columns, imageUrl: $imageUrl, isLandscape: $isLandscape, offerType: $offerType, rows: $rows, subtitle: $subtitle, textColour: $textColour, backgroundColour: $backgroundColour}) {
    id
  }
  insert_home_page_rows_sub_categories(objects: $homePageRowsSubCategoriesObject, on_conflict: {constraint: home_page_rows_sub_categories_homePageRowId_subCatgeoryId_key, update_columns: []}) {
    affected_rows
  }
}`;

export const DeleteHomePageRowsSubCategories = gql`
mutation DeleteHomePageRowsSubCategories($_or: [home_page_rows_sub_categories_bool_exp]!) {
  delete_home_page_rows_sub_categories(where: {_or: $_or}) {
    affected_rows
  }
}
`;

export const InsertHomePageRowsSubCategories = gql`
mutation InsertHomePageRowsSubCategories($homePageRowsSubCategoriesObject: [home_page_rows_sub_categories_insert_input!]!) {
  insert_home_page_rows_sub_categories(objects: $homePageRowsSubCategoriesObject, on_conflict: {constraint: home_page_rows_sub_categories_homePageRowId_subCatgeoryId_key, update_columns: []}) {
    affected_rows
  }
}`;
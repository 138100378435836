import React from "react";
import Spinner from "../Spinner/Spinner";

interface WithLoadingProps {
	isLoading?: boolean;
	size?: Number;
	thickness?: Number;
	className?: string;
}

const WithLoading: React.FC<WithLoadingProps> = ({
	isLoading,
	size,
	thickness,
	children,
	className,
}) => {
	return (
		<>
			{isLoading ? (
				<Spinner
					size={size ?? size}
					thickness={thickness ?? thickness}
				/>
			) : (
				children
			)}
		</>
	);
};

export default WithLoading;

import { useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import SnackbarContext from "../../../../Context/SnackbarContext";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import { CreateFaq, UpdateFaq } from "../FaqQuery";
import close from "../../../../Assets/Images/cross.svg";
import Input from "../../../UI/Input/Input";
import "../Faq.scss";
import { useParams } from "react-router-dom";
import TextArea from "../../../UI/Textarea/Textarea";

interface FaqQuestionEditProps {
	isOpen: boolean;
	editData: any;
	setEditData: (element: any | null) => void;
	setModal: (value: boolean) => void;
	refetch: () => void;
}

const FaqQuestionEdit: React.FC<FaqQuestionEditProps> = (
	props: FaqQuestionEditProps
) => {
	const { isOpen, editData, setEditData, setModal, refetch } = props;
	const [question, setQuestion] = useState<string>("");
	const [answer, setAnswer] = useState<string>("");
	const { id: titleId } = useParams<{ id: string }>();

	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateFaq] = useMutation(UpdateFaq);
	const [createFaq] = useMutation(CreateFaq);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const clearState = () => {
		setQuestion("");
		setAnswer("");
		setEditData(null);
	};

	const onEdit = () => {
		setMutationLoading(true);
		updateFaq({
			variables: {
				faqId: editData.id,
				question,
				answer,
			},
		})
			.then(({ data: { update_faqs: faqs } }: any) => {
				if (faqs.affected_rows > 0) {
					setMessage("Faq Updated Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	const onSave = () => {
		setMutationLoading(true);
		createFaq({
			variables: {
				titleId: titleId,
				question,
				answer,
			},
		})
			.then(({ data: { insert_faqs: faqTitles } }: any) => {
				if (faqTitles.affected_rows > 0) {
					setMessage("Faq Inserted Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	useEffect(() => {
		if (editData) {
			setQuestion(editData && editData.question);
			setAnswer(editData && editData.answer);
		}
	}, [editData]);

	return (
		<ReactModal
			className="faq__modal Modal__main"
			onRequestClose={() => {
				clearState();
				setModal(false);
			}}
			overlayClassName="Overlay"
			isOpen={isOpen}
		>
			<div className="close">
				<img
					src={close}
					alt="Close"
					className="close__img"
					onClick={() => {
						clearState();
						setModal(false);
					}}
				/>
			</div>
			<div className="add__modal__content">
				<p className="add__modal__header">
					{editData ? "Edit FAQ" : "Add FAQ"}
				</p>
				<div className="margin-auto w-75">
					<Input
						placeholder={"Question"}
						label
						type={"text"}
						disabled={false}
						value={question}
						onChange={(event: string) => {
							return setQuestion(event);
						}}
					/>

					<TextArea
						placeholder={"Answer"}
						label
						rows={5}
						columns={10}
						disabled={false}
						value={answer}
						onChange={(event: string) => {
							return setAnswer(event);
						}}
					/>
				</div>

				{!mutationLoading ? (
					<div
						className="w-40 margin-auto"
						style={{ marginTop: "2em" }}
					>
						<PrimaryButton
							label={editData ? "Edit" : "Save"}
							disabled={!question || !answer}
							loading={mutationLoading}
							active
							onClick={() => {
								editData ? onEdit() : onSave();
							}}
						/>
					</div>
				) : (
					<div
						className="spinner-container"
						style={{ marginTop: "3em" }}
					>
						<CircularProgress size={40} thickness={4} />
					</div>
				)}
			</div>
		</ReactModal>
	);
};

export default FaqQuestionEdit;

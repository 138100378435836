import {
	useApolloClient,
	useMutation,
	useQuery,
} from "@apollo/client/react/hooks";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SnackbarContext from "../../../../Context/SnackbarContext";
import {
	Home_Page_Rows,
	Home_Page_Row_Type_Enums,
	Sub_Categories,
} from "../../../../generated/graphql";
import WithLoading from "../../../UI/HOC/WithLoading";
import {
	DeleteHomePageRowsSubCategories,
	GetHomePageRowsById,
	GetSubCategories,
	InsertHomePageRowsOne,
	InsertHomePageRowsSubCategories,
	UpdateHomePageRowsById,
} from "./HomePageRowDetailQuery";
import Error from "../../../UI/Error/Error";
import "./HomePageRowDetail.scss";
import Search from "../../../UI/Search/Search";
import HeaderType from "./Components/HeaderType";
import RowType from "./Components/RowType";
import GridType from "./Components/GridType";
import OfferType from "./Components/OfferType";
import Input from "../../../UI/Input/Input";
import Chip from "@material-ui/core/Chip/Chip";
import { ca } from "date-fns/locale";
import { imageUpload } from "../../../../Utils/imageUpload";

const HomePageRowDetail = () => {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const history = useHistory();

	const [mode, setMode] = useState<string | null>(query.get("mode"));
	const [homePageRowId, setHomePageRowId] = useState<Number | null>(
		parseInt(query.get("id") as string)
	);

	const [title, setTitle] = useState<string>("");
	const [subtitle, setSubTitle] = useState<string>();

	const [isLandscape, setLandscape] = useState<boolean | null>();
	const [rows, setRows] = useState<Number | null>();
	const [columns, setColumns] = useState<Number | null>();
	const [offerType, setOfferType] = useState<string | null>();
	const [imageUrl, setImageUrl] = useState<string>();
	const [fileImage, setFileImage] = useState<string>();
	const [viewAllSubCategory, setViewAllSubCategory] =
		useState<Sub_Categories | null>();
	const [textColour, setTextColour] = useState<string | null>();
	const [backgroundColour, setBackgroundColour] = useState<string | null>();
	const [priority, setPriority] = useState<Number>();
	const [homePageRowType, setHomePageRowType] = useState<string>();
	const [subCategoryList, setSubCategoryList] = useState<any>([]);
	const [orgSubCategoryList, setOrgSubCategoryList] = useState<any>([]);

	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [notFoundError, setNotFountError] = useState<boolean>(false);
	const [queryLoading, setQueryLoading] = useState(false);
	const [mutationLoading, setMutationLoading] = useState(false);
	const { data: subCategoriesData, loading: subCategoreisLoading } =
		useQuery(GetSubCategories);
	const [insertHomePageRow] = useMutation(InsertHomePageRowsOne);
	const [updateHomePageRow] = useMutation(UpdateHomePageRowsById);
	const [deleteHomePageRowSubCategories] = useMutation(
		DeleteHomePageRowsSubCategories
	);
	const [insertHomePageRowsSubCategories] = useMutation(
		InsertHomePageRowsSubCategories
	);

	const client = useApolloClient();

	// Maybe fetch this from DB?
	const homePageRowTypeEnums: Array<any> = [
		{
			name: "Header",
		},
		{
			name: "Grid",
		},
		{
			name: "Offer",
		},
		{
			name: "Row",
		},
	];

	useEffect(() => {
		(async () => {
			try {
				if (mode === "edit" && homePageRowId) {
					setQueryLoading(true);
					const {
						data: { home_page_rows_by_pk: homePageRow },
					}: { data: { home_page_rows_by_pk: Home_Page_Rows } } =
						await client.query({
							query: GetHomePageRowsById,
							variables: { homePageRowId },
						});
					if (!homePageRow) throw "Not found";

					setTitle(homePageRow.title);
					setColumns(homePageRow.columns);
					setImageUrl(homePageRow.imageUrl as string);
					setHomePageRowType(homePageRow.homePageRowType);
					setLandscape(homePageRow.isLandscape);
					setOfferType(homePageRow.offerType);
					setPriority(homePageRow.priority);
					setRows(homePageRow.rows);
					setSubTitle(homePageRow.subtitle as string);
					setTextColour(homePageRow.textColour);
					setBackgroundColour(homePageRow.backgroundColour);
					setViewAllSubCategory(homePageRow.viewAllSubCategory);

					setSubCategoryList(
						homePageRow.homePageRowsSubCategories?.map(
							(e) => e.subCategory
						)
					);

					setOrgSubCategoryList(
						homePageRow.homePageRowsSubCategories?.map(
							(e) => e.subCategory
						)
					);
				}
				setQueryLoading(false);
			} catch (e) {
				console.log(e);
				setNotFountError(true);
				setQueryLoading(false);
			}
		})();
	}, [mode, homePageRowId, client]);

	const rowtype = homePageRowType?.toLocaleLowerCase();

	const enableSubmit = !(
		!title ||
		title?.length === 0 ||
		!priority ||
		!viewAllSubCategory ||
		((rowtype === "grid" || rowtype === "row") &&
			subCategoryList?.length === 0)
	);

	const handleSubCategoryDelete = (category) => {
		setSubCategoryList((values) => {
			return values.filter((val) => {
				return val.id != category.id;
			});
		});
	};

	const handleSubmit = async () => {
		try {
			setMutationLoading(true);
			const id = homePageRowId ? await onEdit() : await onAdd();

			setMessage(
				`Product ${homePageRowId ? "Updated" : "Added"} Successfully`
			);
			setSnackbar(true);
			if (!homePageRowId) {
				history.push(
					`/dashboard/home-page-rows/detail?mode=edit&id=${id}`
				);
			} else {
				client.reFetchObservableQueries();
			}
			setMutationLoading(false);
			setHomePageRowId(id);
		} catch (error: any) {
			setMessage(error.message);
			setSnackbar(true);
			setMutationLoading(false);
		}
	};

	const onAdd = async (): Promise<Number> => {
		try {
			let uploadedImageUrl;
			if (fileImage) {
				uploadedImageUrl = await uploadImage(fileImage);
			}

			const {
				data: {
					insert_home_page_rows_one: { id },
				},
			} = await insertHomePageRow({
				variables: {
					homePageRowType: homePageRowType,
					title: title,
					priority: priority,
					viewAllSubCategoryId: viewAllSubCategory!.id,
					columns: columns,
					imageUrl: uploadedImageUrl,
					isLandscape: isLandscape,
					offerType: offerType,
					rows: rows,
					subtitle: subtitle,
					textColour: textColour,
					backgroundColour: backgroundColour,
				},
			});

			const homePageRowsSubCategoriesObject = subCategoryList.map((e) => {
				return { subCategoryId: e.id, homePageRowId: id };
			});

			const {
				data: {
					insert_home_page_rows_sub_categories: { affected_rows },
				},
			} = await insertHomePageRowsSubCategories({
				variables: {
					homePageRowsSubCategoriesObject:
						homePageRowsSubCategoriesObject,
				},
			});

			return id;
		} catch (error: any) {
			throw error;
		}
	};

	const onEdit = async (): Promise<Number> => {
		try {
			// update Image

			let uploadedImageUrl;
			if (fileImage) {
				uploadedImageUrl = await uploadImage(fileImage);
			}

			// Delete HomePageRow_SubCategories
			const {
				data: {
					delete_home_page_rows_sub_categories: { affected_rows },
				},
			} = await deleteHomePageRowSubCategories({
				variables: {
					_or: generateOrExp(
						filterSubCategoriesToDelete({
							newSubCategories:
								subCategoryList as Array<Sub_Categories>,
							currentSubCategories: getCurrentSubCategories(),
						})
					),
				},
			});

			const homePageRowsSubCategoriesObject = subCategoryList.map((e) => {
				return { subCategoryId: e.id, homePageRowId: homePageRowId };
			});

			const {
				data: {
					update_home_page_rows_by_pk: { id },
				},
			} = await updateHomePageRow({
				variables: {
					homePageRowId: homePageRowId,
					homePageRowType: homePageRowType,
					title: title,
					priority: priority,
					viewAllSubCategoryId: viewAllSubCategory!.id,
					columns: columns,
					imageUrl: uploadedImageUrl ?? imageUrl, // should change if new url
					isLandscape: isLandscape,
					offerType: offerType,
					rows: rows,
					subtitle: subtitle,
					textColour: textColour,
					backgroundColour: backgroundColour,
					homePageRowsSubCategoriesObject:
						homePageRowsSubCategoriesObject,
				},
			});
			return id;
		} catch (error: any) {
			throw error;
		}
	};

	const uploadImage = async (imagePath) => {
		try {
			return await imageUpload(
				imagePath,
				`/images/HomePageRow/${Date.now()}`
			);
		} catch (error: any) {
			console.log(error);
			throw "Error uploading image";
		}
	};

	const getCurrentSubCategories =
		(): Array<Sub_Categories> => /*: Promise<[Categories]>*/ {
			// TODO: Fetch current categorires connected to the subcategories
			// For now, returning the previously fetched data

			if (mode !== "edit") return [];
			return orgSubCategoryList;
		};

	const filterSubCategoriesToDelete = ({
		currentSubCategories,
		newSubCategories,
	}: {
		currentSubCategories: Array<Sub_Categories>;
		newSubCategories: Array<Sub_Categories>;
	}): Array<Sub_Categories> => {
		return currentSubCategories.filter((c) => {
			const found = newSubCategories.find((n) => c.id === n.id);
			return !found;
		});
	};

	const generateOrExp = (
		subCategoriesToDelete: Array<Sub_Categories>
	): Array<any> => {
		if (mode !== "edit") return [];
		const t = subCategoriesToDelete.map((subCategory: Sub_Categories) => {
			return {
				_and: [
					{ subCategoryId: { _eq: subCategory.id } },
					{ homePageRowId: { _eq: homePageRowId } },
				],
			};
		});
		console.log(t);
		return t;
	};

	return (
		<div className="home-page-row-detail__main w-100">
			{notFoundError ? (
				<Error
					error={{
						message:
							"Given Product Type does not exist in database",
					}}
				/>
			) : (
				<WithLoading isLoading={queryLoading || subCategoreisLoading}>
					<div className="home-page-row-detail__main__container">
						<div className="d-flex w-100 justify-content-between">
							<p className="title">Home Page Row Detail</p>
							<div className="d-flex w-25 justify-content-between">
								<button
									type="button"
									className="button__primary button__primary-active"
									onClick={() => {
										history.goBack();
									}}
								>
									Back
								</button>
							</div>
						</div>

						<Input
							placeholder={"Title*"}
							label
							type={"text"}
							disabled={false}
							value={title}
							onChange={(event: string) => {
								return setTitle(event);
							}}
						/>

						<div className="d-flex w-100 justify-content-between-start">
							<div className="w-40 d-flex-column">
								<Search
									label="View All*"
									placeholder="Select One SubCategory"
									options={subCategoriesData?.sub_categories}
									value={viewAllSubCategory?.name ?? ""}
									onChangeSelect={(event: {
										id: number;
										name: string;
									}) => {
										if (!event || !event.id) {
											setViewAllSubCategory(null);
											return;
										}
										setViewAllSubCategory(
											event as Sub_Categories
										);
									}}
								/>
								<br></br>
								<p
									className="home-page-row-detail__hintText"
									style={{ marginTop: "0px" }}
								>
									(sub category connected to View All/ View
									Products/ View Collection buttons)
								</p>
							</div>
							<div className="w-40" style={{ marginTop: "0px" }}>
								<Input
									placeholder={"Priority*"}
									label
									type={"number"}
									disabled={false}
									value={priority?.toString() ?? ""}
									onChange={(event: string) => {
										return setPriority(parseInt(event));
									}}
								/>
								<p className="home-page-row-detail__hintText">
									(order of this row)
								</p>
							</div>
						</div>

						<div className="w-40">
							<Search
								label="Type*"
								placeholder="Select Type"
								options={homePageRowTypeEnums}
								value={homePageRowType ?? ""}
								//value={category && category.name}
								onChangeSelect={(event: { name: string }) => {
									if (!event || !event.name) return;
									if (
										event.name?.toLocaleLowerCase() ===
										"row"
									) {
										setLandscape(isLandscape ?? false);
									}
									if (
										event.name?.toLocaleLowerCase() ===
										"offer"
									) {
										setOfferType(offerType ?? "1");
									}
									setHomePageRowType(event.name);
								}}
							/>
						</div>
						<br></br>
						<hr></hr>
						{homePageRowType?.toLocaleLowerCase() === "header" ? (
							<HeaderType
								enableSubmit={enableSubmit}
								isEdit={homePageRowId ? true : false}
								loading={mutationLoading}
								subTitle={subtitle}
								imageUrl={imageUrl}
								onSubmit={() => {
									handleSubmit();
								}}
								onChange={({ subTitle, fileImage }) => {
									setSubTitle(subTitle);
									setFileImage(fileImage);
								}}
							/>
						) : homePageRowType?.toLocaleLowerCase() === "row" ? (
							<RowType
								enableSubmit={enableSubmit}
								isEdit={homePageRowId ? true : false}
								loading={mutationLoading}
								isLandscape={isLandscape ?? false}
								onSubmit={() => {
									handleSubmit();
								}}
								onChange={({ isLandscape }) => {
									setLandscape(isLandscape);
								}}
							>
								<div
									className="w-50"
									style={{ alignSelf: "start" }}
								>
									<Search
										label={"Sub Categories*"}
										placeholder={"select sub categories"}
										options={
											subCategoriesData?.sub_categories ??
											[]
										}
										value={""}
										onChangeSelect={(event: any) => {
											if (!event || !event.id) return;
											setSubCategoryList((values) => {
												const duplicateValue =
													values?.filter(
														(val) =>
															val.id === event.id
													);
												return !duplicateValue ||
													duplicateValue.length === 0
													? values
														? [...values, event]
														: [event]
													: values;
											});
										}}
									/>
									{subCategoryList &&
										subCategoryList.length > 0 && (
											<div className="home-page-row-detail__chip-container ">
												{subCategoryList.map(
													(chipData) => {
														return (
															<div
																style={{
																	margin: "5px",
																}}
															>
																<Chip
																	label={
																		chipData.name
																	}
																	key={
																		chipData.id
																	}
																	onDelete={() =>
																		handleSubCategoryDelete(
																			chipData
																		)
																	}
																></Chip>
															</div>
														);
													}
												)}
											</div>
										)}
								</div>
							</RowType>
						) : homePageRowType?.toLocaleLowerCase() === "grid" ? (
							<GridType
								enableSubmit={enableSubmit}
								isEdit={homePageRowId ? true : false}
								loading={mutationLoading}
								rows={rows}
								columns={columns}
								onChange={({ rows, columns }) => {
									setRows(rows);
									setColumns(columns);
								}}
								onSubmit={() => {
									handleSubmit();
								}}
							>
								<div
									className="w-50"
									style={{ alignSelf: "start" }}
								>
									<Search
										label={"Sub Categories*"}
										placeholder={"select sub categories"}
										options={
											subCategoriesData?.sub_categories ??
											[]
										}
										value={""}
										onChangeSelect={(event: any) => {
											if (!event || !event.id) return;
											setSubCategoryList((values) => {
												const duplicateValue =
													values?.filter(
														(val) =>
															val.id === event.id
													);
												return !duplicateValue ||
													duplicateValue.length === 0
													? values
														? [...values, event]
														: [event]
													: values;
											});
										}}
									/>
									{subCategoryList &&
										subCategoryList.length > 0 && (
											<div className="home-page-row-detail__chip-container ">
												{subCategoryList.map(
													(chipData) => {
														return (
															<div
																style={{
																	margin: "5px",
																}}
															>
																<Chip
																	label={
																		chipData.name
																	}
																	key={
																		chipData.id
																	}
																	onDelete={() =>
																		handleSubCategoryDelete(
																			chipData
																		)
																	}
																></Chip>
															</div>
														);
													}
												)}
											</div>
										)}
								</div>
							</GridType>
						) : homePageRowType?.toLocaleLowerCase() === "offer" ? (
							<OfferType
								enableSubmit={enableSubmit}
								isEdit={homePageRowId ? true : false}
								loading={mutationLoading}
								offerType={offerType}
								subTitle={subtitle}
								imageUrl={imageUrl}
								textColour={textColour}
								backgroundColour={backgroundColour}
								onSubmit={() => {
									handleSubmit();
								}}
								onChange={({
									offerType,
									fileImage,
									textColour,
									backgroundColour,
									subTitle,
								}) => {
									setOfferType(offerType);
									setSubTitle(subTitle);
									setFileImage(fileImage);
									setTextColour(textColour);
									setBackgroundColour(backgroundColour);
								}}
							/>
						) : (
							<div></div>
						)}
					</div>
				</WithLoading>
			)}
		</div>
	);
};

export default HomePageRowDetail;

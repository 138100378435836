import "./Product.scss";
import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import SearchBox from "../../UI/SearchBox/SearchBox";
import Error from "../../UI/Error/Error";
import { CircularProgress } from "@material-ui/core";
import Empty from "../../UI/Empty/Empty";
import SnackbarContext from "../../../Context/SnackbarContext";
import { GetProductTypesByProduct, ToggleProductType } from "./ProductQuery";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import ProductContext from "../../../Context/ProductContext";

//http://localhost:3000/dashboard/product-types?productId=1

const ProductType = () => {
	const [searchString, setSearchString] = useState<string>("");
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const history = useHistory();
	const [productId] = useState<number | null>(
		parseInt(query.get("productId") as string)
	);
	const [productName] = useState<string | null>(query.get("productName"));
	const { setProductId } = useContext(ProductContext);

	const {
		data: productTypes,
		error,
		loading,
		refetch,
	} = useQuery(GetProductTypesByProduct, {
		variables: {
			searchString: `%${searchString}%`,
			productId,
		},
	});

	const callRefetch = () => {
		console.log("here");
		refetch();
	};

	useEffect(() => {
		setProductId(productId);
	}, [setProductId, productId]);

	if (error) {
		return <Error error={error} />;
	}

	return (
		<div className="container-main">
			<div className="season__header">
				<div className="w-40">
					<SearchBox
						placeholder="Search product type with name"
						onChangeSearch={(value) => {
							setSearchString(value);
						}}
					/>
				</div>
				<button
					type="button"
					className="button__primary button__primary-active"
					onClick={() => {
						history.goBack();
					}}
				>
					Back
				</button>
			</div>

			<div className="coupon__box">
				<div className="coupon__main">
					<div className="season__header">
						<p className="title">
							Product Types &nbsp;({productName})
						</p>
						<button
							type="button"
							onClick={() => {
								history.push(
									"/dashboard/products/product-type-detail?mode=add&productTypeId=0"
								);
							}}
						>
							+Add Product Type
						</button>
					</div>
					<ProductTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : productTypes.product_type.length > 0 ? (
						productTypes.product_type.map((element: any) => {
							return (
								<ProductItem
									element={element}
									key={element.id}
									refetch={callRefetch}
									onClickEdit={() => {
										history.push(
											`/dashboard/products/product-type-detail?mode=edit&productTypeId=${element.id}`
										);
									}}
								/>
							);
						})
					) : (
						<Empty />
					)}
				</div>
			</div>
		</div>
	);
};

const ProductTitle: React.FC = () => {
	return (
		<div className="product-type__table">
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Name</p>
			<p className="season__table-title">Cost</p>
			<p className="season__table-title">Product</p>
			{/* {<p className="season__table-title">Sub-Category</p>
			<p className="season__table-title">Category</p>} */}
		</div>
	);
};

interface ItemProps {
	element: any;
	refetch: () => void;
	onClickEdit: () => void;
}

const ProductItem: React.FC<ItemProps> = (props: ItemProps) => {
	const { element, onClickEdit, refetch } = props;
	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [toggleProduct] = useMutation(ToggleProductType);
	const [loading, setLoading] = useState(false);

	const onToggle = () => {
		setLoading(true);
		toggleProduct({
			variables: {
				productTypeId: element.id,
				isDeleted: !element.isDeleted,
			},
		})
			.then(({ data }) => {
				setSnackbar(true);
				setMessage("Product updated Successfully");
				setLoading(false);
				refetch();
			})
			.catch((error) => {
				setSnackbar(true);
				setMessage(error.message);
				setLoading(false);
			});
	};

	return (
		<div className="product-type__table">
			<p className="season__table-item">{element.id}</p>
			<p className="season__table-item">{element.name}</p>
			<p className="season__table-item">{element.discountedPrice}</p>
			{/* {<p className="season__table-item">
				{
					//element.product.productsSubCategories?.subCategory?.name
				}
			</p>} */}
			<p className="season__table-item">{element.product.name}</p>
			{/* {<p className="season__table-item">
				{
					//element.product.productsSubCategories.category.name
				}
			</p>} */}

			<div className="season__actions">
				<button className="btn-list" onClick={onClickEdit}>
					View
				</button>

				{loading ? (
					<div style={{ width: "4rem" }}>
						<CircularProgress size={20} thickness={3} />
					</div>
				) : (
					<button
						className="btn-list"
						onClick={() => {
							onToggle();
						}}
					>
						{element.isDeleted ? "Enable" : "Disable"}
					</button>
				)}
			</div>
		</div>
	);
};

export default ProductType;

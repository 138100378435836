import React from "react";
import emptyImage from "../../../Assets/Images/empty.png";

const Empty = () => {
    return (
        <div
            className="spinner-container h-50 card-animation"
            style={{ marginTop: "5em" }}
        >
            <img src={emptyImage} alt="No Data" style={{ height: "15rem" }} />
        </div>
    );
};

export default Empty;

import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import SearchBox from "../../UI/SearchBox/SearchBox";
import "./Coupon.scss";
import {
	BulkInsertCoupon,
	GetCouponCount,
	GetCoupons,
	ToggleCoupon,
} from "./CouponQuery";
import Error from "../../UI/Error/Error";
import { CircularProgress } from "@material-ui/core";
import Empty from "../../UI/Empty/Empty";
import Pagination from "../../UI/Pagination/Pagination";
import SnackbarContext from "../../../Context/SnackbarContext";
import CouponEdit from "./CouponEdit/CouponEdit";
import { parse } from "papaparse";
import { useQueryParams } from "../Product/Product";

const Coupon: React.FC = () => {
	const [codeSearch, setCodeSearch] = useState<string>("");
	const [limit] = useState(8);
	const query = useQueryParams();
	const [offset, setOffset] = useState(
		query.get("offset") ? parseInt(query?.get("offset") ?? "0") : 0
	);
	const [uploadLoading, setUploadLoading] = useState<boolean>(false);

	const [editCoupon, setEditCoupon] = useState<any>(null);
	const [couponModal, setCouponModal] = useState<boolean>(false);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [bulkInsertCoupon] = useMutation(BulkInsertCoupon);

	const { data: coupons, error, loading, refetch } = useQuery(GetCoupons, {
		variables: {
			searchString: `%${codeSearch}%`,
			limit,
			offset,
		},
	});

	const {
		data: couponCount,
		error: errorCount,
		loading: countLoading,
		refetch: countRefetch,
	} = useQuery(GetCouponCount, {
		variables: {
			searchString: `%${codeSearch}%`,
		},
	});

	if (error || errorCount) {
		return <Error error={error ? error : errorCount} />;
	}

	const callRefetch = () => {
		refetch();
		countRefetch();
	};

	const onSave = (codes: any[]) => {
		bulkInsertCoupon({
			variables: {
				codes,
			},
		})
			.then(({ data: { insert_coupons: coupons } }: any) => {
				if (coupons.affected_rows > 0) {
					setMessage(
						`Got ${codes.length} coupons, inserted ${coupons.affected_rows} coupons`
					);
					setSnackbar(true);
					callRefetch();
					setUploadLoading(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setSnackbar(true);
					setUploadLoading(false);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setUploadLoading(false);
			});
	};

	const onChange = (e: any) => {
		setUploadLoading(true);
		const file = e.target.files[0];
		parse(file, {
			header: true,
			dynamicTyping: true,
			complete: ({ data, errors }: any) => {
				if (errors.length > 0) {
					setMessage("Given data is not suitable for bulk addition");
					setUploadLoading(false);
					return setSnackbar(true);
				}
				let codes = [];
				codes = data.map(({ code, value }: any) => {
					return { code: code.toString(), value: parseInt(value) };
				});

				onSave(codes);
			},
		});
	};

	return (
		<div className="container-main">
			<CouponEdit
				isOpen={couponModal}
				setModal={setCouponModal}
				refetch={callRefetch}
				setEditData={setEditCoupon}
				editData={editCoupon}
			/>

			<div className="w-40">
				<SearchBox
					placeholder="Search code"
					onChangeSearch={(value) => {
						setCodeSearch(value);
					}}
				/>
			</div>
			<div className="coupon__box">
				<div className="coupon__main">
					<div className="season__header">
						<p className="title">Coupons</p>

						<div className="coupon__header">
							{uploadLoading ? (
								<div
									className="spinner-container"
									style={{ width: "14rem" }}
								>
									<CircularProgress size={40} thickness={4} />
								</div>
							) : (
								<label
									className="button__primary button__primary-active"
									htmlFor="csv"
								>
									Upload CSV
									<input
										className="file-input"
										type="file"
										id="csv"
										accept=".csv"
										onChange={(
											event: React.ChangeEvent<HTMLInputElement>
										) => {
											onChange(event);
										}}
									></input>
								</label>
							)}
							<button
								type="button"
								onClick={() => {
									setCouponModal(true);
								}}
							>
								+Add Coupon
							</button>
						</div>
					</div>
					<CouponTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : coupons.coupons.length > 0 ? (
						coupons.coupons.map((element: any) => {
							return (
								<CouponItem
									element={element}
									key={element.id}
									refetch={refetch}
									onClickEdit={() => {
										setEditCoupon(element);
										setCouponModal(true);
									}}
								/>
							);
						})
					) : (
						<Empty />
					)}
				</div>

				{!loading && !countLoading && (
					<Pagination
						limit={limit}
						offset={offset}
						count={couponCount.coupons_aggregate.aggregate.count}
						changeOffset={(value) => setOffset(value)}
					/>
				)}
			</div>
		</div>
	);
};

export default Coupon;

const CouponTitle: React.FC = () => {
	return (
		<div className="coupon__table">
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Code</p>
			<p className="season__table-title">Value</p>
		</div>
	);
};

interface ItemProps {
	element: any;
	refetch: () => void;
	onClickEdit: () => void;
}

const CouponItem: React.FC<ItemProps> = (props: ItemProps) => {
	const { element, onClickEdit, refetch } = props;
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const [toggleCoupon] = useMutation(ToggleCoupon);
	const [loading, setLoading] = useState(false);

	const onToggle = () => {
		setLoading(true);

		toggleCoupon({
			variables: {
				couponId: element.id,
				isValid: !element.isValid,
			},
		})
			.then(({ data }) => {
				setSnackbar(true);
				setMessage("Status updated Successfully");
				setLoading(false);
				refetch();
			})
			.catch((error) => {
				setSnackbar(true);
				setMessage(error.message);
			});
	};

	return (
		<div className="coupon__table">
			<p className="season__table-item">{element.id}</p>
			<p className="season__table-item">{element.code}</p>
			<p className="season__table-item">{element.value}</p>
			<div className="season__actions">
				<button className="btn-list" onClick={onClickEdit}>
					Edit
				</button>

				{loading ? (
					<div style={{ width: "4rem" }}>
						<CircularProgress size={20} thickness={3} />
					</div>
				) : (
					<button
						className="btn-list"
						onClick={() => {
							onToggle();
						}}
					>
						{element.isValid ? "Enable" : "Disable"}
					</button>
				)}
			</div>
		</div>
	);
};

import { gql } from "@apollo/client";

export const GetCoupons = gql`
	query GetCoupons($searchString: String!, $limit: Int!, $offset: Int!) {
		coupons(order_by: {id: asc}, where: {code: {_ilike: $searchString}}, limit: $limit, offset: $offset) {
			code
			isValid
			value
			id
		}
	}
`;


export const GetCouponCount = gql`
	query GetCouponsCount($searchString: String!) {
		coupons_aggregate(where: {code: {_ilike: $searchString}}) {
			aggregate {
				count
			}
		}
	}
`;


export const ToggleCoupon = gql`
	mutation ToggleCoupon($couponId: Int!, $isValid: Boolean!) {
		update_coupons(where: {id: {_eq: $couponId}}, _set: {isValid: $isValid}) {
			affected_rows
		}
	}
`;

export const UpdateCoupon = gql`
	mutation UpdateCoupon($couponId: Int!, $code: String!, $value: Int!) {
		update_coupons(where: {id: {_eq: $couponId}}, _set: {code: $code, value: $value}) {
			affected_rows
		}
	}
`;

export const InsertCoupon = gql`
	mutation InsertCoupon($code: String!, $value: Int!) {
		insert_coupons(objects: {code: $code, value: $value}) {
			affected_rows
		}
	}
`;


export const BulkInsertCoupon = gql`
	mutation BulkInsertCoupon($codes: [coupons_insert_input!]!) {
		insert_coupons(objects: $codes) {
			affected_rows
		}
	}
`;
import React from "react";
import "./Nav.scss";
import { NavLink } from "react-router-dom";
import firebase from "../../../FirebaseConfig";
import { useHistory } from "react-router";
import Logo from "../../../Assets/Images/logo.png";
import { useState } from "react";
import { useEffect } from "react";

const section1Keywords: string[] = ["banners", "deal-of-the-day"];
const section2Keywords: string[] = [
	"categories",
	"subcategories",
	"products",
	"product-types-main",
	"Orders",
	"coupons",
];

const Nav = () => {
	const history = useHistory();
	const logout = () => {
		firebase
			.auth()
			.signOut()
			.then((data) => {
				history.push("/login");
			});
	};

	const [section, setSection] = useState<number>(1);

	useEffect(() => {
		console.log(window.location.href.split("/")[4]);
		if (section1Keywords.includes(window.location.href.split("/")[4])) {
			setSection(1);
		} else if (
			section2Keywords.includes(window.location.href.split("/")[4])
		) {
			setSection(2);
		} else {
			setSection(3);
		}
	}, []);

	return (
		<div className="nav">
			<div className="nav__logo">
				<NavLink to="/dashboard">
					<img
						src={Logo}
						className="nav__logo-image"
						alt="Indo Am Logo"
					/>
				</NavLink>
			</div>

			<div className="nav__items">
				<NavLink
					onClick={() => {
						setSection(1);
					}}
					to="/dashboard/banners"
					activeClassName="nav__item-selected"
				>
					<p>Home</p>
				</NavLink>

				{section === 1 && (
					<div className="nav__sub">
						<NavLink
							to="/dashboard/banners"
							activeClassName="nav__item-selected"
						>
							<p>Banners</p>
						</NavLink>

						<NavLink
							to="/dashboard/home-page-rows"
							activeClassName="nav__item-selected"
						>
							<p>Home Page Rows</p>
						</NavLink>

						<NavLink
							to="/dashboard/deal-of-the-day"
							activeClassName="nav__item-selected"
						>
							<p>Deal of the day</p>
						</NavLink>
					</div>
				)}
				<NavLink
					onClick={() => {
						setSection(2);
					}}
					to="/dashboard/category-types"
					activeClassName="nav__item-selected"
				>
					<p>Product Listing</p>
				</NavLink>

				{section === 2 && (
					<div className="nav__sub">
						<NavLink
							to="/dashboard/category-types"
							activeClassName="nav__item-selected"
						>
							<p>Category Types</p>
						</NavLink>

						<NavLink
							to="/dashboard/categories"
							activeClassName="nav__item-selected"
						>
							<p>Categories</p>
						</NavLink>

						<NavLink
							to="/dashboard/subcategories"
							activeClassName="nav__item-selected"
						>
							<p>Sub-categories</p>
						</NavLink>

						<NavLink
							to="/dashboard/products"
							activeClassName="nav__item-selected"
						>
							<p>Products</p>
						</NavLink>

						<NavLink
							to="/dashboard/product-types-main"
							activeClassName="nav__item-selected"
						>
							<p>Products Types</p>
						</NavLink>

						<NavLink
							to="/dashboard/orders"
							activeClassName="nav__item-selected"
						>
							<p>Orders</p>
						</NavLink>

						<NavLink
							to="/dashboard/coupons"
							activeClassName="nav__item-selected"
						>
							<p>Coupons</p>
						</NavLink>
					</div>
				)}

				<NavLink
					onClick={() => {
						setSection(3);
					}}
					to="/dashboard/seasons"
					activeClassName="nav__item-selected"
				>
					<p>Others</p>
				</NavLink>

				{section === 3 && (
					<div className="nav__sub">
						<NavLink
							to="/dashboard/seasons"
							activeClassName="nav__item-selected"
						>
							<p>SEASONS</p>
						</NavLink>

						{/* <NavLink
							to="/dashboard/stores"
							activeClassName="nav__item-selected"
						>
							<p>STORES</p>
						</NavLink> */}

						<NavLink
							to="/dashboard/faqs"
							activeClassName="nav__item-selected"
						>
							<p>FAQs</p>
						</NavLink>

						<NavLink
							to="/dashboard/users"
							activeClassName="nav__item-selected"
						>
							<p>Users</p>
						</NavLink>

						<NavLink
							to="/dashboard/blogs"
							activeClassName="nav__item-selected"
						>
							<p>Blogs</p>
						</NavLink>

						<NavLink
							to="/dashboard/testimonials"
							activeClassName="nav__item-selected"
						>
							<p>Testimonials</p>
						</NavLink>
					</div>
				)}
			</div>

			<p className="nav__sign-out" onClick={logout}>
				sign out
			</p>
		</div>
	);
};

export default Nav;

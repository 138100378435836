import { gql } from "@apollo/client";

export const GetFaqsTitles = gql`
	query GetFaqsTitles {
		faq_titles(order_by: {id: asc}, where: {isDeleted: {_eq: false}}) {
			id
			title
		}
	}
`;
export const UpdateFaqTitleById = gql`
	mutation UpdateFaqTitleById($title: String!, $faqId: Int!) {
		update_faq_titles(where: {id: {_eq: $faqId}}, _set: {title: $title}) {
			affected_rows
		}
	}
`;

export const CreateFaqTitle = gql`
	mutation CreateFaqTitle($title: String!) {
		insert_faq_titles(objects: {title: $title}) {
			affected_rows
		}
	}
`;


export const GetFaqsByTitleId = gql`
	query GetFaqsByTitleId($titleId: Int!) {
		faq_titles_by_pk(id: $titleId) {
			title
			faqs(order_by: {id: asc}) {
				id
				answer
				question
			}
		}
	}
`;

export const CreateFaq = gql`
	mutation CreateFaq($answer: String!, $titleId: Int!, $question: String!) {
		insert_faqs(objects: {answer: $answer, faqTitleId: $titleId, question: $question}) {
			affected_rows
		}
	}
`;


export const UpdateFaq = gql`
	mutation UpdateFaq($answer: String!, $faqId: Int!, $question: String!) {
		update_faqs(where: {id: {_eq: $faqId}}, _set: {answer: $answer, question: $question}) {
			affected_rows
		}
	}
`;


export const DeleteFaqTitle = gql`
	mutation DeleteFaqTitle($faqTitleId: Int!) {
		delete_faq_titles(where: { id: { _eq: $faqTitleId } }) {
			affected_rows
		}
	}
`;


export const DeleteFaq = gql`
	mutation DeleteFaq($faqId: Int!) {
		delete_faqs(where: { id: { _eq: $faqId } }) {
			affected_rows
		}
	}
`;





import { useApolloClient, useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import SearchBox from "../../UI/SearchBox/SearchBox";
import "./User.scss";
import { GetUserCount, GetUserDataForDataExport, GetUsers } from "./UserQuery";
import Error from "../../UI/Error/Error";
import { CircularProgress } from "@material-ui/core";
import Empty from "../../UI/Empty/Empty";
import Pagination from "../../UI/Pagination/Pagination";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import SnackbarContext from "../../../Context/SnackbarContext";
import { useQueryParams } from "../Product/Product";

const Coupon: React.FC = () => {
	const [codeSearch, setCodeSearch] = useState<string>("");
	const [limit] = useState(8);
	const query = useQueryParams();
	const [offset, setOffset] = useState(
		query.get("offset") ? parseInt(query?.get("offset") ?? "0") : 0
	);
	const [userExportData, setUserExportData] = useState<any[]>([]);
	const [userDataDownloading, setUserDataDownloading] = useState<boolean>(
		false
	);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const { data: users, error, loading } = useQuery(GetUsers, {
		variables: {
			searchString: `%${codeSearch}%`,
			limit,
			offset,
		},
	});

	const {
		data: userCount,
		error: errorCount,
		loading: countLoading,
	} = useQuery(GetUserCount, {
		variables: {
			searchString: `%${codeSearch}%`,
		},
	});

	const client = useApolloClient();
	const getUserExportData = async () => {
		try {
			setUserDataDownloading(true);
			const {
				data: { users },
			} = await client.query({
				query: GetUserDataForDataExport,
				fetchPolicy: "network-only",
			});

			const usersCleaned = JSON.parse(JSON.stringify(users));
			usersCleaned.map((item) => delete item.__typename);
			setUserExportData(usersCleaned);
			setMessage("User data converted to CSV successfully");
		} catch (error) {
			setMessage(error.message);
		} finally {
			setUserDataDownloading(false);
			setSnackbar(true);
		}
	};

	if (error || errorCount) {
		return <Error error={error ? error : errorCount} />;
	}

	return (
		<div className="container-main">
			<div className="w-50">
				<SearchBox
					placeholder="Search users with email, phone or name"
					onChangeSearch={(value) => {
						setCodeSearch(value);
					}}
				/>
			</div>
			<div className="coupon__box">
				<div className="coupon__main">
					<div className="season__header">
						<p className="title">Users</p>
						{userExportData.length === 0 && !userDataDownloading ? (
							<button
								type="button"
								onClick={() => {
									getUserExportData();
								}}
							>
								Export Users As CSV
							</button>
						) : userExportData.length > 0 &&
						  !userDataDownloading ? (
							<CSVLink
								data={userExportData}
								filename={"users.csv"}
							>
								Download
							</CSVLink>
						) : (
							<CircularProgress size={40} thickness={4} />
						)}
					</div>
					<CouponTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : users.users.length > 0 ? (
						users.users.map((element: any) => {
							return (
								<CouponItem
									element={element}
									key={element.id}
								/>
							);
						})
					) : (
						<Empty />
					)}
				</div>

				{!loading && !countLoading && (
					<Pagination
						limit={limit}
						offset={offset}
						count={userCount.users_aggregate.aggregate.count}
						changeOffset={(value) => setOffset(value)}
					/>
				)}
			</div>
		</div>
	);
};

export default Coupon;

const CouponTitle: React.FC = () => {
	return (
		<div className="user__table">
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Name</p>
			<p className="season__table-title">Email</p>
			<p className="season__table-title">Phone</p>
		</div>
	);
};

interface ItemProps {
	element: any;
}

const CouponItem: React.FC<ItemProps> = (props: ItemProps) => {
	const { element } = props;
	const history = useHistory();

	return (
		<div className="user__table">
			<p className="season__table-item">{element.id}</p>
			<p className="season__table-item">
				{element.firstName} {element.lastName}
			</p>
			<p className="season__table-item">{element.email}</p>
			<p className="season__table-item">{element.phoneNumber}</p>

			<div className="season__actions">
				<button
					className="btn-list"
					onClick={() => {
						history.push(`/dashboard/users/${element.id}`);
					}}
				>
					view
				</button>
			</div>
		</div>
	);
};

import { useApolloClient, useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SnackbarContext from "../../../../Context/SnackbarContext";
import { imageUpload } from "../../../../Utils/imageUpload";
import ImageView from "../../../UI/ImageView/ImageView";
import Input from "../../../UI/Input/Input";
import TextArea from "../../../UI/Textarea/Textarea";
import BlogEditor from "../BlogEditor/BlogEditor";
import { GetBlogById, InsertBlog, UpdateBlog } from "../BlogQuery";

const BlogView = () => {
	const [title, setTitle] = useState<string>("");
	const [summary, setSummary] = useState<string>("");
	const [author, setAuthor] = useState<string>("");
	const [imageUrl, setImageUrl] = useState<string>("");

	const [loading, setLoading] = useState<boolean>(false);

	const { id: blogId } = useParams<{ id: string }>();
	const [html, setHtml] = useState<any>(null);
	const history = useHistory();
	const client = useApolloClient();
	const [fileImage, setFileImage] = useState<any>(null);

	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	useEffect(() => {
		if (parseInt(blogId)) {
			console.log("Fetching Data");
			setLoading(true);
			client
				.query({
					query: GetBlogById,
					variables: {
						blogId: parseInt(blogId),
					},
				})
				.then(({ data: { blogs_by_pk: blog } }: any) => {
					if (blog) {
						setTitle(blog.title);
						setSummary(blog.summary);
						setHtml(blog.body);
						setAuthor(blog.author);
						setImageUrl(blog.imageUrl);

						setLoading(false);
					} else {
						setMessage(
							"Blog either does not exist or is deleted by admin"
						);
						setSnackbar(true);
						setLoading(false);
					}
				});
		}
	}, [blogId, client, setTitle, setSummary, setMessage, setSnackbar]);

	const [mutationLoading, setMutationLoading] = useState(false);
	const [updateCoupon] = useMutation(UpdateBlog);
	const [insertCoupon] = useMutation(InsertBlog);

	const onSave = async () => {
		try {
			setMutationLoading(true);

			let imageUrl = await imageUpload(
				fileImage,
				`/images/Blogs/${title}`
			);

			const {
				data: { insert_blogs: blogs },
			} = await insertCoupon({
				variables: {
					title,
					summary,
					body: html,
					imageUrl,
					author,
				},
			});
			if (blogs.affected_rows > 0) {
				setMessage("Blog Inserted Successfully");
				setSnackbar(true);
				history.push("/dashboard/blogs");
			} else {
				setMessage("Some Unknown Error Occurred");
				setSnackbar(true);
			}
		} catch (error) {
			setMessage(error.message);
			setSnackbar(true);
		} finally {
			setMutationLoading(false);
		}
	};

	const onEdit = async () => {
		setMutationLoading(true);
		let imageUrlFirebase = imageUrl;

		if (fileImage) {
			imageUrlFirebase = await imageUpload(
				fileImage,
				`/images/Blogs/${title}`
			);
		}
		updateCoupon({
			variables: {
				title,
				summary,
				body: html,
				author,
				blogId,
				imageUrl: imageUrlFirebase,
			},
		})
			.then(({ data: { update_blogs: blogs } }: any) => {
				if (blogs.affected_rows > 0) {
					setMessage("Blog Updated Successfully");
					history.push("/dashboard/blogs");
					setSnackbar(true);
					setMutationLoading(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	return (
		<div className="blog__main">
			<div className="banner__container">
				<div className="season__header">
					<p className="title">Blog</p>

					<div
						style={{ width: "20%" }}
						className="d-flex justify-content-between"
					>
						{mutationLoading ? (
							<div className="spinner-container">
								<CircularProgress size={40} thickness={4} />
							</div>
						) : (
							<button
								type="button"
								className="button__primary button__primary-active"
								onClick={() => {
									parseInt(blogId) ? onEdit() : onSave();
								}}
							>
								{parseInt(blogId) ? "Edit" : "Save"}
							</button>
						)}

						<button
							type="button"
							className="button__primary button__primary-active"
							onClick={() => {
								history.goBack();
							}}
						>
							Back
						</button>
					</div>
				</div>
				{loading ? (
					<div className="spinner-container">
						<CircularProgress size={40} thickness={4} />
					</div>
				) : (
					<div style={{ marginBottom: "2em" }}>
						<div className="w-80">
							<div className="d-flex justify-content-between">
								<Input
									placeholder={"Title"}
									label
									type={"text"}
									disabled={false}
									value={title}
									onChange={(event: string) => {
										return setTitle(event);
									}}
								/>
								<Input
									placeholder={"Author"}
									label
									type={"text"}
									disabled={false}
									value={author}
									onChange={(event: string) => {
										return setAuthor(event);
									}}
								/>
							</div>

							<div className="d-flex justify-content-between">
								<TextArea
									placeholder={"Summary"}
									label
									rows={8}
									columns={40}
									disabled={false}
									value={summary}
									onChange={(event: string) => {
										return setSummary(event);
									}}
								/>
								<div className="w-40">
									<ImageView
										uniqueName={"ImageUrl"}
										imageUrl={imageUrl}
										setImage={setFileImage}
										label={"Blog Cover Image"}
									/>
								</div>
							</div>
						</div>

						<p className="label">Content</p>
						<BlogEditor
							convertedContent={html}
							setConvertedContent={setHtml}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default BlogView;

import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import SnackbarContext from "../../../Context/SnackbarContext";
import DeleteModal from "../../UI/DeleteModal/DeleteModal";
import Empty from "../../UI/Empty/Empty";
import Error from "../../UI/Error/Error";
import Pagination from "../../UI/Pagination/Pagination";
import SearchBox from "../../UI/SearchBox/SearchBox";
import { DeleteBlog, GetBlogCount, GetBlogs } from "./BlogQuery";
import "./Blog.scss";
import { useQueryParams } from "../Product/Product";

const Blog: React.FC = () => {
	const [searchString, setSearchString] = useState<string>("");
	const [limit] = useState(8);
	const query = useQueryParams();
	const [offset, setOffset] = useState(
		query.get("offset") ? parseInt(query?.get("offset") ?? "0") : 0
	);
	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [deleteBlog] = useMutation(DeleteBlog);

	const [deleteId, setDeleteId] = useState<number | null>(null);
	const { data: blogs, error, loading, refetch } = useQuery(GetBlogs, {
		variables: {
			searchString: `%${searchString}%`,
			limit: limit,
			offset: offset,
		},
	});

	const {
		data: blogCount,
		error: errorCount,
		loading: countLoading,
		refetch: countRefetch,
	} = useQuery(GetBlogCount, {
		variables: {
			searchString: `%${searchString}%`,
		},
	});

	const history = useHistory();

	if (error || errorCount) {
		return <Error error={error ? error : errorCount} />;
	}

	const callRefetch = () => {
		refetch();
		countRefetch();
	};

	const onDelete = (blogId: number) => {
		setDeleteLoading(true);
		deleteBlog({
			variables: {
				blogId,
			},
		})
			.then(({ data: { delete_blogs: blogs } }: any) => {
				if (blogs.affected_rows > 0) {
					setMessage("Store Location Deleted Successfully");
					setSnackbar(true);
					callRefetch();
					setDeleteLoading(false);
					return setDeleteModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setDeleteLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setDeleteLoading(false);
			});
	};

	return (
		<div className="container-main">
			<DeleteModal
				loading={deleteLoading}
				setModal={setDeleteModal}
				modal={deleteModal}
				onClickDelete={() => {
					deleteId && onDelete(deleteId);
				}}
			/>
			<div className="w-40">
				<SearchBox
					placeholder="Search Blog titles"
					onChangeSearch={(value) => {
						setSearchString(value);
					}}
				/>
			</div>
			<div className="season__box">
				<div className="season__main">
					<div className="season__header">
						<p className="title">Blogs</p>
						<button
							type="button"
							onClick={() => {
								history.push("/dashboard/blogs/0");
							}}
						>
							+Create Blog
						</button>
					</div>
					<SeasonTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : blogs.blogs.length > 0 ? (
						blogs.blogs.map((element: any) => {
							return (
								<SeasonItem
									element={element}
									key={element.id}
									onClickEdit={() => {
										history.push(
											`/dashboard/blogs/${element.id}`
										);
									}}
									onClickDelete={(id: number) => {
										setDeleteModal(true);
										setDeleteId(id);
									}}
								/>
							);
						})
					) : (
						<Empty />
					)}
				</div>

				{!loading && !countLoading && (
					<Pagination
						limit={limit}
						offset={offset}
						count={blogCount.blogs_aggregate.aggregate.count}
						changeOffset={(value) => setOffset(value)}
					/>
				)}
			</div>
		</div>
	);
};

export default Blog;

const SeasonTitle: React.FC = () => {
	return (
		<div className="coupon__table">
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Title</p>
			<p className="season__table-title">Author</p>
		</div>
	);
};

interface SeasonItemProps {
	element: any;
	onClickEdit: () => void;
	onClickDelete: (id: number) => void;
}

const SeasonItem: React.FC<SeasonItemProps> = (props: SeasonItemProps) => {
	const { element, onClickEdit, onClickDelete } = props;

	return (
		<div className="coupon__table">
			<p className="season__table-item">{element.id}</p>
			<p className="season__table-item">{element.title}</p>
			<p className="season__table-item">{element.author}</p>

			<div className="season__actions">
				<button className="btn-list" onClick={onClickEdit}>
					view
				</button>
				<button
					className="btn-list"
					onClick={() => onClickDelete(element.id)}
				>
					delete
				</button>
			</div>
		</div>
	);
};

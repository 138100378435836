import React, { useState } from "react";
import addSvg from "../../../Assets/Images/add.svg";
import "./ImageView.scss";

interface ImageViewProps {
	imageUrl: string;
	setImage: (value: string) => void;
	label: string;
	uniqueName?: string;
}

const ImageView: React.FC<ImageViewProps> = (props: ImageViewProps) => {
	const { setImage, label, imageUrl, uniqueName } = props;

	const [localImage, setLocalImage] = useState("");
	const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
		const uploadedImage: any =
			event.target.files &&
			event.target.files.length > 0 &&
			event.target.files[0];
		setImage(uploadedImage);
		const reader: any = new FileReader();
		reader.onload = function (e: any) {
			setLocalImage(reader.result);
		};
		reader.readAsDataURL(uploadedImage);
	};
	return (
		<div className="item-view">
			<p className="label">{label}</p>
			{!imageUrl && !localImage && (
				<label
					className="upload "
					htmlFor={uniqueName ? uniqueName : "description"}
				>
					<img src={addSvg} alt="upload" className="image-svg" />
				</label>
			)}
			{imageUrl && !localImage && (
				<label
					className="upload"
					htmlFor={uniqueName ? uniqueName : "description"}
				>
					<img
						src={imageUrl}
						alt="Uploaded"
						className="image-upload"
					/>
				</label>
			)}
			{localImage && (
				<label
					className="upload"
					htmlFor={uniqueName ? uniqueName : "description"}
				>
					<img
						src={localImage}
						alt="Uploaded"
						className="image-upload"
					/>
				</label>
			)}

			<input
				type="file"
				id={uniqueName ? uniqueName : "description"}
				accept="image/*"
				onChange={onChangeImage}
				className="input-file"
			/>
		</div>
	);
};

export default ImageView;

import { gql } from "@apollo/client";

export const GetHomePageRows = gql`
query GetHomePageRows {
    home_page_rows(order_by: {priority: asc}) {
      id
      priority
      title
      isDeleted
      homePageRowType
    }
  }
`;

export const UpdateHomePageRowsVisiblity = gql`
mutation UpdateHomePageRowsVisiblityById($homePageRowId: bigint!, $isDeleted: Boolean!) {
  update_home_page_rows_by_pk(pk_columns: {id: $homePageRowId}, _set: {isDeleted: $isDeleted}){
    id
    isDeleted
  }
}`;


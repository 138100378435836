import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Chip, CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SnackbarContext from "../../../Context/SnackbarContext";
import { imageUpload } from "../../../Utils/imageUpload";
import ImageView from "../../UI/ImageView/ImageView";
import Input from "../../UI/Input/Input";
import Search from "../../UI/Search/Search";
import TextArea from "../../UI/Textarea/Textarea";
import { GetSubCategories } from "../SubCategory/SubCategoryQuery";
import {
	DeleteProductsSubCategories,
	GetProductDetails,
	InsertProduct,
	InsertProductsSubCategories,
	UpdateProduct,
} from "./ProductQuery";
import Error from "../../UI/Error/Error";
import {
	Products_Sub_Categories,
	Sub_Categories,
} from "../../../generated/graphql";

const ProductDetail = () => {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	// {query.get("mode")}
	// 			{query.get("id")}

	const [mode] = useState<string | null>(query.get("mode"));
	const [productId, setProductId] = useState<number | null>(
		parseInt(query.get("id") as string)
	);

	const [name, setName] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [nutritiveValue, setNutritiveValue] = useState<string>("");
	const [coverImageUrl, setCoverImageUrl] = useState<string>("");
	const [imageUrl, setImageUrl] = useState<string>("");
	const [hoverImageUrl, setHoverImageUrl] = useState<string>("");
	const [subCategoryList, setSubCategoryList] = useState<any>(null);
	const [orgSubCategoryList, setOrgSubCategoryList] = useState<any>(null);
	const [fileCoverImage, setFileCoverImage] = useState<any>(null);
	const [fileHoverImage, setFileHoverImage] = useState<any>(null);
	const [fileImage, setFileImage] = useState<any>(null);
	const [loading, setLoading] = useState(false);
	const [notFoundError, setNotFountError] = useState<boolean>(false);

	const [queryLoading, setQueryLoading] = useState(false);

	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [insertProduct] = useMutation(InsertProduct);
	const [updateProduct] = useMutation(UpdateProduct);

	const client = useApolloClient();
	const { data: subCategories, loading: subCategoryLoading } =
		useQuery(GetSubCategories);
	const [deleteProductsSubCategories] = useMutation(
		DeleteProductsSubCategories
	);

	const [insertProductsSubCategories] = useMutation(
		InsertProductsSubCategories
	);

	const history = useHistory();

	useEffect(() => {
		if (mode === "edit" && productId) {
			setQueryLoading(true);
			client
				.query({ query: GetProductDetails, variables: { productId } })
				.then(({ data: { product } }) => {
					if (product.length > 0) {
						const productDetail = product[0];
						setImageUrl(productDetail.imageUrl);
						setHoverImageUrl(productDetail.hoverImageUrl);
						setCoverImageUrl(productDetail.coverImageUrl);
						setName(productDetail.name);
						setDescription(productDetail.description);
						setSubCategoryList(
							productDetail.productsSubCategories.map(
								(e) => e.subCategory
							)
						);
						setOrgSubCategoryList(
							productDetail.productsSubCategories.map(
								(e) => e.subCategory
							)
						);
						setNutritiveValue(productDetail.nutritiveValue);
						setQueryLoading(false);
					} else {
						setNotFountError(true);
						setQueryLoading(false);
					}
				});
		}
	}, [mode, productId, client]);

	const handleSubCategoryDelete = (category) => {
		setSubCategoryList((values) => {
			return values.filter((val) => {
				return val.id != category.id;
			});
		});
	};

	const onSave = async () => {
		setLoading(true);
		try {
			let imageUrl = await imageUpload(
				fileImage,
				`/images/ProductTypes/${name}`
			);
			let coverImageUrl = await imageUpload(
				fileCoverImage,
				`/images/ProductTypes/${name}_cover`
			);
			let hoverImageUrl = await imageUpload(
				fileHoverImage,
				`/images/ProductTypes/${name}_hover`
			);

			const {
				data: {
					insert_product_one: { id },
				},
			} = await insertProduct({
				variables: {
					name,
					description,
					nutritiveValue,
					hoverImageUrl,
					imageUrl,
					coverImageUrl,
				},
			});

			const productsSubCategoriesObject = subCategoryList.map((e) => {
				return { subCategoryId: e.id, productId: id };
			});

			const {
				data: {
					insert_products_sub_categories: { affected_rows },
				},
			} = await insertProductsSubCategories({
				variables: {
					productsSubCategoriesObject: productsSubCategoriesObject,
				},
			});

			setMessage("Product Updated Successfully");
			setSnackbar(true);
			history.push(
				`/dashboard/products/product-detail?mode=edit&id=${id}`
			);
			setLoading(false);
			setProductId(id);
		} catch (error: any) {
			setMessage(error.message);
			setSnackbar(true);
			setLoading(false);
		}

		/* insertProduct({
			variables: {
				name,
				description,
				nutritiveValue,
				hoverImageUrl,
				imageUrl,
				coverImageUrl,
			},
		})
			.then(
				({
					data: {
						insert_product_one: { id },
					},
				}) => {
					console.log(id);
					setMessage("Product Updated Successfully");
					setSnackbar(true);
					history.push(
						`/dashboard/products/product-detail?mode=edit&id=${id}`
					);
					setLoading(false);
				}
			)
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setLoading(false);
			}); */
	};

	const onEdit = async () => {
		setLoading(true);
		let imageUrlFirebase = imageUrl;
		let coverUrlFirebase = coverImageUrl;
		let hoverUrlFirebase = hoverImageUrl;

		if (fileImage) {
			imageUrlFirebase = await imageUpload(
				fileImage,
				`/images/ProductTypes/${name}`
			);
		}
		if (fileCoverImage) {
			coverUrlFirebase = await imageUpload(
				fileCoverImage,
				`/images/ProductTypes/${name}_cover`
			);
		}
		if (fileHoverImage) {
			hoverUrlFirebase = await imageUpload(
				fileHoverImage,
				`/images/ProductTypes/${name}_hover`
			);
		}

		try {
			// Delete Products_SubCategories
			const {
				data: {
					delete_products_sub_categories: { affected_rows },
				},
			} = await deleteProductsSubCategories({
				variables: {
					_or: generateOrExp(
						filterSubCategoriesToDelete({
							newSubCategories:
								subCategoryList as Array<Sub_Categories>,
							currentSubCategories: getCurrentSubCategories(),
						})
					),
				},
			});
		} catch (error: any) {
			console.log("Error deleting product subcategories: ");
			console.log(error);
			setMessage(error.message);
			setSnackbar(true);
			setLoading(false);
			return;
		}

		const productsSubCategoriesObject = subCategoryList.map((e) => {
			return { subCategoryId: e.id, productId: productId };
		});

		updateProduct({
			variables: {
				productId,
				name,
				description,
				nutritiveValue,
				hoverImageUrl: hoverUrlFirebase,
				imageUrl: imageUrlFirebase,
				coverImageUrl: coverUrlFirebase,
				productsSubCategoriesObject: productsSubCategoriesObject,
			},
		})
			.then(({ data }) => {
				setMessage("Product Updated Successfully");
				setSnackbar(true);
				client.reFetchObservableQueries();
				setLoading(false);
			})
			.catch((error) => {
				console.log("Error updating product detail");
				console.log(error);
				setMessage(error.message);
				setSnackbar(true);
				setLoading(false);
			});
	};

	const getCurrentSubCategories =
		(): Array<Sub_Categories> => /*: Promise<[Categories]>*/ {
			// TODO: Fetch current categorires connected to the subcategories
			// For now, returning the previously fetched data

			if (mode !== "edit") return [];
			return orgSubCategoryList;
		};

	const filterSubCategoriesToDelete = ({
		currentSubCategories,
		newSubCategories,
	}: {
		currentSubCategories: Array<Sub_Categories>;
		newSubCategories: Array<Sub_Categories>;
	}): Array<Sub_Categories> => {
		return currentSubCategories.filter((c) => {
			const found = newSubCategories.find((n) => c.id === n.id);
			return !found;
		});
	};

	const generateOrExp = (
		subCategoriesToDelete: Array<Sub_Categories>
	): Array<any> => {
		if (mode !== "edit") return [];
		return subCategoriesToDelete.map((subCategory: Sub_Categories) => {
			return {
				_and: [
					{ subCategoryId: { _eq: subCategory.id } },
					{ productId: { _eq: productId } },
				],
			};
		});
	};

	if (notFoundError) {
		return (
			<Error
				error={{ message: "Given Product does not exist in database" }}
			/>
		);
	} else
		return (
			<div className="container-main">
				<div className="banner__container ">
					<div className="d-flex w-100 justify-content-between">
						<p className="title">Product Detail</p>

						<div className="w-25 d-flex justify-content-between ">
							{!loading ? (
								<>
									<button
										onClick={productId ? onEdit : onSave}
										className="button__primary button__primary-active"
										disabled={
											!name.trim() ||
											!description.trim() ||
											!subCategoryList ||
											subCategoryList.length === 0 ||
											!(
												fileCoverImage || coverImageUrl
											) ||
											!(fileImage || imageUrl) ||
											!(fileHoverImage || hoverImageUrl)
										}
									>
										{productId ? "Edit" : "Save"}
									</button>
								</>
							) : (
								<div className="">
									<div className="spinner-container">
										<CircularProgress
											size={40}
											thickness={4}
										/>
									</div>
								</div>
							)}

							<button
								type="button"
								className="button__primary button__primary-active"
								onClick={() => {
									history.goBack();
								}}
							>
								Back
							</button>
						</div>
					</div>
					{subCategoryLoading || queryLoading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : (
						<>
							<div className="w-100 d-flex justify-content-between">
								<div className="w-40">
									<ImageView
										uniqueName={"ImageUrl*"}
										imageUrl={imageUrl}
										setImage={setFileImage}
										label={"Product Image*"}
									/>
								</div>

								<div className="w-40">
									<ImageView
										uniqueName={"CoverImageUrl*"}
										imageUrl={coverImageUrl}
										setImage={setFileCoverImage}
										label={"Product Cover Image*"}
									/>
								</div>
							</div>
							<div className="w-100 d-flex justify-content-between-start">
								<div className="w-40 d-flex-column">
									<Input
										placeholder={"Name*"}
										label
										type={"text"}
										disabled={false}
										value={name}
										onChange={(event: string) => {
											return setName(event);
										}}
									/>
									<div className="w-100">
										<Search
											label={"Sub Category*"}
											options={
												subCategories.sub_categories
											}
											value={""}
											onChangeSelect={(event: any) => {
												console.log(event);

												if (!event || !event.id) return;
												setSubCategoryList((values) => {
													const duplicateValue =
														values?.filter(
															(val) =>
																val.id ===
																event.id
														);
													return !duplicateValue ||
														duplicateValue.length ===
															0
														? values
															? [...values, event]
															: [event]
														: values;
												});
											}}
										/>
									</div>
									{subCategoryList &&
										subCategoryList.length > 0 && (
											<div className="product-detail__chip-container">
												{subCategoryList.map(
													(chipData) => {
														return (
															<div
																style={{
																	margin: "5px",
																}}
															>
																<Chip
																	label={
																		chipData.name
																	}
																	key={
																		chipData.id
																	}
																	onDelete={() =>
																		handleSubCategoryDelete(
																			chipData
																		)
																	}
																></Chip>
															</div>
														);
													}
												)}
											</div>
										)}
								</div>

								<div className="w-40">
									<TextArea
										placeholder={"Description*"}
										label
										rows={8}
										columns={40}
										disabled={false}
										value={description}
										onChange={(event: string) => {
											return setDescription(event);
										}}
									/>
								</div>
							</div>

							<div className="w-100 d-flex justify-content-between">
								<div className="w-40">
									<TextArea
										placeholder={"Nutritive Value"}
										label
										rows={8}
										columns={40}
										disabled={false}
										value={nutritiveValue}
										onChange={(event: string) => {
											return setNutritiveValue(event);
										}}
									/>
								</div>
							</div>
							<div className="w-100 d-flex justify-content-center">
								<div className="w-50 ">
									<ImageView
										uniqueName={"HoverImageUrl"}
										imageUrl={hoverImageUrl}
										setImage={setFileHoverImage}
										label={"Product Hover Image*"}
									/>
								</div>
							</div>
						</>
					)}
					{productId && (
						<div
							className="d-flex justify-content-between"
							style={{ margin: "2em auto", width: "75%" }}
						>
							<button
								className="banner__button"
								onClick={() => {
									history.push(
										`/dashboard/product-types?productId=${productId}&productName=${name}`
									);
								}}
							>
								Product Types
							</button>
							<button
								className="banner__button"
								onClick={() => {
									history.push(
										`/dashboard/instruction-titles?productId=${productId}&productName=${name}`
									);
								}}
							>
								Instructions
							</button>
						</div>
					)}
				</div>
			</div>
		);
};

export default ProductDetail;

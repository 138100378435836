import "./Product.scss";
import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import Error from "../../UI/Error/Error";
import { CircularProgress } from "@material-ui/core";
import Empty from "../../UI/Empty/Empty";
import SnackbarContext from "../../../Context/SnackbarContext";
import {
	GetRecommendations,
	DeleteRecommendations,
	InsertRecommendations,
	GetProductTypesForRecommendation,
} from "./ProductQuery";
import { useLocation } from "react-router-dom";
import ReactModal from "react-modal";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";
import close from "../../../Assets/Images/cross.svg";
import Search from "../../UI/Search/Search";
import ProductContext from "../../../Context/ProductContext";

const Recommended = () => {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const [productId] = useState<number | null>(
		parseInt(query.get("productId") as string)
	);
	const [productName] = useState<string | null>(query.get("productName"));

	const [addModal, setAddModal] = useState<boolean>(false);
	const { setProductId } = useContext(ProductContext);

	const { data: recommendations, error, loading, refetch } = useQuery(
		GetRecommendations,
		{
			variables: {
				productId,
			},
		}
	);

	useEffect(() => {
		setProductId(productId);
	}, [setProductId, productId]);

	const callRefetch = () => {
		refetch();
	};

	if (error) {
		return <Error error={error} />;
	}

	return (
		<div className="container-main">
			<AddRecommendations
				isOpen={addModal}
				setModal={setAddModal}
				refetch={callRefetch}
				productId={productId}
			/>
			<div className="coupon__box">
				<div className="coupon__main">
					<div className="season__header">
						<p className="title">
							Recommendations &nbsp; ({productName})
						</p>
						<button
							type="button"
							onClick={() => {
								setAddModal(true);
							}}
						>
							+Add Recommendations
						</button>
					</div>
					<ProductTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : recommendations.recommendations.length > 0 ? (
						recommendations.recommendations.map((element: any) => {
							return (
								<ProductItem
									element={element}
									key={element.id}
									refetch={callRefetch}
								/>
							);
						})
					) : (
						<Empty />
					)}
				</div>
			</div>
		</div>
	);
};

const ProductTitle: React.FC = () => {
	return (
		<div className="coupon__table">
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Product Name</p>
		</div>
	);
};

interface ItemProps {
	element: any;
	refetch: () => void;
}

const ProductItem: React.FC<ItemProps> = (props: ItemProps) => {
	const { element, refetch } = props;
	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [deleteRecommendations] = useMutation(DeleteRecommendations);
	const [loading, setLoading] = useState(false);

	const onDelete = () => {
		setLoading(true);
		deleteRecommendations({
			variables: {
				recommendedId: element.id,
			},
		})
			.then(({ data }) => {
				setSnackbar(true);
				setMessage("Recommendation Deleted Successfully");
				setLoading(false);
				refetch();
			})
			.catch((error) => {
				setSnackbar(true);
				setMessage(error.message);
			});
	};

	return (
		<div className="coupon__table">
			<p className="season__table-item">{element.id}</p>
			<p className="season__table-item">{element.product_type.name}</p>
			<p className="season__table-item"></p>

			<div className="season__actions">
				{loading ? (
					<div style={{ width: "4rem" }}>
						<CircularProgress size={20} thickness={3} />
					</div>
				) : (
					<button className="btn-list" onClick={onDelete}>
						Delete
					</button>
				)}
			</div>
		</div>
	);
};

export default Recommended;

interface AddRecommendationsProps {
	isOpen: boolean;
	productId: number | null;
	setModal: (value: boolean) => void;
	refetch: () => void;
}
const AddRecommendations: React.FC<AddRecommendationsProps> = ({
	isOpen,
	setModal,
	refetch,
	productId,
}: AddRecommendationsProps) => {
	const [productType, setProductType] = useState<any>(null);
	const [mutationLoading, setMutationLoading] = useState(false);
	const [insertRecommendations] = useMutation(InsertRecommendations);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const { data: productTypes, loading } = useQuery(
		GetProductTypesForRecommendation,
		{
			variables: {
				productId,
			},
		}
	);

	const clearState = () => {
		setProductType(null);
	};

	const onSave = () => {
		setMutationLoading(true);
		insertRecommendations({
			variables: {
				productTypeId: productType.id,
				productId,
			},
		})
			.then(({ data: { insert_recommendations: category } }: any) => {
				if (category.affected_rows > 0) {
					setMessage("Recommendations Inserted Successfully");
					setSnackbar(true);
					refetch();
					clearState();
					setMutationLoading(false);
					return setModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setMutationLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setMutationLoading(false);
			});
	};

	return (
		<ReactModal
			className="season__modal Modal__main"
			onRequestClose={() => {
				clearState();
				setModal(false);
			}}
			overlayClassName="Overlay"
			isOpen={isOpen}
		>
			<div className="close">
				<img
					src={close}
					alt="Close"
					className="close__img"
					onClick={() => {
						clearState();
						setModal(false);
					}}
				/>
			</div>
			{loading ? (
				<div className="spinner-container" style={{ marginTop: "3em" }}>
					<CircularProgress size={40} thickness={4} />
				</div>
			) : (
				<div className="add__modal__content">
					<p className="add__modal__header">Add Recommendation</p>
					<div className="margin-auto w-75">
						<Search
							label={"Product Types"}
							options={productTypes.product_type}
							value={productType ? productType.name : ""}
							onChangeSelect={(event: any) => {
								console.log(event);
								setProductType(event);
							}}
						/>
					</div>

					{!mutationLoading ? (
						<div
							className="w-40 margin-auto"
							style={{ marginTop: "2em" }}
						>
							<PrimaryButton
								label="Save"
								disabled={!productType}
								loading={mutationLoading}
								active
								onClick={onSave}
							/>
						</div>
					) : (
						<div
							className="spinner-container"
							style={{ marginTop: "3em" }}
						>
							<CircularProgress size={40} thickness={4} />
						</div>
					)}
				</div>
			)}
		</ReactModal>
	);
};

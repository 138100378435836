import { gql } from "@apollo/client";

export const GetUsers = gql`
	query GetUsers($limit: Int!, $offset: Int!, $searchString: String!) {
		users(limit: $limit, offset: $offset, where: {_or: [{email: {_ilike: $searchString}}, {phoneNumber: {_ilike: $searchString}}, {firstName: {_ilike: $searchString}}, {lastName: {_ilike: $searchString}}]}) {
			id
			email
			firstName
			lastName
			phoneNumber
		}
	}
`;


export const GetUserCount = gql`
	query GetUsersCount($searchString: String!) {
		users_aggregate(where: {_or: [{email: {_ilike: $searchString}}, {phoneNumber: {_ilike: $searchString}}, {firstName: {_ilike: $searchString}}, {lastName: {_ilike: $searchString}}]}) {
			aggregate {
				count
			}
		}
	}
`;


export const GetUserByPk = gql`
	query GetUsersByPk($userId: bigint!) {
		users_by_pk(id: $userId) {
			email
			firstName
			id
			lastName
			phoneNumber
			addresses(order_by: {id: asc}) {
			id
			country {
				name
			}
			countryId
			lineOne
			lineTwo
			name
			state
			town
			zipcode
			}
		}
	}

`;




export const UpdateUsers = gql`
	mutation UpdateUsers($userId: Int!, $firstName: String!, $lastName: String!, $phoneNumber: String!) {
		update_users(where: {id: {_eq: $userId}}, _set: {firstName: $firstName, lastName:  $lastName, phoneNumber: $phoneNumber}) {
			affected_rows
		}
	}
`;


export const CreateAddress = gql`
	mutation CreateAddress($userId: Int!, $lineOne: String!, $lineTwo: String!, $name: String!, $state: String!, $zipcode: String!, $town: String!, $countryId: Int!) {
		insert_addresses(objects: {countryId: $countryId, lineOne: $lineOne, lineTwo: $lineTwo, name: $name, state: $state, town: $town, userId: $userId, zipcode: $zipcode}) {
			affected_rows
		}
	}
`;


export const UpdateAddress = gql`
	mutation UpdateAddress($userId: Int!, $lineOne: String!, $lineTwo: String!, $name: String!, $state: String!, $zipcode: String!, $town: String!, $countryId: Int!, $addressId: Int!) {
		update_addresses(where: {id:{_eq: $addressId}}, _set: {countryId: $countryId, lineOne: $lineOne, lineTwo: $lineTwo, name: $name, state: $state, town: $town, userId: $userId, zipcode: $zipcode}) {
			affected_rows
		}
	}
`;


export const GetUserDataForDataExport = gql`
	query GetAllUsers {
		users(where: {isDeleted: {_eq: false}}) {
			id
			email
			firstName
			lastName
			phoneNumber
		}
	}
`;
import { gql } from "@apollo/client";

export const GetSeasons = gql`
	query MyQuery {
		seasons(order_by: {id: asc}) {
			id
			name
			isDeleted
		}
	}
`;


export const UpdateSeasonById = gql`
	mutation UpdateSeasonById($name: String!, $seasonId: Int!) {
		update_seasons(where: {id: {_eq: $seasonId}}, _set: {name: $name}) {
			affected_rows
		}
	}
`;

export const CreateSeason = gql`
	mutation CreateSeason($name: String!) {
		insert_seasons(objects: {name: $name}) {
			affected_rows
		}
	}
`;

export const ToggleSeason = gql`
	mutation ToggleSeason($isDeleted: Boolean!, $seasonId: Int!) {
		update_seasons(where: { id: { _eq: $seasonId } }, _set: { isDeleted: $isDeleted }) {
			affected_rows
		}
}
`;


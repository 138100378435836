import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
	ApolloClient,
	ApolloLink,
	ApolloProvider,
	createHttpLink,
	HttpLink,
	InMemoryCache,
} from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import {
	unstable_createMuiStrictModeTheme as createMuiTheme,
	ThemeProvider,
} from "@material-ui/core";
import ReactModal from "react-modal";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#5f8ff1",
			main: "#004032",
			dark: "#30497a",
		},
		secondary: {
			main: "#FFFFFF",
		},
	},
});

// const getToken = (): Promise<string | undefined> => {
//     return new Promise((resolve, reject) => {
//         firebase.auth().onAuthStateChanged(async (user) => {
//             if (!user) {
//                 reject("User not found");
//             }
//             const token: string | undefined = await user?.getIdToken(
//                 /* forceRefresh */ true
//             );
//             resolve(token);
//         });
//     });
// };

const prodUri = "https://api.indamseeds.com/v1/graphql";
const devUri = "https://indoam.herokuapp.com/v1/graphql";

const devAdminSecret = "indoame@321";
const prodAdminSectet = "indoame@2021@seeds";

const httpLink = createHttpLink({
	uri: prodUri,
});

const authLink = setContext(async (_, { headers }) => {
	return {
		headers: {
			...headers,
			"x-hasura-admin-secret": prodAdminSectet,
		},
	};
});

const rootEl: any = document.getElementById("root");
ReactModal.setAppElement(rootEl);

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.map(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			)
		);

	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
	link: ApolloLink.from([errorLink, authLink, httpLink]),
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: "network-only",
		},
		query: {
			fetchPolicy: "network-only",
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<ApolloProvider client={client}>
					<App />
				</ApolloProvider>
			</ThemeProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

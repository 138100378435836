import { gql } from "@apollo/client";

export const GetOrders = gql`
query GetOrders($statusId: Int!, $searchString: String!, $limit: Int!, $offset: Int!) {
	orders(where: { statusId: {_eq: $statusId}  _or: [{user: {email: {_ilike: $searchString}}}, {user: {phoneNumber: {_ilike: $searchString}}}, {razorPayOrderId: {_ilike: $searchString}}]}, order_by: {id: desc} limit: $limit, offset: $offset) {
		addressId
		id
		address {
			lineOne
			lineTwo
			name
			state
			town
		}
		userId
		user {
			email
			lastName
			firstName
			phoneNumber
		}
		totalAmount
		statusId
		order_status {
			name
		}
		order_product_types {
			product_type {
				name
				productId
			}
			count
		}
		razorPayOrderId
		razorPayPaymentId
		shippingFee
		createdAt
	}
}
`;

export const GetOrderCount = gql`
	query GetOrdersCount($searchString: String!) {
		orders_aggregate(where: {_or: [{user: {email: {_ilike: $searchString}}}, {user: {phoneNumber: {_ilike: $searchString}}}, {razorPayOrderId: {_ilike: $searchString}}]}) {
			aggregate {
				count
			}
		}
	}
`;


export const GetOrderByOrderId = gql`
	query GetOrders($orderId: bigint!) {
		orders_by_pk(id: $orderId) {
			id
			addressId
			trackingNumber
			address {
				lineOne
				lineTwo
				name
				zipcode
				state
				town
				country {
					name
				}
			}
			userId
			user {
				email
				lastName
				firstName
				phoneNumber
			}
			totalAmount
			order_status {
				id
				name
			}
			order_product_types {
				order_status {
					id
					name
				}
				product_type {
					id
					name
					productId
					discountedPrice
				}
				count
			}
			razorPayOrderId
			razorPayPaymentId
			shippingFee
			createdAt
	}
}
`;

export const UpdateOrder = gql`
mutation UpdateOrder($orderId: bigint!, $orderStatusId: Int!) {
	update_orders(where: {id: {_eq: $orderId}}, _set: {statusId: $orderStatusId}) {
	  affected_rows
	}
}
`;


export const GetOrderStatus = gql`
	query GetOrderStatus {
		order_status(where: {isDeleted: {_eq: false}}, order_by: {id: asc}) {
			id
			name
		}
	}
`;

export const UpdateOrderTrackingNumber = gql`
mutation UpdateOrderTracking($orderId: bigint!, $trackingNumber: String!) {
	update_orders(where: {id: {_eq: $orderId}}, _set: {trackingNumber: $trackingNumber}) {
	  affected_rows
	}
}
`;

export const DownloadOrderDetails = gql`
query GetOrders($fromTime: timestamptz!, $toTime: timestamptz!) {
  orders(where: {_and: [{createdAt: {_gte: $fromTime}}, {createdAt: {_lte: $toTime}}]}) {
    id
    addressId
    trackingNumber
    address {
      lineOne
      lineTwo
      name
      zipcode
      state
      town
      country {
        name
      }
    }
    userId
    user {
      email
      lastName
      firstName
      phoneNumber
    }
    totalAmount
    order_status {
      id
      name
    }
    order_product_types {
      order_status {
        id
        name
      }
      product_type {
        id
        name
        productId
        discountedPrice
      }
      count
    }
    razorPayOrderId
    razorPayPaymentId
    createdAt
  }
}
`;
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { CircularProgress, TextField } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import SnackbarContext from "../../../Context/SnackbarContext";
import Checkbox from "../../UI/Checkbox/Checkbox";
import Error from "../../UI/Error/Error";
import Search from "../../UI/Search/Search";
import DatePicker from "react-datepicker";
import DateTimePicker from "react-datetime-picker";

import "react-datepicker/dist/react-datepicker.css";

import "./Deal.scss";
import {
	GetDeals,
	GetProducts,
	GetProductTypes,
	ToggleDeal,
	UpdateDeal,
} from "./DealQuery";

import { makeStyles } from "@material-ui/core/styles";
import { Input as InputM } from "@material-ui/core";
import { parseIsoToDate } from "../../../Utils/parseIsoToDate";
import Input from "../../UI/Input/Input";

const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: "4em",
		width: "40",
	},
}));

const Deal: React.FC = () => {
	const classes = useStyles();

	const { data: deals, error, loading, refetch } = useQuery(GetDeals);

	const [isProduct, setIsProduct] = useState<boolean>(true);
	const [expiry, setExpiry] = useState<string>("");
	const [deal, setDeal] = useState<any>(null);
	const [discount, setDiscount] = useState<string>("0");
	const [enable, setEnable] = useState<boolean>(false);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const [mutationLoading, setLoading] = useState(false);

	const [options, setOptions] = useState<any[]>([]);
	const [toggleSubCategory] = useMutation(ToggleDeal);
	const [updateDeal] = useMutation(UpdateDeal);
	const client = useApolloClient();

	const callRefetch = () => {
		refetch();
	};

	useEffect(() => {
		if (isProduct) {
			client
				.query({ query: GetProducts })
				.then(({ data: { product } }) => {
					setOptions(product);
				});
		} else {
			client
				.query({ query: GetProductTypes })
				.then(({ data: { product_type } }) => {
					setOptions(product_type);
				});
		}
	}, [isProduct, client]);

	useEffect(() => {
		if (!loading && deals.deal_of_the_day.length > 0) {
			const deal = deals.deal_of_the_day[0];
			setExpiry(deal.expiry);
			setEnable(deal.enable);
			setDiscount(deal.discount);
			setIsProduct(!!deal.product);
			setDeal(
				!!deals.deal_of_the_day[0].product
					? deal.product
					: deal.product_type
			);
		}
	}, [loading, deals]);

	const onToggle = () => {
		setLoading(true);
		toggleSubCategory({
			variables: {
				dealId: deals.deal_of_the_day[0].id,
				enable: !enable,
			},
		})
			.then(({ data }) => {
				setSnackbar(true);
				setMessage("Sub Category updated Successfully");
				setLoading(false);
				callRefetch();
			})
			.catch((error) => {
				setSnackbar(true);
				setMessage(error.message);
			});
	};

	const onSave = () => {
		setLoading(true);
		updateDeal({
			variables: {
				dealId: deals.deal_of_the_day[0].id,
				enable: enable,
				expiry,
				productId: isProduct ? deal.id : null,
				productTypeId: !isProduct ? deal.id : null,
				discount,
			},
		})
			.then(({ data }) => {
				setSnackbar(true);
				setMessage("Deal of the day updated Successfully");
				setLoading(false);
				callRefetch();
			})
			.catch((error) => {
				setSnackbar(true);
				setMessage(error.message);
			});
	};

	if (error) {
		return <Error error={error} />;
	}

	const parseDate = (isoString: string) => {
		console.log(parseIsoToDate(isoString, "yyyy-MM-ddHH:mm"));
		return `${parseIsoToDate(isoString, "yyyy-MM-dd")}T${parseIsoToDate(
			isoString,
			"HH:mm"
		)}`;
	};
	return (
		<div className="container-main">
			<div className="deal__box">
				{loading ? (
					<div className="spinner-container">
						<CircularProgress size={40} thickness={4} />
					</div>
				) : (
					<div className="season__main">
						<div className="season__header">
							<p className="title">Deal of the Day</p>
						</div>
						<div className="w-60 d-flex justify-content-between">
							<div style={{ width: "10rem" }}>
								<Checkbox
									checked={isProduct}
									setChecked={setIsProduct}
									label={"Add Product"}
								/>
							</div>

							<div style={{ width: "12rem" }}>
								<Checkbox
									checked={!isProduct}
									setChecked={(value) => {
										setIsProduct(!value);
									}}
									label={"Add Product Type"}
								/>
							</div>
						</div>
						<div className="w-60 d-flex justify-content-between">
							<div className="w-40">
								<Search
									label={
										isProduct ? "Product" : "Product Type"
									}
									options={options}
									value={deal ? deal?.name : ""}
									onChangeSelect={(event: {
										id: number;
										name: string;
									}) => {
										setDeal(event);
									}}
								/>
							</div>

							<div className="w-40">
								<Input
									placeholder={"Discount (Percentage)"}
									label
									type={"number"}
									disabled={false}
									value={discount}
									onChange={(event: string) => {
										return setDiscount(event);
									}}
								/>
							</div>
						</div>
						{/* 
						<TextField
							id="datetime-local"
							// label="Expires On"
							type="datetime-local"
							defaultValue={parseDate(
								deals.deal_of_the_day[0].expiry
							)}
							onChange={(event) => {
								setExpiry(
									new Date(event.target.value).toISOString()
								);
							}}
							className={classes.textField}
							InputLabelProps={{
								shrink: true,
							}}
						/> */}

						<DateTimePicker
							className={classes.textField}
							value={
								new Date(
									expiry
										? expiry
										: deals.deal_of_the_day[0].expiry
								)
							}
							onChange={(date) =>
								setExpiry(new Date(date).toISOString())
							}
						/>

						{/* <DateTimeInput
							source="endDate"
							label="End time"
							type="datetime-local"
							// defaultValue={parseDate(
							// 	deals.deal_of_the_day[0].expiry
							// )}
							// onChange={(event) => {
							// 	setExpiry(
							// 		new Date(event.target.value).toISOString()
							// 	);
							// }}
							// className={classes.textField}
							options={{
								format: "DD/MM/YYYY, HH:mm:ss",
								ampm: false,
								clearable: true,
							}} */}
						{/* /> */}

						<div className="deal__header w-75">
							{!mutationLoading ? (
								<>
									<button onClick={onSave}>Save</button>
									<button onClick={onToggle}>
										{enable ? "Disable" : "Enable"}
									</button>
								</>
							) : (
								<div className="spinner-container">
									<CircularProgress size={40} thickness={4} />
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Deal;

import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import Empty from "../../UI/Empty/Empty";
import Error from "../../UI/Error/Error";
import "./Banner.scss";

import { GetBannerTypes } from "./BannerQuery";
const Banner = () => {
	const { data: bannerTypes, error, loading, refetch } = useQuery(
		GetBannerTypes
	);

	const history = useHistory();

	if (error) {
		return <Error error={error} />;
	}

	return (
		<div className="container-main">
			<div className="season__box">
				<div className="coupon__main">
					<div className="season__header">
						<p className="title">Banners</p>
					</div>

					<BannerTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : bannerTypes.banner_type.length > 0 ? (
						bannerTypes.banner_type.map((element: any) => {
							return (
								<BannerItem
									element={element}
									refetch={refetch}
									key={element.id}
									onClickView={() => {
										history.push(
											`/dashboard/banners/${element.type}`
										);
									}}
								/>
							);
						})
					) : (
						<Empty />
					)}
				</div>
			</div>
		</div>
	);
};

export default Banner;

const BannerTitle: React.FC = () => {
	return (
		<div className="season__table">
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Name</p>
		</div>
	);
};

interface BannerItemProps {
	element: any;
	onClickView: () => void;
	refetch: () => void;
}

const BannerItem: React.FC<BannerItemProps> = (props: BannerItemProps) => {
	const { element, onClickView } = props;


	return (
		<div className="season__table">
			<p className="season__table-item">{element.id}</p>
			<p className="season__table-item">{element.name}</p>
			<div className="season__actions">
				<button className="btn-list" onClick={onClickView}>
					view
				</button>
			</div>
		</div>
	);
};

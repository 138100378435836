import { gql } from "@apollo/client";

/* export const GetProducts = gql`
	query GetProducts($limit: Int!, $offset: Int!, $searchString: String!) {
		product(limit: $limit, offset: $offset, order_by: {id: asc}, where: {name: {_ilike: $searchString}}) {
			id
			name
			sub_category {
				id
				name
				category {
					id
					name
				}
			}
			isDeleted
		}
	}
`; */

export const GetProducts = gql`
query GetProducts($limit: Int!, $offset: Int!, $searchString: String!) {
	product(limit: $limit, offset: $offset, order_by: {id: asc}, where: {name: {_ilike: $searchString}}) {
	  id
	  name
	  productsSubCategories {
		subCategory {
		  id
		  name
		  categoriesSubCategories {
			category {
			  id
			  name
			}
		  }
		}
	  }
	  isDeleted
	}
  }
`;


export const GetProductsCount = gql`
	query GetProducts($searchString: String!) {
		product_aggregate(where: {name: {_ilike: $searchString}}) {
			aggregate {
				count
			}
		}
	}
`;



export const ToggleProduct = gql`
	mutation ToggleProduct($productId: bigint!, $isDeleted: Boolean!) {
		update_product(where: {id: {_eq: $productId}}, _set: {isDeleted: $isDeleted}) {
			affected_rows
		}
	}
`;


export const InsertProduct = gql`
mutation InsertProduct($imageUrl: String!, $hoverImageUrl: String!, $coverImageUrl: String!, $name: String!, $description: String!, $nutritiveValue: String!) {
	insert_product_one(object: {imageUrl: $imageUrl, hoverImageUrl: $hoverImageUrl, coverImageUrl: $coverImageUrl, name: $name, description: $description, nutritiveValue: $nutritiveValue}) {
	  id
	}
  }  
`;

export const GetProductDetails = gql`
	query GetProductDetails($productId: bigint!) {
		product(where: { id: { _eq: $productId } }) {
			coverImageUrl
			id
			hoverImageUrl
			description
			imageUrl
			name
			nutritiveValue
			productsSubCategories{
				subCategory{
				  id
				  name
				}
			}
		}
	}
`;

export const UpdateProduct = gql`
mutation UpdateProduct($productId: bigint!, $imageUrl: String!, $hoverImageUrl: String!, $coverImageUrl: String!, $name: String!, $description: String!, $nutritiveValue: String!, $productsSubCategoriesObject: [products_sub_categories_insert_input!]!) {
	update_product(where: {id: {_eq: $productId}}, _set: {imageUrl: $imageUrl, hoverImageUrl: $hoverImageUrl, coverImageUrl: $coverImageUrl, name: $name, description: $description, nutritiveValue: $nutritiveValue}) {
	  affected_rows
	}
	insert_products_sub_categories(objects: $productsSubCategoriesObject, on_conflict: {constraint: products_sub_categories_productId_subCategoryId_key, update_columns: []}) {
	  affected_rows
	}
  }
`;



export const GetProductTypesByProduct = gql`
	query GetProductTypesByProductId($productId: Int!, $searchString: String!) {
		product_type(where: {productId: {_eq: $productId}, name: {_ilike: $searchString}}, order_by: {id: asc}) {
			id
			name
			discountedPrice
			product {
				id 
				name
				productsSubCategories{
					subCategory{
					  id
					  name
					  categoriesSubCategories{
						category{
						  id
						  name
						}
					  }
					}
				}
			}
			isDeleted
		}
	}
`;

/* export const GetProductTypes = gql`
	query GetProductTypesByProductId($searchString: String!, $limit: Int!, $offset: Int!) {
		product_type(where: {name: {_ilike: $searchString}}, order_by: {id: asc}, limit: $limit, offset: $offset) {
			id
			name
			discountedPrice
			isDeleted
			quantity
			product {
			id
			name
			sub_category {
				id
				name
				category {
					id
					name
				}
			}
			}
		}
	}
`; */

export const GetProductTypes = gql`
query GetProductTypesByProductId($searchString: String!, $limit: Int!, $offset: Int!) {
	product_type(where: {name: {_ilike: $searchString}}, order_by: {id: asc}, limit: $limit, offset: $offset) {
	  id
	  name
	  discountedPrice
	  isDeleted
	  quantity
	  product {
		id
		name
		productsSubCategories {
		  subCategory {
			id
			name
			categoriesSubCategories {
			  category {
				id
				name
			  }
			}
		  }
		}
	  }
	}
  }
`;


export const GetProductsTypeCount = gql`
	query GetProducts($searchString: String!) {
		product_type_aggregate(where: {name: {_ilike: $searchString}}) {
			aggregate {
				count
			}
		}
	}
`;


export const ToggleProductType = gql`
	mutation ToggleProductType($productTypeId: Int!, $isDeleted: Boolean!) {
		update_product_type(where: {id: {_eq: $productTypeId}}, _set: {isDeleted: $isDeleted}) {
			affected_rows
		}
	}
`;


export const InsertProductType = gql`
mutation InsertProductType($imageUrl: String!, $recommendedCoverImage: String!, $productId: Int!, $originalPrice: Int, $discountedPrice: Int!, $name: String!, $SKU: String!, $remark: String, $duration: String, $plant: String, $spread: String, $exposure: String, $spacing: String, $height: String, $type: String, $isFeatured: Boolean!, $quantity: String!, $productSeasons: [product_seasons_insert_input!]!, $commonName: String, $family: String, $origin: String, $habit: String, $growingTips: String) {
	insert_product_type(objects: {imageUrl: $imageUrl, recommendedCoverImage: $recommendedCoverImage, productId: $productId, originalPrice: $originalPrice, discountedPrice: $discountedPrice, name: $name, SKU: $SKU, remark: $remark, duration: $duration, spread: $spread, spacing: $spacing, height: $height, exposure: $exposure, plant: $plant, type: $type, isFeatured: $isFeatured, quantity: $quantity, product_seasons: {data: $productSeasons}, commonName: $commonName, family: $family, origin: $origin, habit: $habit, growingTips: $growingTips}) {
	  affected_rows
	}
  }
`;

export const UpdateProductType = gql`
mutation UpdateProductType($imageUrl: String!, $recommendedCoverImage: String!, $productId: Int!, $originalPrice: Int, $discountedPrice: Int!, $name: String!, $SKU: String!, $remark: String, $duration: String, $plant: String, $productTypeId: Int!, $spread: String, $exposure: String, $spacing: String, $height: String, $type: String, $isFeatured: Boolean!, $productSeasons: [product_seasons_insert_input!]!, $quantity: String, $commonName: String, $family: String, $growingTips: String, $habit: String, $origin: String) {
	update_product_type(where: {id: {_eq: $productTypeId}}, _set: {imageUrl: $imageUrl, recommendedCoverImage: $recommendedCoverImage, productId: $productId, originalPrice: $originalPrice, discountedPrice: $discountedPrice, name: $name, SKU: $SKU, remark: $remark, quantity: $quantity, duration: $duration, plant: $plant, isFeatured: $isFeatured, spread: $spread, spacing: $spacing, height: $height, exposure: $exposure, type: $type, commonName: $commonName, family: $family, growingTips: $growingTips, habit: $habit, origin: $origin}) {
	  affected_rows
	}
	delete_product_seasons(where: {productTypeId: {_eq: $productTypeId}}) {
	  affected_rows
	}
	insert_product_seasons(objects: $productSeasons) {
	  affected_rows
	}
  }
`;

export const GetProductTypeDetail = gql`
	query GetProductTypeDetail($productTypeId: Int!) {
		product_type(where: { id: { _eq: $productTypeId } }) {
			duration
			id
			imageUrl
			recommendedCoverImage
			isFeatured
			name
			remark
			quantity
			duration
			plant
			originalPrice
			discountedPrice
			exposure 
			spacing 
			height
			type
			spread
			commonName
			family
			origin
			habit
			growingTips
			product {
				id
				name
				productsSubCategories{
					subCategory{
					  id
					}
				}
			}
			product_seasons {
				id
				season {
					id 
					name
				}
			}
			season {
				id
				name
			}
			SKU
		}
	}
`;


export const GetRecommendations = gql`
	query GetRecommendation($productId: bigint!) {
		recommendations(where: {productId: {_eq: $productId}}, order_by: {recommended: asc}, distinct_on: recommended) {
			product_type {
				id
				name
			}
			product {
				name
			}
			productId
			id
		}
	}
`;

export const DeleteRecommendations = gql`
	mutation DeleteRecommendations($recommendedId: Int!) {
		delete_recommendations(where: { id: { _eq: $recommendedId } }) {
			affected_rows
		}
	}
`;


export const InsertRecommendations = gql`
	mutation InsertRecommendations($productId: bigint!, $productTypeId: Int!) {
		insert_recommendations(objects: { productId: $productId, recommended: $productTypeId }) {
			affected_rows
		}
	}
`;



export const GetProductTypesForRecommendation = gql`
	query GetProductTypesForRecommendation($productId: Int!) {
		product_type(where: {productId: {_neq: $productId}}) {
			id
			name
		}
	}

`;


export const GetInstructionTitlesByProductId = gql`
	query GetInstructionTitles($productId: Int!) {
		instruction_titles(where: {productId: {_eq: $productId}},  order_by: {id: asc}) {
			id
			index
			name
			isDeleted
			instructions_type {
				id
				name
			}
		}
	}
`;

export const ToggleInstructionTitle = gql`
	mutation ToggleInstructionTitle($instructionTitleId: Int!, $isDeleted: Boolean) {
		update_instruction_titles(where: {id: {_eq: $instructionTitleId}}, _set: {isDeleted: $isDeleted}) {
			affected_rows
		}
	}
`;



export const InsertInstructionTitles = gql`
	mutation InsertInstructionTitles($name: String!, $productId: Int!, $typeId: Int!, $index: Int!) {
		insert_instruction_titles(objects: {name: $name, productId: $productId, typeId: $typeId, index:  $index}) {
			affected_rows
		}
	}
`;


export const UpdateInstructionTitles = gql`
	mutation UpdateInstructionTitles($name: String!, $productId: Int!, $typeId: Int!, $index: Int!, $instructionTitleId: Int!) {
		update_instruction_titles(where: {id: {_eq: $instructionTitleId}} _set: {name: $name, productId: $productId, typeId: $typeId, index:  $index}) {
			affected_rows
		}
	}
`;


export const GetInstructionTypes = gql`
	query GetInstructionTypes {
		instructions_types(order_by: {id: asc}) {
			id
			name
		}
	}
`;


export const InsertInstruction = gql`
	mutation InsertInstruction($title: String, $mediaUrl: String, $description: String!, $index: Int!, $instructionTitleId: Int!) {
		insert_instructions(objects: {title: $title, mediaUrl:  $mediaUrl, description: $description, index: $index, instructionTitleId:$instructionTitleId}) {
			affected_rows
		}
	}
`;


export const UpdateInstruction = gql`
	mutation UpdateInstruction($title: String, $mediaUrl: String, $description: String!, $index: Int!, $instructionId: Int!) {
		update_instructions(where: { id: { _eq: $instructionId } } _set: { title: $title, mediaUrl: $mediaUrl, description: $description, index: $index }) {
			affected_rows
		}
	}
`;

export const DeleteInstruction = gql`
	mutation DeleteInstruction($instructionId: Int!) {
		delete_instructions(where: { id: { _eq: $instructionId } }) {
			affected_rows
		}
	}
`;

export const DeleteInstructionTitle = gql`
	mutation DeleteInstructionTitle($instructionId: Int!) {
		delete_instruction_titles(where: { id: { _eq: $instructionId } }) {
			affected_rows
		}
	}
`;

export const GetInstructions = gql`
	query GetInstructions($instructionTitleId: Int!) {
		instructions(where: {instructionTitleId: {_eq: $instructionTitleId}}, order_by: {index: asc}) {
			index
			id
			mediaUrl
			title
			description
			instruction_title {
				name
			}
		}
	}
`;

export const DeleteProductsSubCategories = gql`
mutation DeleteProductsSubCategories($_or: [products_sub_categories_bool_exp]!) {
	delete_products_sub_categories(where: {_or: $_or}) {
	  affected_rows
	}
  }`;

export const InsertProductsSubCategories = gql`
mutation InsertProductsSubCategories($productsSubCategoriesObject: [products_sub_categories_insert_input!]!) {
	insert_products_sub_categories(objects: $productsSubCategoriesObject, on_conflict: {constraint: products_sub_categories_productId_subCategoryId_key, update_columns: []}) {
	  affected_rows
	}
  }`;

import React, { useContext } from "react";
import SnackbarContext from "../../../Context/SnackbarContext";
import Empty from "../Empty/Empty";

const Error: React.FC<{ error: any }> = ({ error }) => {
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	setSnackbar(true);
	setMessage(
		error.message ? error.message : "Some unknown error occurred"
	);

	return (
		<div className="container-main">
			<div className="season__box">
				<Empty />
			</div>
		</div>
	);
};

export default Error;

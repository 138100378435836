import React from "react";
import "./Checkbox.modules.scss";

interface CheckboxProps {
	setChecked: (value: boolean) => void;
	label: string;
	checked: boolean;
}
const Checkbox: React.FC<CheckboxProps> = (props) => {
	const { label, checked, setChecked } = props;

	return (
		<div
			className="checkbox__container"
			onClick={() => {
				setChecked(!checked);
			}}
		>
			<div
				className={
					"checkbox  " +
					(checked ? "checkbox__checked" : "checkbox__unchecked")
				}
			/>
			<p className="checkbox__label">{label}</p>
		</div>
	);
};

export default Checkbox;

import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import SnackbarContext from "../../../Context/SnackbarContext";
import DeleteModal from "../../UI/DeleteModal/DeleteModal";
import Empty from "../../UI/Empty/Empty";
import Error from "../../UI/Error/Error";

import "./Faq.scss";
import FaqTitleEdit from "./FaqEdit/FaqEdit";
import { DeleteFaqTitle, GetFaqsTitles } from "./FaqQuery";

const Faq: React.FC = () => {
	const { data: faqTitles, error, loading, refetch } = useQuery(
		GetFaqsTitles
	);
	const [editFaqTitle, setEditFaqTitle] = useState<any>(null);
	const [faqModal, setFaqModal] = useState<boolean>(false);

	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const [deleteFaqTitle] = useMutation(DeleteFaqTitle);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);
	const [deleteId, setDeleteId] = useState<number | null>(null);

	if (error) {
		return <Error error={error} />;
	}

	const callRefetch = () => {
		refetch();
	};

	const onDelete = (faqTitleId: number) => {
		setDeleteLoading(true);
		deleteFaqTitle({
			variables: {
				faqTitleId,
			},
		})
			.then(({ data: { delete_faq_titles: faqTitle } }: any) => {
				if (faqTitle.affected_rows > 0) {
					setMessage("Faq Title Deleted Successfully");
					setSnackbar(true);
					callRefetch();
					setDeleteLoading(false);
					return setDeleteModal(false);
				} else {
					setMessage("Some Unknown Error Occurred");
					setDeleteLoading(false);
					return setSnackbar(true);
				}
			})
			.catch((error) => {
				setMessage(error.message);
				setSnackbar(true);
				setDeleteLoading(false);
			});
	};

	return (
		<div className="container-main">
			<FaqTitleEdit
				isOpen={faqModal}
				setModal={setFaqModal}
				refetch={callRefetch}
				setEditData={setEditFaqTitle}
				editData={editFaqTitle}
			/>

			<DeleteModal
				loading={deleteLoading}
				setModal={setDeleteModal}
				modal={deleteModal}
				onClickDelete={() => {
					deleteId && onDelete(deleteId);
				}}
			/>

			<div className="season__box">
				<div className="season__main">
					<div className="season__header">
						<p className="title">FAQs</p>
						<button
							type="button"
							onClick={() => {
								setFaqModal(true);
							}}
						>
							+Add Faq Title
						</button>
					</div>
					<FaqTitle />
					{loading ? (
						<div className="spinner-container">
							<CircularProgress size={40} thickness={4} />
						</div>
					) : faqTitles.faq_titles.length > 0 ? (
						faqTitles.faq_titles.map((element: any) => {
							return (
								<FaqItem
									element={element}
									key={element.id}
									onClickEdit={() => {
										setEditFaqTitle(element);
										setFaqModal(true);
									}}
									onClickDelete={(id: number) => {
										setDeleteModal(true);
										setDeleteId(id);
									}}
								/>
							);
						})
					) : (
						<Empty />
					)}
				</div>
			</div>
		</div>
	);
};

export default Faq;

const FaqTitle: React.FC = () => {
	return (
		<div className="season__table">
			<p className="season__table-title">ID</p>
			<p className="season__table-title">Title</p>
		</div>
	);
};

interface SeasonItemProps {
	element: any;
	onClickEdit: () => void;
	onClickDelete: (id: number) => void;
}

const FaqItem: React.FC<SeasonItemProps> = (props: SeasonItemProps) => {
	const { element, onClickEdit, onClickDelete } = props;

	const history = useHistory();

	return (
		<div className="season__table">
			<p className="season__table-item">{element.id}</p>
			<p className="season__table-item">{element.title}</p>
			<div className="season__actions">
				<button className="btn-list" onClick={onClickEdit}>
					Edit
				</button>
				<button
					className="btn-list"
					onClick={() => {
						history.push(`/dashboard/faqs/${element.id}`);
					}}
				>
					View
				</button>

				<button
					className="btn-list"
					onClick={() => onClickDelete(element.id)}
				>
					Delete
				</button>
			</div>
		</div>
	);
};

import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import SnackbarContext from "../../../../Context/SnackbarContext";
import { Category_Types } from "../../../../generated/graphql";
import WithLoading from "../../../UI/HOC/WithLoading";
import { EnableDisableCategoryType } from "../CategoryTypeQuery";

interface CategoryTypeProps {
	categoryType: Category_Types;
	onClickEdit: () => void;
	refetch: () => void;
}

const CategoryTypeItem: React.FC<CategoryTypeProps> = (
	props: CategoryTypeProps
) => {
	const { categoryType, onClickEdit, refetch } = props;
	const [toggleVisibility] = useMutation(EnableDisableCategoryType);
	const [isLoading, setLoading] = useState(false);
	const { setSnackbar, setMessage } = useContext(SnackbarContext);

	const onToggle = async () => {
		setLoading(true);
		try {
			await toggleVisibility({
				variables: {
					categoryTypeId: categoryType.id,
					isDeleted: !categoryType.isDeleted,
				},
			});
			setSnackbar(true);
			setMessage("Category updated Successfully");
			setLoading(false);
			refetch();
		} catch (err: any) {
			setLoading(false);
			setSnackbar(true);
			setMessage(err.message);
		}
	};
	return (
		<div className="season__table">
			<p className="season__table-item">{categoryType.id}</p>
			<p className="season__table-item">{categoryType.name}</p>
			<div className="season__actions">
				<button className="btn-list" onClick={onClickEdit}>
					Edit
				</button>
				<WithLoading size={20} thickness={3} isLoading={isLoading}>
					<button
						className="btn-list"
						onClick={() => {
							onToggle();
						}}
					>
						{categoryType.isDeleted ? "Enable" : "Disable"}
					</button>
				</WithLoading>

				{/* {loading ? (
					<div
						style={{ width: "4rem" }}
						className="spinner-container"
					>
						<CircularProgress size={20} thickness={3} />
					</div>
				) : (
					<button
						className="btn-list"
						onClick={() => {
							onToggle();
						}}
					>
						{element.isDeleted ? "Enable" : "Disable"}
					</button>
				)} */}
			</div>
		</div>
	);
};

export default CategoryTypeItem;

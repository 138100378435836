import { gql } from "@apollo/client";

export const GetCategories = gql`
query GetCategories {
	categories(order_by: {id: asc}) {
	  id
	  name
	  isDeleted
	  categoriesCategoryTypes{
		categoryType{
		  id
		  name
		}
	  }
	}
  }
`;


export const UpdateCategoriesById = gql`
mutation UpdateCategoriesById($name: String!, $categoryId: Int!, $categoriesCategoryTypesObject: [categories_category_types_insert_input!]!) {
	update_categories(where: {id: {_eq: $categoryId}}, _set: {name: $name}) {
	  affected_rows
	}
	insert_categories_category_types(objects: $categoriesCategoryTypesObject, on_conflict: {constraint: categories_category_types_categoryId_categoryTypeId_key, update_columns: []}){
	  affected_rows
	}
  }
`;

export const CreateCategory = gql`
mutation CreateCategory($name: String!) {
	insert_categories_one(object: {name: $name}) {
	  id
	}
  }
`;

export const ToggleCategory = gql`
	mutation ToggleCategory($isDeleted: Boolean!, $categoryId: Int!) {
		update_categories(where: { id: { _eq: $categoryId } }, _set: { isDeleted: $isDeleted }) {
			affected_rows
		}
}
`;

export const GetCategoryTypes = gql`
query getCategoryTypes {
    category_types(order_by:{id:asc}) {
      id
      name
      isDeleted
    }
  }
`;

export const DeleteCategoriesCategoryTypes = gql`
mutation DeleteCategoriesCategoryTypes($_or: [categories_category_types_bool_exp]!) {
	delete_categories_category_types(where: {_or: $_or}){
	  affected_rows
	}
  }
`;


export const InsertCategoriesCategoryTypes = gql`
mutation InsertCategoriesCategoryTypes($categoriesCategoryTypesObject: [categories_category_types_insert_input!]!) {
	insert_categories_category_types(objects: $categoriesCategoryTypesObject, on_conflict: {constraint: categories_category_types_categoryId_categoryTypeId_key, update_columns: []}) {
	  affected_rows
	}
}`;
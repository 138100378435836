import { gql } from "@apollo/client";

export const GetBlogs = gql`
	query GetBlogs($searchString: String!, $limit: Int!, $offset: Int!) {
		blogs(order_by: {id: asc}, where: {title: {_ilike: $searchString}}, limit: $limit, offset: $offset) {
			id
			title
			author 
		}
	}
`;

export const GetBlogCount = gql`
	query GetBlogCount($searchString: String!) {
		blogs_aggregate(where: {title: {_ilike: $searchString}}) {
			aggregate {
				count
			}
		}
	}
`;


export const DeleteBlog = gql`
	mutation DeleteBlog($blogId: bigint!) {
		delete_blogs(where: {id: {_eq: $blogId}}) {
			affected_rows
		}
	}
`;


export const InsertBlog = gql`
	mutation CreateBlog($summary: String!, $title: String!, $body: String!, $author: String!, $imageUrl: String!) {
		insert_blogs(objects: {summary: $summary, title: $title, body: $body, author: $author, imageUrl: $imageUrl}) {
			affected_rows
		}
	}
`;


export const UpdateBlog = gql`
	mutation UpdateBlog($summary: String!, $title: String!, $body: String!, $blogId: bigint!, $author: String!, $imageUrl: String!) {
		update_blogs(where: {id: {_eq: $blogId}}, _set: {summary: $summary, title: $title, body: $body, author: $author, imageUrl: $imageUrl}) {
			affected_rows
		}
	}
`;

export const GetBlogById = gql`
	query GetBlogById($blogId: bigint!) {
		blogs_by_pk(id: $blogId) {
			summary
			title
			author
			imageUrl
			body
		}
	}
`;
import { Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import WithLoading from "../../../../UI/HOC/WithLoading";
import Input from "../../../../UI/Input/Input";

interface RowTypeProps {
	enableSubmit: boolean;
	isEdit: boolean;
	loading: boolean;
	isLandscape: boolean;
	onChange: ({ isLandscape }) => void;
	onSubmit: () => void;
}

const RowType: React.FC<RowTypeProps> = ({
	enableSubmit,
	isEdit,
	loading,
	isLandscape,
	onSubmit,
	onChange,
	children,
}) => {
	const [islandscape, setLandscape] = useState<boolean>(isLandscape);

	const disableButton = !enableSubmit;

	return (
		<div className="d-flex-column w-100 justify-content-between">
			<div className="d-flex w-100 justify-content-start">
				<Checkbox
					checked={islandscape}
					color="primary"
					name="dvsdv"
					onChange={(event, checked) => {
						setLandscape(checked);
						onChange({
							isLandscape: checked,
						});
					}}
				/>
				<div>Set row item's orientation to Landscape</div>
			</div>

			{children}
			<br></br>
			<br></br>
			<div>
				<WithLoading isLoading={loading}>
					<button
						type="button"
						className="button__primary button__primary-active"
						disabled={disableButton}
						onClick={() => {
							onSubmit();
						}}
					>
						{isEdit ? "Edit" : "Save"}
					</button>
				</WithLoading>
			</div>
			<br></br>
			<br></br>
		</div>
	);
};

export default RowType;
